import React from 'react';

import ClusterCards from 'pages/Clusters/ClusterCards';
import Clusters from 'pages/Clusters/Clusters';
import Members from 'pages/Members/Members';
import { UnverifiedEmail } from 'pages/Messages';
import Onboarding from 'pages/Onboarding';
import Reload from 'pages/Reload';
import SignIn from 'pages/SignIn';
import SignOut from 'pages/SignOut';
import WIP from 'pages/Wip';
import { RouteConfig } from 'shared/types';

import Routes from './routes';

const routeComponentMap: Record<string, React.ReactNode> = {
  cards: <ClusterCards />, // temporary route
  clusters: <Clusters />,
  members: <Members />,
  onboarding: <Onboarding />,

  reload: <Reload />,
  signIn: <SignIn />,
  signOut: <SignOut />,
  unverifiedEmail: <UnverifiedEmail />,

  wip: <WIP />,
};

const defaultRouteId = 'clusters';

const appRoutes: RouteConfig[] = Routes.map((route) => {
  if (!routeComponentMap[route.id]) throw new Error(`Missing route component for ${route.id}`);
  return {
    ...route,
    element: routeComponentMap[route.id],
  };
});

export const defaultRoute = appRoutes.find((route) => route.id === defaultRouteId) as RouteConfig;

export default appRoutes;
