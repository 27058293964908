import queryString from 'query-string';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';

import { useStore } from 'contexts/Store';
import useAuthCheck from 'hooks/useAuthCheck';
import { defaultRoute } from 'routes';
import { routeAll } from 'routes/utils';
import Spinner from 'shared/components/Spinner';
import useUI from 'shared/contexts/stores/UI';
import usePolling from 'shared/hooks/usePolling';
import { locationToPath, routeToReactUrl } from 'shared/utils/routes';
import { ignoreExceptions } from 'utils/saas';

interface Queries {
  cli?: boolean;
  redirect?: string;
}

const SignIn: React.FC = () => {
  const location = useLocation();
  const { auth, info } = useStore();
  const { actions: uiActions } = useUI();
  const [canceler] = useState(new AbortController());

  const queries: Queries = queryString.parse(location.search);

  const redirectToExternalSignin = useCallback(() => {
    const redirect = encodeURIComponent(window.location.href);
    const authUrl = `${info.externalLoginUri}?redirect=${redirect}`;
    routeAll(authUrl);
  }, [info.externalLoginUri]);

  /*
   * Check every so often to see if the user is authenticated.
   * For example, the user can authenticate in a different session,info
   * and this will pick up that auth and automatically redirect them into
   * their previous app. We don't run immediately because the router also
   * performs an auth check there as well upon the first page load.
   */
  usePolling(
    // TODO set up usePolling to account for potential exceptions.
    ignoreExceptions(useAuthCheck(canceler)),
    { interval: 1000, runImmediately: false },
  );

  /*
   * Check for when `isAuthenticated` becomes true and redirect
   * the user to the most recent requested page.
   */
  useEffect(() => {
    if (auth.isAuthenticated) {
      // Stop the spinner, prepping for user redirect.
      uiActions.hideSpinner();

      // Reroute the authenticated user to the app.
      if (!queries.redirect) {
        routeToReactUrl(locationToPath(location.state?.loginRedirect) || defaultRoute.path);
      } else {
        routeAll(queries.redirect);
      }
    } else if (auth.checked) {
      redirectToExternalSignin();
    }
  }, [auth, location, queries, uiActions, redirectToExternalSignin]);

  useEffect(() => {
    uiActions.hideChrome();
    return uiActions.showChrome;
  }, [uiActions]);

  // Stop the polling upon a dismount of this page.
  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  /*
   * Don't render sign in page if...
   *   1. authentication hasn't occurred yet
   */
  if (!auth.checked) return null;

  return <Spinner center />;
};

export default SignIn;
