import { FormInstance, ModalFuncProps } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { getEmptyPoolConfig } from 'components/ResourcePools';
import { clusterDefaults } from 'constants/defaults';
import { useStore } from 'contexts/Store';
import {
  basicProClusterRequest,
  basicStandardClusterRequest,
  defaultPoolConfigs,
} from 'hooks/useModal/useNewCluster/useNewCluster.settings';
import { BasicConfigOption, DefaultResourcePool, OverallConfig, PoolConfig } from 'saasTypes';
import { createCluster } from 'services/api';
import { ModelAgentResourceManagerConfig } from 'services/regional-bindings';
import useModal, { ModalHooks } from 'shared/hooks/useModal/useModal';
import { ErrorLevel } from 'shared/utils/error';
import handleError from 'utils/error';
import { generateMasterConfig, getLatestVersion } from 'utils/saas';

import { NewClusterModalContent } from './useNewCluster.components';

const useModalNewCluster = (): ModalHooks => {
  /**
    This modal uses either a Basic config or an Advanced config to generate a
    `ModelCreateClusterRequest` value. The config type is set by toggling `isAdvanced`.

    With Basic config, `basicConfigSelection` determines whether `basicProClusterRequest`
    or `basicStandardClusterRequest` is used as the value for `ModelCreateClusterRequest`.

    With Advanced config, `AdvancedConfigForm` (with any number of `PoolConfigCard`s) is used
    to build `OverallConfig` and `PoolConfigs`. Those values are then used to generate
    a `ModelCreateClusterRequest`.
  */

  const { modalOpen: openOrUpdate, ...modalHooks } = useModal();

  const {
    orgState: { selectedOrg },
    supportMatrix,
  } = useStore();

  const initialState = useMemo(
    () => ({
      basicConfigSelection: BasicConfigOption.Standard,
      isAdvanced: false,
      overallConfig: {
        detVersion: '',
        masterInstanceType: clusterDefaults.MASTER_INSTANCE_TYPE_STANDARD,
      },
      poolConfigs: defaultPoolConfigs,
      resourceManager: {
        default_aux_resource_pool: '',
        default_compute_resource_pool: '',
      },
    }),
    [],
  );

  const [canceler] = useState(new AbortController());
  const [isAdvanced, setIsAdvanced] = useState<boolean>(initialState.isAdvanced);
  const [basicConfigSelection, setBasicConfigSelection] = useState<BasicConfigOption>(
    initialState.basicConfigSelection,
  );
  const [poolConfigs, setPoolConfigs] = useState<PoolConfig[]>(initialState.poolConfigs);
  const [overallConfig, setOverallConfig] = useState<OverallConfig>(initialState.overallConfig);
  const [supportedDetVersions, setSupportedDetVersions] = useState<string[]>([]);
  const [clusterName, setClusterName] = useState<string>('');
  const [clusterRegion, setClusterRegion] = useState<string>('');
  const [resourceManager, setResourceManager] = useState<ModelAgentResourceManagerConfig>(
    initialState.resourceManager,
  );

  const resetModalState = useCallback(() => {
    setBasicConfigSelection(initialState.basicConfigSelection);
    setIsAdvanced(initialState.isAdvanced);
    setPoolConfigs(initialState.poolConfigs);
    setOverallConfig(initialState.overallConfig);
    setResourceManager(initialState.resourceManager);
  }, [initialState]);

  const addPoolConfig = useCallback(() => {
    setPoolConfigs((poolConfigs) => [getEmptyPoolConfig(), ...poolConfigs]);
  }, [setPoolConfigs]);

  const removePoolConfig = useCallback(
    (key: string) => {
      const poolConfigsCopy = [...poolConfigs];
      const idx = poolConfigsCopy.findIndex((a) => a.key === key);
      poolConfigsCopy.splice(idx, 1);
      setPoolConfigs(poolConfigsCopy);
    },
    [poolConfigs],
  );

  const updatePoolConfig = useCallback(
    (key: string, form: FormInstance) => {
      const poolConfigsCopy = [...poolConfigs];
      const idx = poolConfigsCopy.findIndex((a) => a.key === key);
      poolConfigsCopy[idx] = { ...poolConfigsCopy[idx], ...form.getFieldsValue() };
      setPoolConfigs(poolConfigsCopy);
    },
    [poolConfigs],
  );

  const updateResourceManager = useCallback(
    (keys, value) => {
      const rm = { ...resourceManager };
      keys.forEach((key: DefaultResourcePool) => {
        if (key === DefaultResourcePool.aux) {
          rm.default_aux_resource_pool = value;
        }
        if (key === DefaultResourcePool.compute) {
          rm.default_compute_resource_pool = value;
        }
      });
      setResourceManager(rm);
    },
    [setResourceManager, resourceManager],
  );

  const toggleConfigType = useCallback(() => {
    setIsAdvanced((isAdvanced) => !isAdvanced);
  }, [setIsAdvanced]);

  useEffect(() => {
    const latestVersion = getLatestVersion(supportedDetVersions);
    if (overallConfig.detVersion === '' && latestVersion !== undefined) {
      // set default detVersion value once supportedDetVersions is loaded:
      setOverallConfig((c) => ({
        ...c,
        detVersion: latestVersion,
      }));
    }
  }, [supportedDetVersions, overallConfig.detVersion]);

  const handleOk = useCallback(async () => {
    // handleOk uses async/await so that if `generateMasterConfig` throws a data validation error,
    // it can return a rejected promise, which prevents modal from closing
    let clusterRequest;
    const defaultDetVersion = getLatestVersion(supportedDetVersions) || '';
    if (isAdvanced) {
      // call `generateMasterConfig` outside of try/catch block
      // to avoid calling `handleError` if `generateMasterConfig` throws a data validation error:
      const masterConfig = generateMasterConfig(poolConfigs, resourceManager, true);
      const advancedRequest = { ...overallConfig, masterConfig };
      clusterRequest = { ...advancedRequest, name: clusterName };
    } else if (basicConfigSelection === BasicConfigOption.Standard) {
      clusterRequest = {
        ...basicStandardClusterRequest,
        detVersion: defaultDetVersion,
        name: clusterName,
      };
    } else if (basicConfigSelection === BasicConfigOption.Pro) {
      clusterRequest = {
        ...basicProClusterRequest,
        detVersion: defaultDetVersion,
        name: clusterName,
      };
    }
    if (!clusterRequest) {
      throw handleError(undefined, {
        level: ErrorLevel.Error,
        publicSubject: 'Failed to create cluster',
        silent: false,
      });
    } else {
      if (!clusterRequest.name) {
        throw handleError(undefined, {
          level: ErrorLevel.Error,
          publicSubject: 'Cluster name required',
          silent: false,
        });
      }
      if (!clusterRequest.detVersion) {
        throw handleError(undefined, {
          level: ErrorLevel.Error,
          publicSubject: 'Determined version required',
          silent: false,
        });
      }
      try {
        await createCluster(
          {
            cluster: clusterRequest,
            orgId: selectedOrg?.id ?? '',
            regionId: clusterRegion,
          },
          { signal: canceler.signal },
        );
      } catch (error) {
        handleError(error, {
          level: ErrorLevel.Error,
          publicSubject: 'Failed to create cluster',
          silent: false,
        });
      }
    }
  }, [
    clusterRegion,
    canceler.signal,
    clusterName,
    poolConfigs,
    overallConfig,
    isAdvanced,
    basicConfigSelection,
    supportedDetVersions,
    selectedOrg,
    resourceManager,
  ]);

  useEffect(() => {
    if (supportMatrix) setSupportedDetVersions(supportMatrix.supportedDetVersions);
  }, [supportMatrix]);

  const modalProps: ModalFuncProps = useMemo(() => {
    return {
      closable: true,
      content: (
        <NewClusterModalContent
          basicConfigSelection={basicConfigSelection}
          clusterName={clusterName}
          clusterRegion={clusterRegion}
          isAdvanced={isAdvanced}
          overallConfig={overallConfig}
          poolConfigs={poolConfigs}
          resourceManager={resourceManager}
          supportedDetVersions={supportedDetVersions}
          onAddPoolConfig={addPoolConfig}
          onRemovePoolConfig={removePoolConfig}
          onSelectBasicOption={setBasicConfigSelection}
          onToggleConfigType={toggleConfigType}
          onUpdateClusterName={setClusterName}
          onUpdateClusterRegion={setClusterRegion}
          onUpdateOverallConfig={setOverallConfig}
          onUpdatePoolConfig={updatePoolConfig}
          onUpdateResourceManager={updateResourceManager}
        />
      ),
      icon: null,
      okButtonProps: { disabled: poolConfigs.length === 0 || supportedDetVersions.length === 0 },
      okText: 'Create Cluster',
      onOk: handleOk,
      title: 'New Cluster',
      width: 600,
    };
  }, [
    poolConfigs,
    handleOk,
    removePoolConfig,
    addPoolConfig,
    updatePoolConfig,
    toggleConfigType,
    basicConfigSelection,
    overallConfig,
    supportedDetVersions,
    setClusterName,
    clusterName,
    clusterRegion,
    isAdvanced,
    resourceManager,
    updateResourceManager,
  ]);

  const modalOpen = useCallback(
    (initialModalProps: ModalFuncProps = {}) => {
      resetModalState();
      openOrUpdate({ ...modalProps, ...initialModalProps });
    },
    [modalProps, openOrUpdate, resetModalState],
  );

  useEffect(() => {
    if (modalHooks.modalRef.current) openOrUpdate(modalProps);
  }, [modalProps, modalHooks.modalRef, openOrUpdate]);

  return { modalOpen, ...modalHooks };
};

export default useModalNewCluster;
