import { CheckOutlined, DownOutlined, PlusOutlined } from '@ant-design/icons';
import { Dropdown, Menu, Tooltip } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import Link from 'components/Link';
import { useStore } from 'contexts/Store';
import { useFetchOrgs } from 'hooks/useFetch';
import * as GlobalApi from 'services/global-bindings';
import Avatar, { Size } from 'shared/components/Avatar';
import { DarkLight } from 'shared/themes';

import CreateNewOrgModal from './NewOrgModal';
import css from './OrgPicker.module.scss';

type Org = GlobalApi.ModelListOrgsEntry;

export interface OrgPickerProps {
  darkLight?: DarkLight;
  /** Denotes whether the picker should be minimal to fit in a small space or not.  */
  isCollapsed?: boolean;
  onSelect: (orgId: string) => void;
  orgs: GlobalApi.ModelListOrgsEntry[];
  selectedId: string;
  truncate?: boolean;
}

interface SingleOrgPickerProps {
  darkLight: DarkLight;
  displayId?: boolean;
  isCollapsed?: boolean;
  mini?: boolean;
  org: Org;
  selected?: boolean;
  size?: Size;
  truncate?: boolean;
}

// FIXME: how do we do transitions for navbar items for `mini`?
export const SingleOrg: React.FC<SingleOrgPickerProps> = ({
  darkLight,
  displayId = false,
  isCollapsed = false,
  mini,
  org,
  selected,
  size = Size.Medium,
  truncate = false,
}) => {
  const baseClasses = [css.singleOrg];
  if (selected) baseClasses.push(css.selected);
  if (mini) baseClasses.push(css.mini);

  return (
    <div className={baseClasses.join(' ')}>
      <Avatar darkLight={darkLight} displayName={org.name} hideTooltip noColor size={size} square />
      <div className={css.text} hidden={isCollapsed}>
        <div className={css.name + (truncate ? ` ${css.truncate}` : '')}>{org.name}</div>
        {displayId && <div className={css.id}>{org.id}</div>}
      </div>
      {selected && <CheckOutlined className="checkmark" />}
    </div>
  );
};

export const OrgPicker: React.FC<OrgPickerProps> = ({
  darkLight = DarkLight.Light,
  isCollapsed = false,
  onSelect,
  orgs,
  selectedId,
  truncate,
}) => {
  const [isCreatingOrg, setIsCreatingOrg] = React.useState(false);
  const [canceler] = useState<AbortController>(new AbortController());
  const fetchOrgs = useFetchOrgs(canceler);
  const [selectedOrg, setSelectedOrg] = useState<GlobalApi.ModelListOrgsEntry | undefined>(
    undefined,
  );
  const { supportMatrix } = useStore();

  useEffect(() => {
    const selection = orgs.find((org) => org.id === selectedId);
    setSelectedOrg(selection);
  }, [orgs, selectedId]);

  const toggleModal = useCallback(() => {
    setIsCreatingOrg((cur) => !cur);
    fetchOrgs();
  }, [fetchOrgs]);

  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  const newOrgText = 'New Organization';

  const menuItems = [
    ...orgs.map((org) => {
      return {
        key: org.id,
        label: (
          <Link onClick={() => onSelect(org.id)}>
            <SingleOrg
              darkLight={darkLight}
              org={org}
              selected={org.id === selectedId}
              truncate={truncate}
            />
          </Link>
        ),
      };
    }),
    {
      disabled: true,
      key: 'divider',
      label: <hr />,
    },
    {
      key: 'new-org',
      label: (
        <Link disabled={!supportMatrix?.newOrgs} onClick={toggleModal}>
          {newOrgText}
        </Link>
      ),
    },
  ];

  if (orgs.length === 0) {
    return <p>There are no organizations to list</p>;
  }

  if (orgs.length === 1) {
    if (selectedId !== orgs[0].id) {
      onSelect(orgs[0].id);
    }
    if (isCollapsed)
      return (
        <SingleOrg
          darkLight={darkLight}
          isCollapsed={isCollapsed}
          org={orgs[0]}
          truncate={truncate}
        />
      );

    return (
      <>
        <div className={css.oneOrg}>
          <SingleOrg
            darkLight={darkLight}
            isCollapsed={isCollapsed}
            org={orgs[0]}
            truncate={truncate}
          />
          {supportMatrix?.newOrgs && (
            <Tooltip title={newOrgText}>
              <PlusOutlined onClick={toggleModal} />
            </Tooltip>
          )}
        </div>
        <CreateNewOrgModal close={toggleModal} isVisible={isCreatingOrg} />
      </>
    );
  }

  return (
    <>
      <Dropdown overlay={<Menu items={menuItems} />} placement="bottomRight">
        <div className={css.orgFace}>
          {selectedOrg ? (
            <SingleOrg
              darkLight={darkLight}
              isCollapsed={isCollapsed}
              org={selectedOrg}
              truncate={truncate}
            />
          ) : (
            <p>Select an organization</p>
          )}
          {!isCollapsed && <DownOutlined />}
        </div>
      </Dropdown>
      <CreateNewOrgModal close={toggleModal} isVisible={isCreatingOrg} />
    </>
  );
};

export default OrgPicker;
