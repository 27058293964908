import React, { useEffect, useLayoutEffect, useState } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import Navigation from 'components/Navigation';
import Router from 'components/Router';
import StoreProvider, { useStore } from 'contexts/Store';
import { useFetchClusters } from 'experimental/notifications/hooks';
import { notifStorage } from 'experimental/notifications/storage';
import { useFetchOrgs } from 'hooks/useFetch';
import useKeyTracker from 'hooks/useKeyTracker';
import usePageVisibility from 'hooks/usePageVisibility';
import useResize from 'hooks/useResize';
import useTheme from 'hooks/useTheme';
import appRoutes from 'routes';
import usePolling from 'shared/hooks/usePolling';
import { correctViewportHeight } from 'utils/browser';

import css from './App.module.scss';

const AppView: React.FC = () => {
  const resize = useResize();
  const {
    orgState: { selectedOrg },
    auth: { user },
  } = useStore();
  const [canceler] = useState(new AbortController());
  useTheme();

  const doFetchOrgs = useFetchOrgs(canceler);
  const doFetchClusters = useFetchClusters(canceler, true);

  usePolling(doFetchOrgs, { interval: 60000, rerunOnNewFn: true, runImmediately: true });

  useKeyTracker();
  usePageVisibility();

  // BEGIN experimental notification addtion.
  usePolling(doFetchClusters, { continueWhenHidden: true, interval: 5000 });
  useEffect(() => {
    if (!selectedOrg || !user) return;
    notifStorage.setup(selectedOrg.id, user.userID);
  }, [selectedOrg, user]);
  // END experimental notification addtion.

  // Abort cancel signal when app unmounts.
  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  // Correct the viewport height size when window resize occurs.
  useLayoutEffect(() => correctViewportHeight(), [resize]);

  return (
    <div className={css.base}>
      <Navigation>
        <main>
          <Router routes={appRoutes} />
        </main>
      </Navigation>
    </div>
  );
};

const App: React.FC = () => {
  return (
    <HelmetProvider>
      <StoreProvider>
        <AppView />
      </StoreProvider>
    </HelmetProvider>
  );
};

export default App;
