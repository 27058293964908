import { useCallback } from 'react';

import { useStore } from 'contexts/Store';
import { ClusterState } from 'saasTypes';
import { ModelClusterInfo } from 'services/global-bindings';

import { fetchSelectRegionalClusters } from './api';
import { openNotification } from './popup';
import { notifStorage, SubscriptionType } from './storage';

/**
 *
 * @param canceler
 * @param skipIfNoSubscribers determines if we should skip if there are
 * no subscribers. defaults to false.
 * @returns
 */
export const useFetchClusters = (
  canceler: AbortController,
  skipIfNoSubscribers = false,
): (() => Promise<ModelClusterInfo[] | void>) => {
  const {
    orgState: { selectedOrg },
    supportMatrix,
  } = useStore();

  const doFetch = useCallback(async () => {
    if (!supportMatrix?.supportedLocations || !selectedOrg?.id) return;
    if (
      skipIfNoSubscribers &&
      (!notifStorage.isStorageSetup() || !notifStorage.hasActiveSubscriptions)
    )
      return;
    const clusters = await fetchSelectRegionalClusters(
      {
        orgId: selectedOrg.id,
        regions: supportMatrix.supportedLocations,
      },
      { signal: canceler.signal },
    );

    Object.entries(clusters).forEach(([regionId, regionClusters]) => {
      triggerNotifications(regionId, regionClusters);
    });

    return Object.values(clusters).flat();
  }, [canceler, selectedOrg?.id, skipIfNoSubscribers, supportMatrix?.supportedLocations]);

  return doFetch;
};

/** denotes that we should stop listenting for this ask.
 * either the condition has been met now or in the past or it has failed.
 */
const settleConditions: Record<SubscriptionType, (c: ModelClusterInfo) => boolean> = {
  [SubscriptionType.ClusterCreated]: (c) => c.state !== ClusterState.Creating,
  [SubscriptionType.ClusterDeleted]: (c) => c.state !== ClusterState.Deleting,
  [SubscriptionType.ClusterPaused]: (c) => c.state !== ClusterState.Pausing,
  [SubscriptionType.ClusterResumed]: (c) => c.state !== ClusterState.Resuming,
  [SubscriptionType.ClusterUpgraded]: (c) => c.state !== ClusterState.Upgrading,
  [SubscriptionType.ClusterReconfigured]: (c) => c.state !== ClusterState.Reconfiguring,
  [SubscriptionType.ClusterReprovisioned]: (c) => c.state !== ClusterState.Reprovisioning,
};

const triggerNotifications = (regionId: string, clusters: ModelClusterInfo[]): void => {
  if (!notifStorage.isStorageSetup()) return;
  const subs = notifStorage.listClusterSubs()[regionId] || [];
  clusters
    .filter((c) => c.id in subs)
    .forEach((c) => {
      const subType = subs[c.id];
      if (settleConditions[subType](c)) {
        notifStorage.removeClusterSub(c);
        openNotification(c, { id: c.id, type: subType });
      }
    });
};

export default {};
