import React, { useCallback, useEffect, useState } from 'react';

import CreateNewOrgModal from 'components/OrgPicker/NewOrgModal';
import PageMessage from 'components/PageMessage';
import { useStore } from 'contexts/Store';
import { useFetchOrgs, useFetchSupportMatrix } from 'hooks/useFetch';
import Message from 'shared/components/Message';
import Spinner from 'shared/components/Spinner';
import useUI from 'shared/contexts/stores/UI';

import appCss from '../../App.module.scss'; // FIXME

const Onboarding: React.FC = () => {
  const [canceler] = useState(new AbortController());
  const doFetchOrgs = useFetchOrgs(canceler);
  const { supportMatrix } = useStore();
  const { actions: uiActions } = useUI();
  const fetchSupportMatrix = useFetchSupportMatrix(canceler);
  useEffect(() => {
    uiActions.hideSpinner();
    uiActions.hideChrome();
    return uiActions.showChrome;
  }, [uiActions]);

  const onClose = useCallback(async () => {
    // verify that the org has been created.
    const orgs = await doFetchOrgs();
    if (!orgs) return; // request aborted or failed;
    const isOrgCreated = orgs.length === 0;
    if (!isOrgCreated) return;
    // TODO handle unhappy path
  }, [doFetchOrgs]);

  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  useEffect(() => {
    if (!supportMatrix) fetchSupportMatrix();
  }, [supportMatrix, fetchSupportMatrix]);

  return (
    <div className={appCss.base}>
      {supportMatrix ? (
        supportMatrix.newOrgs ? (
          <PageMessage title="Onboarding">
            <CreateNewOrgModal close={onClose} isVisible={true} />
          </PageMessage>
        ) : (
          <Message title="Please contact your administrator to be added to the organization" />
        )
      ) : (
        <PageMessage title="">
          <Spinner />
        </PageMessage>
      )}
    </div>
  );
};

export default Onboarding;
