import { DownOutlined } from '@ant-design/icons';
import { Button, Table, Tabs } from 'antd';
import { ColumnsType } from 'antd/es/table';
import React, { useCallback, useEffect, useState } from 'react';

import Dropdown from 'components/Dropdown';
import { useStore } from 'contexts/Store';

import css from './InstanceTypeDropdown.module.scss';
import {
  auxCategories,
  computeCategories,
  InstanceTypeInfo,
  InstanceTypeValues,
  KEY_COLUMN_NAME,
  masterCategories,
} from './InstanceTypeDropdown.values';
export { auxCategories, computeCategories };

const { TabPane } = Tabs;

interface TableProps {
  category: InstanceTypeCategory;
  onSelect: (instanceType: string) => void;
}
const InstanceTypeCategoryTable = ({ category, onSelect }: TableProps) => {
  const onTableRow = useCallback(
    (record) => {
      return {
        onClick: () => {
          onSelect(`${category.categoryKey}.${record[KEY_COLUMN_NAME]}`);
        },
      };
    },
    [onSelect, category.categoryKey],
  );

  return (
    <Table<InstanceTypeInfo>
      className={css.dropdownTable}
      columns={category.columns}
      dataSource={category.rows}
      pagination={false}
      rowClassName={css.dropdownTableRow}
      rowKey="instance"
      size="small"
      onRow={onTableRow}
    />
  );
};

interface DropdownProps {
  buttonLabel?: string;
  clusterRegion: string;
  disableAutoDismiss: boolean;
  hide: boolean;
  onSelect: (instanceType: string) => void;
  onVisibleChange: () => void;
  showArrow: boolean;
  use: InstanceUse;
}
const InstanceTypeDropdown: React.FC<DropdownProps> = ({
  disableAutoDismiss,
  clusterRegion,
  buttonLabel,
  hide,
  onSelect,
  onVisibleChange,
  showArrow,
  use,
}: DropdownProps) => {
  const { supportMatrix } = useStore();
  const [tabsData, setTableData] = useState<InstanceTypeCategory[]>();

  const getTabsData = (supportedInstanceTypes: string[]) => {
    interface CategoriesData {
      [categoryKey: string]: InstanceTypeCategory;
    }
    const categoriesData: CategoriesData = {};

    supportedInstanceTypes.forEach((t) => {
      if (typeof t !== 'string' || t.split('.').length < 2) return;
      const categoryKey = t.split('.')[0];
      const size = t.split('.')[1];
      const category = categoriesData[categoryKey] ?? {
        columns: [],
        description: '',
        rows: [],
      };
      if (InstanceTypeValues[categoryKey]) {
        category.rows.push(InstanceTypeValues[categoryKey].values[size]);
        category.description = InstanceTypeValues[categoryKey].description;
        category.columns = InstanceTypeValues[categoryKey]?.columns;
      } else {
        category.rows.push({ [KEY_COLUMN_NAME]: size });
        category.columns = [
          {
            dataIndex: KEY_COLUMN_NAME,
            title: KEY_COLUMN_NAME,
          },
        ];
      }
      category.categoryKey = categoryKey;
      categoriesData[categoryKey] = category;
    });
    return Object.values(categoriesData);
  };

  const getTypesToDisplay = (supportedAgentTypes: string[], knownCategories: string[]) => {
    return supportedAgentTypes.filter((t) => {
      const categoryKey = t.split('.')[0];
      return (
        knownCategories.includes(categoryKey) ||
        // always include unknown categories:
        (!auxCategories.includes(categoryKey) &&
          !computeCategories.includes(categoryKey) &&
          !masterCategories.includes(categoryKey))
      );
    });
  };

  useEffect(() => {
    if (!supportMatrix) return;
    let typesToDisplay: string[] = [];
    if (use === InstanceUse.Master) {
      typesToDisplay = supportMatrix.supportedMasterTypes[clusterRegion];
    }
    if (use === InstanceUse.Aux) {
      typesToDisplay = getTypesToDisplay(
        supportMatrix.supportedAgentTypes[clusterRegion],
        auxCategories,
      );
    }
    if (use === InstanceUse.Compute) {
      typesToDisplay = getTypesToDisplay(
        supportMatrix.supportedAgentTypes[clusterRegion],
        computeCategories,
      );
    }
    const tabsData = getTabsData(typesToDisplay);
    if (tabsData) setTableData(tabsData);
  }, [clusterRegion, supportMatrix, use]);

  return (
    <Dropdown
      content={
        <Tabs type="card">
          {tabsData?.map((category) => {
            return (
              <TabPane key={category.categoryKey} tab={category.categoryKey}>
                {category.description && <span>{category.description}</span>}
                <div className={css.tableWrapper}>
                  <InstanceTypeCategoryTable category={category} onSelect={onSelect} />
                </div>
              </TabPane>
            );
          })}
        </Tabs>
      }
      disableAutoDismiss={disableAutoDismiss}
      displayToggle={true}
      hide={hide}
      showArrow={showArrow}
      onVisibleChange={onVisibleChange}>
      <Button className={css.dropdownButton}>
        <span className={css.label}>{buttonLabel}</span>
        <span className={css.icon}>
          <DownOutlined />
        </span>
      </Button>
    </Dropdown>
  );
};

export enum InstanceUse {
  Aux = 'aux',
  Compute = 'compute',
  Master = 'master',
}

interface InstanceTypeCategory {
  categoryKey: string;
  columns: ColumnsType<InstanceTypeInfo>;
  description: string;
  rows: InstanceTypeInfo[];
}

export default InstanceTypeDropdown;
