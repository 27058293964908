import { Alert, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import { useStore } from 'contexts/Store';
import { useFetchSupportMatrix } from 'hooks/useFetch';

import css from './RegionPicker.module.scss';

const { Option } = Select;

interface Props {
  curRegion?: string;
  onChange: (change: string) => void;
}

const RegionPicker: React.FC<Props> = ({ curRegion, onChange }: Props) => {
  const [regions, setRegions] = useState<string[]>();
  const { supportMatrix } = useStore();
  const [canceler] = useState(new AbortController());
  const fetchSupportMatrix = useFetchSupportMatrix(canceler);

  const selectDefaultRegions = useCallback(() => {
    if (regions) onChange(regions[0]);
  }, [regions, onChange]);

  useEffect(() => {
    if (!supportMatrix) {
      fetchSupportMatrix();
    } else {
      setRegions(supportMatrix.supportedLocations);
      if (!curRegion) selectDefaultRegions();
    }
  }, [supportMatrix, fetchSupportMatrix, selectDefaultRegions, curRegion]);

  return (
    <>
      {regions && regions.length > 1 && (
        <div className={css.alert}>
          <Alert
            message="Cluster region cannot be changed after cluster creation."
            type="warning"
          />
        </div>
      )}
      <Select
        disabled={regions && regions.length === 1}
        value={curRegion}
        onChange={(value) => {
          onChange(value);
        }}>
        {regions?.map((region) => (
          <Option key={region}>{region}</Option>
        ))}
      </Select>
    </>
  );
};

export default RegionPicker;
