/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Determined AI Cloud - Global Management API
 * This service is
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://global.dev.det-cloud.net:4930".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface ModelClusterAccessInfo
 */
export interface ModelClusterAccessInfo {
    /**
     * 
     * @type {string}
     * @memberof ModelClusterAccessInfo
     */
    detVersion: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterAccessInfo
     */
    endpoint: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterAccessInfo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterAccessInfo
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterAccessInfo
     */
    name: string;
    /**
     * 
     * @type {Array<ModelResourcePoolConfig>}
     * @memberof ModelClusterAccessInfo
     */
    resourcePools: Array<ModelResourcePoolConfig>;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterAccessInfo
     */
    role: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterAccessInfo
     */
    state: string;
}

/**
 * 
 * @export
 * @interface ModelClusterInfo
 */
export interface ModelClusterInfo {
    /**
     * 
     * @type {string}
     * @memberof ModelClusterInfo
     */
    detVersion: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterInfo
     */
    endpoint: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterInfo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterInfo
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterInfo
     */
    name: string;
    /**
     * 
     * @type {Array<ModelResourcePoolConfig>}
     * @memberof ModelClusterInfo
     */
    resourcePools: Array<ModelResourcePoolConfig>;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterInfo
     */
    state: string;
}

/**
 * 
 * @export
 * @interface ModelClusterUser
 */
export interface ModelClusterUser {
    /**
     * 
     * @type {string}
     * @memberof ModelClusterUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterUser
     */
    role: string;
}

/**
 * 
 * @export
 * @interface ModelCreateOrgRequest
 */
export interface ModelCreateOrgRequest {
    /**
     * 
     * @type {string}
     * @memberof ModelCreateOrgRequest
     */
    name: string;
}

/**
 * 
 * @export
 * @interface ModelCreateOrgResponse
 */
export interface ModelCreateOrgResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelCreateOrgResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateOrgResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateOrgResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelErrorRESTResponse
 */
export interface ModelErrorRESTResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelErrorRESTResponse
     */
    error: string;
    /**
     * 
     * @type {string}
     * @memberof ModelErrorRESTResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelErrorRESTResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelGetClusterOverridesResponse
 */
export interface ModelGetClusterOverridesResponse {
    /**
     * 
     * @type {{ [key: string]: Array<ModelClusterUser>; }}
     * @memberof ModelGetClusterOverridesResponse
     */
    clusters: { [key: string]: Array<ModelClusterUser>; };
    /**
     * 
     * @type {string}
     * @memberof ModelGetClusterOverridesResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGetClusterOverridesResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelGetClusterUsersResponse
 */
export interface ModelGetClusterUsersResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelGetClusterUsersResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGetClusterUsersResponse
     */
    requestId: string;
    /**
     * 
     * @type {Array<ModelClusterUser>}
     * @memberof ModelGetClusterUsersResponse
     */
    users: Array<ModelClusterUser>;
}

/**
 * 
 * @export
 * @interface ModelGetOrgUsersResponse
 */
export interface ModelGetOrgUsersResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelGetOrgUsersResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGetOrgUsersResponse
     */
    requestId: string;
    /**
     * 
     * @type {Array<ModelOrgUser>}
     * @memberof ModelGetOrgUsersResponse
     */
    users: Array<ModelOrgUser>;
}

/**
 * 
 * @export
 * @interface ModelGetSupportMatrixResponse
 */
export interface ModelGetSupportMatrixResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelGetSupportMatrixResponse
     */
    location: string;
    /**
     * 
     * @type {boolean}
     * @memberof ModelGetSupportMatrixResponse
     */
    newOrgs: boolean;
    /**
     * 
     * @type {string}
     * @memberof ModelGetSupportMatrixResponse
     */
    requestId: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ModelGetSupportMatrixResponse
     */
    supportedAgentTypes: { [key: string]: Array<string>; };
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelGetSupportMatrixResponse
     */
    supportedDetVersions: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof ModelGetSupportMatrixResponse
     */
    supportedLocations: Array<string>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof ModelGetSupportMatrixResponse
     */
    supportedMasterTypes: { [key: string]: Array<string>; };
}

/**
 * 
 * @export
 * @interface ModelGetUserClustersResponse
 */
export interface ModelGetUserClustersResponse {
    /**
     * 
     * @type {Array<ModelClusterAccessInfo>}
     * @memberof ModelGetUserClustersResponse
     */
    clusters: Array<ModelClusterAccessInfo>;
    /**
     * 
     * @type {string}
     * @memberof ModelGetUserClustersResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGetUserClustersResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelGlobalHealthCheckResponse
 */
export interface ModelGlobalHealthCheckResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelGlobalHealthCheckResponse
     */
    location: string;
    /**
     * 
     * @type {number}
     * @memberof ModelGlobalHealthCheckResponse
     */
    numKanbanOrgs: number;
    /**
     * 
     * @type {number}
     * @memberof ModelGlobalHealthCheckResponse
     */
    orgKanbanSize: number;
    /**
     * 
     * @type {string}
     * @memberof ModelGlobalHealthCheckResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelInviteOrgUserRequest
 */
export interface ModelInviteOrgUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ModelInviteOrgUserRequest
     */
    defaultClusterRole: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInviteOrgUserRequest
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ModelInviteOrgUserRequest
     */
    role: string;
}

/**
 * 
 * @export
 * @interface ModelListClustersResponse
 */
export interface ModelListClustersResponse {
    /**
     * 
     * @type {Array<ModelClusterInfo>}
     * @memberof ModelListClustersResponse
     */
    clusters: Array<ModelClusterInfo>;
    /**
     * 
     * @type {string}
     * @memberof ModelListClustersResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelListClustersResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelListOrgsEntry
 */
export interface ModelListOrgsEntry {
    /**
     * 
     * @type {string}
     * @memberof ModelListOrgsEntry
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelListOrgsEntry
     */
    name: string;
}

/**
 * 
 * @export
 * @interface ModelListOrgsResponse
 */
export interface ModelListOrgsResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelListOrgsResponse
     */
    location: string;
    /**
     * 
     * @type {Array<ModelListOrgsEntry>}
     * @memberof ModelListOrgsResponse
     */
    orgs: Array<ModelListOrgsEntry>;
    /**
     * 
     * @type {string}
     * @memberof ModelListOrgsResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelOrgUser
 */
export interface ModelOrgUser {
    /**
     * 
     * @type {string}
     * @memberof ModelOrgUser
     */
    defaultClusterRole: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOrgUser
     */
    email: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOrgUser
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOrgUser
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ModelOrgUser
     */
    role: string;
}

/**
 * 
 * @export
 * @interface ModelProviderConfig
 */
export interface ModelProviderConfig {
    /**
     * 
     * @type {boolean}
     * @memberof ModelProviderConfig
     */
    cpu_slots_allowed: boolean;
    /**
     * These are from AWSClusterConfig upstream, which is also included via github.com/determined-ai/determined/master/pkg/union
     * @type {string}
     * @memberof ModelProviderConfig
     */
    instance_type: string;
    /**
     * 
     * @type {number}
     * @memberof ModelProviderConfig
     */
    max_instances: number;
    /**
     * When we support multiple clouds, this field should be handled by something like github.com/determined-ai/determined/master/pkg/union This should always be 'aws' right now, and is here to ensure we don't break compatibility when we add multi-cloud support
     * @type {string}
     * @memberof ModelProviderConfig
     */
    type: string;
}

/**
 * 
 * @export
 * @interface ModelRESTResponse
 */
export interface ModelRESTResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelRESTResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelRESTResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelRefreshJWTResponse
 */
export interface ModelRefreshJWTResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelRefreshJWTResponse
     */
    jwt: string;
    /**
     * 
     * @type {string}
     * @memberof ModelRefreshJWTResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelRefreshJWTResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelResourcePoolConfig
 */
export interface ModelResourcePoolConfig {
    /**
     * 
     * @type {number}
     * @memberof ModelResourcePoolConfig
     */
    max_aux_containers_per_agent: number;
    /**
     * 
     * @type {string}
     * @memberof ModelResourcePoolConfig
     */
    pool_name: string;
    /**
     * 
     * @type {ModelProviderConfig}
     * @memberof ModelResourcePoolConfig
     */
    provider: ModelProviderConfig;
}

/**
 * 
 * @export
 * @interface ModelUpdateClusterUserRequest
 */
export interface ModelUpdateClusterUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ModelUpdateClusterUserRequest
     */
    role: string;
}

/**
 * 
 * @export
 * @interface ModelUpdateOrgUserRequest
 */
export interface ModelUpdateOrgUserRequest {
    /**
     * 
     * @type {string}
     * @memberof ModelUpdateOrgUserRequest
     */
    defaultClusterRole: string;
    /**
     * 
     * @type {string}
     * @memberof ModelUpdateOrgUserRequest
     */
    role: string;
}


/**
 * AuthzApi - fetch parameter creator
 * @export
 */
export const AuthzApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * deletes a cluster-level role setting for a user
         * @summary Delete a cluster access override
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClusterOverride(orgId: string, clusterId: string, userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling deleteClusterOverride.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling deleteClusterOverride.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteClusterOverride.');
            }
            const localVarPath = `/orgs/{orgId}/clusters/{clusterId}/users/{userId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * delete a user from an organization
         * @summary Delete a user from an organization
         * @param {string} orgId organization ID
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrgUser(orgId: string, userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling deleteOrgUser.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling deleteOrgUser.');
            }
            const localVarPath = `/orgs/{orgId}/users/{userId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns all of the explicitly set cluster roles in an organization
         * @summary List all cluster access overrides
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterOverrides(orgId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getClusterOverrides.');
            }
            const localVarPath = `/orgs/{orgId}/cluster-authz`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns the effective list of users who can access a cluster after overrides AND defaults are considered
         * @summary Get the list of users with access to a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterUsers(orgId: string, clusterId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getClusterUsers.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling getClusterUsers.');
            }
            const localVarPath = `/orgs/{orgId}/clusters/{clusterId}/users`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list users and their access levels in the organization
         * @summary List users and their access levels in the organization
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgUsers(orgId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getOrgUsers.');
            }
            const localVarPath = `/orgs/{orgId}/users`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * returns the effective list of clusters a user can access after overrides AND defaults are considered
         * @summary Get the list of clusters a user can access
         * @param {string} orgId organization ID
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserClusters(orgId: string, userId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getUserClusters.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling getUserClusters.');
            }
            const localVarPath = `/orgs/{orgId}/users/{userId}/cluster-authz`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * invites a user to join an organization
         * @summary Invite a user to join an organization
         * @param {string} orgId organization ID
         * @param {ModelInviteOrgUserRequest} orgUser user-level settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteOrgUser(orgId: string, orgUser: ModelInviteOrgUserRequest, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling inviteOrgUser.');
            }
            // verify required parameter 'orgUser' is not null or undefined
            if (orgUser === null || orgUser === undefined) {
                throw new RequiredError('orgUser','Required parameter orgUser was null or undefined when calling inviteOrgUser.');
            }
            const localVarPath = `/orgs/{orgId}/invite`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelInviteOrgUserRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(orgUser || {}) : (orgUser || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get an updated JWT
         * @summary Get an updated JWT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshJWT(options: any = {}): FetchArgs {
            const localVarPath = `/jwt`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * sets a specific cluster-level role for a user
         * @summary Set a cluster access override
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {string} userId user ID
         * @param {ModelUpdateClusterUserRequest} role user-level settings for cluster access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterUser(orgId: string, clusterId: string, userId: string, role: ModelUpdateClusterUserRequest, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling updateClusterUser.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling updateClusterUser.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateClusterUser.');
            }
            // verify required parameter 'role' is not null or undefined
            if (role === null || role === undefined) {
                throw new RequiredError('role','Required parameter role was null or undefined when calling updateClusterUser.');
            }
            const localVarPath = `/orgs/{orgId}/clusters/{clusterId}/users/{userId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelUpdateClusterUserRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(role || {}) : (role || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * update organization-level access settings for user
         * @summary Update organization-level access settings for user
         * @param {string} orgId organization ID
         * @param {string} userId user ID
         * @param {ModelUpdateOrgUserRequest} orgUser user-level settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrgUser(orgId: string, userId: string, orgUser: ModelUpdateOrgUserRequest, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling updateOrgUser.');
            }
            // verify required parameter 'userId' is not null or undefined
            if (userId === null || userId === undefined) {
                throw new RequiredError('userId','Required parameter userId was null or undefined when calling updateOrgUser.');
            }
            // verify required parameter 'orgUser' is not null or undefined
            if (orgUser === null || orgUser === undefined) {
                throw new RequiredError('orgUser','Required parameter orgUser was null or undefined when calling updateOrgUser.');
            }
            const localVarPath = `/orgs/{orgId}/users/{userId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"userId"}}`, encodeURIComponent(String(userId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'PUT' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelUpdateOrgUserRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(orgUser || {}) : (orgUser || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * AuthzApi - functional programming interface
 * @export
 */
export const AuthzApiFp = function(configuration?: Configuration) {
    return {
        /**
         * deletes a cluster-level role setting for a user
         * @summary Delete a cluster access override
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClusterOverride(orgId: string, clusterId: string, userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).deleteClusterOverride(orgId, clusterId, userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * delete a user from an organization
         * @summary Delete a user from an organization
         * @param {string} orgId organization ID
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrgUser(orgId: string, userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).deleteOrgUser(orgId, userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * returns all of the explicitly set cluster roles in an organization
         * @summary List all cluster access overrides
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterOverrides(orgId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGetClusterOverridesResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).getClusterOverrides(orgId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * returns the effective list of users who can access a cluster after overrides AND defaults are considered
         * @summary Get the list of users with access to a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterUsers(orgId: string, clusterId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGetClusterUsersResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).getClusterUsers(orgId, clusterId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * list users and their access levels in the organization
         * @summary List users and their access levels in the organization
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgUsers(orgId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGetOrgUsersResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).getOrgUsers(orgId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * returns the effective list of clusters a user can access after overrides AND defaults are considered
         * @summary Get the list of clusters a user can access
         * @param {string} orgId organization ID
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserClusters(orgId: string, userId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGetUserClustersResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).getUserClusters(orgId, userId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * invites a user to join an organization
         * @summary Invite a user to join an organization
         * @param {string} orgId organization ID
         * @param {ModelInviteOrgUserRequest} orgUser user-level settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteOrgUser(orgId: string, orgUser: ModelInviteOrgUserRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelListClustersResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).inviteOrgUser(orgId, orgUser, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get an updated JWT
         * @summary Get an updated JWT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshJWT(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRefreshJWTResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).refreshJWT(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * sets a specific cluster-level role for a user
         * @summary Set a cluster access override
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {string} userId user ID
         * @param {ModelUpdateClusterUserRequest} role user-level settings for cluster access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterUser(orgId: string, clusterId: string, userId: string, role: ModelUpdateClusterUserRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).updateClusterUser(orgId, clusterId, userId, role, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * update organization-level access settings for user
         * @summary Update organization-level access settings for user
         * @param {string} orgId organization ID
         * @param {string} userId user ID
         * @param {ModelUpdateOrgUserRequest} orgUser user-level settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrgUser(orgId: string, userId: string, orgUser: ModelUpdateOrgUserRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelListClustersResponse> {
            const localVarFetchArgs = AuthzApiFetchParamCreator(configuration).updateOrgUser(orgId, userId, orgUser, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * AuthzApi - factory interface
 * @export
 */
export const AuthzApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * deletes a cluster-level role setting for a user
         * @summary Delete a cluster access override
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteClusterOverride(orgId: string, clusterId: string, userId: string, options?: any) {
            return AuthzApiFp(configuration).deleteClusterOverride(orgId, clusterId, userId, options)(fetch, basePath);
        },
        /**
         * delete a user from an organization
         * @summary Delete a user from an organization
         * @param {string} orgId organization ID
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteOrgUser(orgId: string, userId: string, options?: any) {
            return AuthzApiFp(configuration).deleteOrgUser(orgId, userId, options)(fetch, basePath);
        },
        /**
         * returns all of the explicitly set cluster roles in an organization
         * @summary List all cluster access overrides
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterOverrides(orgId: string, options?: any) {
            return AuthzApiFp(configuration).getClusterOverrides(orgId, options)(fetch, basePath);
        },
        /**
         * returns the effective list of users who can access a cluster after overrides AND defaults are considered
         * @summary Get the list of users with access to a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterUsers(orgId: string, clusterId: string, options?: any) {
            return AuthzApiFp(configuration).getClusterUsers(orgId, clusterId, options)(fetch, basePath);
        },
        /**
         * list users and their access levels in the organization
         * @summary List users and their access levels in the organization
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOrgUsers(orgId: string, options?: any) {
            return AuthzApiFp(configuration).getOrgUsers(orgId, options)(fetch, basePath);
        },
        /**
         * returns the effective list of clusters a user can access after overrides AND defaults are considered
         * @summary Get the list of clusters a user can access
         * @param {string} orgId organization ID
         * @param {string} userId user ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUserClusters(orgId: string, userId: string, options?: any) {
            return AuthzApiFp(configuration).getUserClusters(orgId, userId, options)(fetch, basePath);
        },
        /**
         * invites a user to join an organization
         * @summary Invite a user to join an organization
         * @param {string} orgId organization ID
         * @param {ModelInviteOrgUserRequest} orgUser user-level settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        inviteOrgUser(orgId: string, orgUser: ModelInviteOrgUserRequest, options?: any) {
            return AuthzApiFp(configuration).inviteOrgUser(orgId, orgUser, options)(fetch, basePath);
        },
        /**
         * get an updated JWT
         * @summary Get an updated JWT
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        refreshJWT(options?: any) {
            return AuthzApiFp(configuration).refreshJWT(options)(fetch, basePath);
        },
        /**
         * sets a specific cluster-level role for a user
         * @summary Set a cluster access override
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {string} userId user ID
         * @param {ModelUpdateClusterUserRequest} role user-level settings for cluster access
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateClusterUser(orgId: string, clusterId: string, userId: string, role: ModelUpdateClusterUserRequest, options?: any) {
            return AuthzApiFp(configuration).updateClusterUser(orgId, clusterId, userId, role, options)(fetch, basePath);
        },
        /**
         * update organization-level access settings for user
         * @summary Update organization-level access settings for user
         * @param {string} orgId organization ID
         * @param {string} userId user ID
         * @param {ModelUpdateOrgUserRequest} orgUser user-level settings
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateOrgUser(orgId: string, userId: string, orgUser: ModelUpdateOrgUserRequest, options?: any) {
            return AuthzApiFp(configuration).updateOrgUser(orgId, userId, orgUser, options)(fetch, basePath);
        },
    };
};

/**
 * AuthzApi - object-oriented interface
 * @export
 * @class AuthzApi
 * @extends {BaseAPI}
 */
export class AuthzApi extends BaseAPI {
    /**
     * deletes a cluster-level role setting for a user
     * @summary Delete a cluster access override
     * @param {string} orgId organization ID
     * @param {string} clusterId cluster ID
     * @param {string} userId user ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public deleteClusterOverride(orgId: string, clusterId: string, userId: string, options?: any) {
        return AuthzApiFp(this.configuration).deleteClusterOverride(orgId, clusterId, userId, options)(this.fetch, this.basePath);
    }

    /**
     * delete a user from an organization
     * @summary Delete a user from an organization
     * @param {string} orgId organization ID
     * @param {string} userId user ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public deleteOrgUser(orgId: string, userId: string, options?: any) {
        return AuthzApiFp(this.configuration).deleteOrgUser(orgId, userId, options)(this.fetch, this.basePath);
    }

    /**
     * returns all of the explicitly set cluster roles in an organization
     * @summary List all cluster access overrides
     * @param {string} orgId organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public getClusterOverrides(orgId: string, options?: any) {
        return AuthzApiFp(this.configuration).getClusterOverrides(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * returns the effective list of users who can access a cluster after overrides AND defaults are considered
     * @summary Get the list of users with access to a cluster
     * @param {string} orgId organization ID
     * @param {string} clusterId cluster ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public getClusterUsers(orgId: string, clusterId: string, options?: any) {
        return AuthzApiFp(this.configuration).getClusterUsers(orgId, clusterId, options)(this.fetch, this.basePath);
    }

    /**
     * list users and their access levels in the organization
     * @summary List users and their access levels in the organization
     * @param {string} orgId organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public getOrgUsers(orgId: string, options?: any) {
        return AuthzApiFp(this.configuration).getOrgUsers(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * returns the effective list of clusters a user can access after overrides AND defaults are considered
     * @summary Get the list of clusters a user can access
     * @param {string} orgId organization ID
     * @param {string} userId user ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public getUserClusters(orgId: string, userId: string, options?: any) {
        return AuthzApiFp(this.configuration).getUserClusters(orgId, userId, options)(this.fetch, this.basePath);
    }

    /**
     * invites a user to join an organization
     * @summary Invite a user to join an organization
     * @param {string} orgId organization ID
     * @param {ModelInviteOrgUserRequest} orgUser user-level settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public inviteOrgUser(orgId: string, orgUser: ModelInviteOrgUserRequest, options?: any) {
        return AuthzApiFp(this.configuration).inviteOrgUser(orgId, orgUser, options)(this.fetch, this.basePath);
    }

    /**
     * get an updated JWT
     * @summary Get an updated JWT
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public refreshJWT(options?: any) {
        return AuthzApiFp(this.configuration).refreshJWT(options)(this.fetch, this.basePath);
    }

    /**
     * sets a specific cluster-level role for a user
     * @summary Set a cluster access override
     * @param {string} orgId organization ID
     * @param {string} clusterId cluster ID
     * @param {string} userId user ID
     * @param {ModelUpdateClusterUserRequest} role user-level settings for cluster access
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public updateClusterUser(orgId: string, clusterId: string, userId: string, role: ModelUpdateClusterUserRequest, options?: any) {
        return AuthzApiFp(this.configuration).updateClusterUser(orgId, clusterId, userId, role, options)(this.fetch, this.basePath);
    }

    /**
     * update organization-level access settings for user
     * @summary Update organization-level access settings for user
     * @param {string} orgId organization ID
     * @param {string} userId user ID
     * @param {ModelUpdateOrgUserRequest} orgUser user-level settings
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AuthzApi
     */
    public updateOrgUser(orgId: string, userId: string, orgUser: ModelUpdateOrgUserRequest, options?: any) {
        return AuthzApiFp(this.configuration).updateOrgUser(orgId, userId, orgUser, options)(this.fetch, this.basePath);
    }

}

/**
 * ClustersApi - fetch parameter creator
 * @export
 */
export const ClustersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * list clusters; the regional service that corresponds to a cluster is the source of truth, while the global service provides an index for convenience that may be slightly out of date
         * @summary List clusters
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusters(orgId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling listClusters.');
            }
            const localVarPath = `/orgs/{orgId}/clusters`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClustersApi - functional programming interface
 * @export
 */
export const ClustersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * list clusters; the regional service that corresponds to a cluster is the source of truth, while the global service provides an index for convenience that may be slightly out of date
         * @summary List clusters
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusters(orgId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelListClustersResponse> {
            const localVarFetchArgs = ClustersApiFetchParamCreator(configuration).listClusters(orgId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ClustersApi - factory interface
 * @export
 */
export const ClustersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * list clusters; the regional service that corresponds to a cluster is the source of truth, while the global service provides an index for convenience that may be slightly out of date
         * @summary List clusters
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusters(orgId: string, options?: any) {
            return ClustersApiFp(configuration).listClusters(orgId, options)(fetch, basePath);
        },
    };
};

/**
 * ClustersApi - object-oriented interface
 * @export
 * @class ClustersApi
 * @extends {BaseAPI}
 */
export class ClustersApi extends BaseAPI {
    /**
     * list clusters; the regional service that corresponds to a cluster is the source of truth, while the global service provides an index for convenience that may be slightly out of date
     * @summary List clusters
     * @param {string} orgId organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public listClusters(orgId: string, options?: any) {
        return ClustersApiFp(this.configuration).listClusters(orgId, options)(this.fetch, this.basePath);
    }

}

/**
 * HealthCheckApi - fetch parameter creator
 * @export
 */
export const HealthCheckApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return health-check related info
         * @summary Health-check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options: any = {}): FetchArgs {
            const localVarPath = `/health-check`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HealthCheckApi - functional programming interface
 * @export
 */
export const HealthCheckApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Return health-check related info
         * @summary Health-check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGlobalHealthCheckResponse> {
            const localVarFetchArgs = HealthCheckApiFetchParamCreator(configuration).healthCheck(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * HealthCheckApi - factory interface
 * @export
 */
export const HealthCheckApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Return health-check related info
         * @summary Health-check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options?: any) {
            return HealthCheckApiFp(configuration).healthCheck(options)(fetch, basePath);
        },
    };
};

/**
 * HealthCheckApi - object-oriented interface
 * @export
 * @class HealthCheckApi
 * @extends {BaseAPI}
 */
export class HealthCheckApi extends BaseAPI {
    /**
     * Return health-check related info
     * @summary Health-check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthCheckApi
     */
    public healthCheck(options?: any) {
        return HealthCheckApiFp(this.configuration).healthCheck(options)(this.fetch, this.basePath);
    }

}

/**
 * OrgsApi - fetch parameter creator
 * @export
 */
export const OrgsApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create a new organization
         * @summary Create a new organization
         * @param {ModelCreateOrgRequest} org Organization specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrg(org: ModelCreateOrgRequest, options: any = {}): FetchArgs {
            // verify required parameter 'org' is not null or undefined
            if (org === null || org === undefined) {
                throw new RequiredError('org','Required parameter org was null or undefined when calling createOrg.');
            }
            const localVarPath = `/orgs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelCreateOrgRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(org || {}) : (org || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * deactivate an organization
         * @summary Deactivate an organization
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateOrg(orgId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling deactivateOrg.');
            }
            const localVarPath = `/orgs/{orgId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list organizations the user is a part of
         * @summary List organizations the user is a part of
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrgs(options: any = {}): FetchArgs {
            const localVarPath = `/orgs`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * OrgsApi - functional programming interface
 * @export
 */
export const OrgsApiFp = function(configuration?: Configuration) {
    return {
        /**
         * create a new organization
         * @summary Create a new organization
         * @param {ModelCreateOrgRequest} org Organization specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrg(org: ModelCreateOrgRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelCreateOrgResponse> {
            const localVarFetchArgs = OrgsApiFetchParamCreator(configuration).createOrg(org, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * deactivate an organization
         * @summary Deactivate an organization
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateOrg(orgId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = OrgsApiFetchParamCreator(configuration).deactivateOrg(orgId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * list organizations the user is a part of
         * @summary List organizations the user is a part of
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrgs(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelListOrgsResponse> {
            const localVarFetchArgs = OrgsApiFetchParamCreator(configuration).listOrgs(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * OrgsApi - factory interface
 * @export
 */
export const OrgsApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * create a new organization
         * @summary Create a new organization
         * @param {ModelCreateOrgRequest} org Organization specification
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createOrg(org: ModelCreateOrgRequest, options?: any) {
            return OrgsApiFp(configuration).createOrg(org, options)(fetch, basePath);
        },
        /**
         * deactivate an organization
         * @summary Deactivate an organization
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deactivateOrg(orgId: string, options?: any) {
            return OrgsApiFp(configuration).deactivateOrg(orgId, options)(fetch, basePath);
        },
        /**
         * list organizations the user is a part of
         * @summary List organizations the user is a part of
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listOrgs(options?: any) {
            return OrgsApiFp(configuration).listOrgs(options)(fetch, basePath);
        },
    };
};

/**
 * OrgsApi - object-oriented interface
 * @export
 * @class OrgsApi
 * @extends {BaseAPI}
 */
export class OrgsApi extends BaseAPI {
    /**
     * create a new organization
     * @summary Create a new organization
     * @param {ModelCreateOrgRequest} org Organization specification
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public createOrg(org: ModelCreateOrgRequest, options?: any) {
        return OrgsApiFp(this.configuration).createOrg(org, options)(this.fetch, this.basePath);
    }

    /**
     * deactivate an organization
     * @summary Deactivate an organization
     * @param {string} orgId organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public deactivateOrg(orgId: string, options?: any) {
        return OrgsApiFp(this.configuration).deactivateOrg(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * list organizations the user is a part of
     * @summary List organizations the user is a part of
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof OrgsApi
     */
    public listOrgs(options?: any) {
        return OrgsApiFp(this.configuration).listOrgs(options)(this.fetch, this.basePath);
    }

}

/**
 * SupportMatrixApi - fetch parameter creator
 * @export
 */
export const SupportMatrixApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Return the support matrix
         * @summary Return the support matrix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportMatrix(options: any = {}): FetchArgs {
            const localVarPath = `/support-matrix`;
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SupportMatrixApi - functional programming interface
 * @export
 */
export const SupportMatrixApiFp = function(configuration?: Configuration) {
    return {
        /**
         * Return the support matrix
         * @summary Return the support matrix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportMatrix(options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGetSupportMatrixResponse> {
            const localVarFetchArgs = SupportMatrixApiFetchParamCreator(configuration).getSupportMatrix(options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * SupportMatrixApi - factory interface
 * @export
 */
export const SupportMatrixApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * Return the support matrix
         * @summary Return the support matrix
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSupportMatrix(options?: any) {
            return SupportMatrixApiFp(configuration).getSupportMatrix(options)(fetch, basePath);
        },
    };
};

/**
 * SupportMatrixApi - object-oriented interface
 * @export
 * @class SupportMatrixApi
 * @extends {BaseAPI}
 */
export class SupportMatrixApi extends BaseAPI {
    /**
     * Return the support matrix
     * @summary Return the support matrix
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SupportMatrixApi
     */
    public getSupportMatrix(options?: any) {
        return SupportMatrixApiFp(this.configuration).getSupportMatrix(options)(this.fetch, this.basePath);
    }

}

