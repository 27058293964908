import { Form, FormInstance, Input, ModalProps } from 'antd';
import { Modal } from 'antd';
import React, { useCallback, useRef, useState } from 'react';

import AccessLevelSelect from 'components/AccessLevel/AccessLevelSelect';
import { AccessLevel } from 'saasTypes';
import { inviteOrgUser } from 'services/api';
import { ModalCloseReason } from 'shared/hooks/useModal/useModal';
import { Eventually } from 'shared/types';
import { ErrorLevel } from 'shared/utils/error';
import handleError from 'utils/error';

/**
 * common cases offered:
 * - controlling the modal visibility.
 * for open and close: onOk or onCancel manually trigger it
 * - finally cb: (aka on close) called on ok and cancel
 * ModalCloseReason
 * - error handling
 * common cases missing:
 * - form input and validation sync with modal actions
 * issues (maybe from misuse):
 * - cause app level rerenders that react can't reconcile/optimize: eg on every input
 * - tracking modal visibility changes
 *  - https://determinedai.atlassian.net/browse/DET-8041
 *  - saas version: saas/509b6d21fa30940ac12032996fc55e10650ffc90
 */

interface Props extends Omit<ModalProps, 'onOk' | 'onCancel'> {
  /** called after onOk and onCancel. finally? */
  onClose?: (reason: ModalCloseReason) => Eventually<void>;
  orgId: string;
}

interface FormValues {
  defaultClusterRole: AccessLevel;
  orgRole: AccessLevel;
  userId: string;
}

const ModalContent: React.FC<{
  inputs: FormValues;
  setInputs: (inputs: FormValues) => void;
}> = ({ inputs, setInputs }) => {
  const formRef = useRef<FormInstance<FormValues>>(null);

  const readFormInput = useCallback(() => {
    if (!formRef.current) {
      handleError(undefined, {
        isUserTriggered: false,
        level: ErrorLevel.Error,
        publicMessage: 'Form is not initialized',
      });
      return;
    }
    const inputs = formRef.current.getFieldsValue();
    setInputs(inputs);
    return inputs;
  }, [setInputs]);

  return (
    <div>
      <Form<FormValues> initialValues={inputs} ref={formRef}>
        <Form.Item label="Email" name="userId">
          <Input
            autoComplete="off"
            min={4}
            placeholder="User email"
            required
            type="text"
            onChange={readFormInput}
          />
        </Form.Item>
        <Form.Item label="Organization Role" name="orgRole">
          <AccessLevelSelect
            defaultValue={inputs.orgRole}
            onChange={(acl: AccessLevel) => {
              setInputs({ ...inputs, orgRole: acl });
            }}
          />
        </Form.Item>
        <Form.Item label="Default Cluster Role" name="defaultClusterRole">
          <AccessLevelSelect
            defaultValue={inputs.defaultClusterRole}
            onChange={(acl: AccessLevel) => {
              setInputs({ ...inputs, defaultClusterRole: acl });
            }}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

const AddMemberModal: React.FC<Props> = ({ orgId, onClose, ...modalProps }) => {
  const [canceler] = useState(new AbortController());
  const [inputs, setInputs] = useState<FormValues>({
    defaultClusterRole: AccessLevel.User,
    orgRole: AccessLevel.User,
    userId: '',
  });

  const onOk = useCallback(async () => {
    try {
      await inviteOrgUser(
        {
          orgId,
          userInfo: {
            defaultClusterRole: inputs.defaultClusterRole,
            email: inputs.userId,
            role: inputs.orgRole,
          },
        },
        { signal: canceler.signal },
      );
    } catch (error) {
      handleError(error, {
        level: ErrorLevel.Error,
        publicSubject: 'Failed to add member',
        silent: false,
      });
    }
    onClose?.(ModalCloseReason.Ok);
  }, [inputs, canceler.signal, orgId, onClose]);

  return (
    <Modal
      okButtonProps={{ disabled: !inputs.userId }}
      okText="Add"
      title="Add Member"
      onCancel={onClose ? () => onClose(ModalCloseReason.Cancel) : undefined}
      onOk={onOk}
      {...modalProps}>
      <ModalContent inputs={inputs} setInputs={setInputs} />
    </Modal>
  );
};

export default AddMemberModal;
