import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { useStore } from 'contexts/Store';
import useAuthCheck from 'hooks/useAuthCheck';
import Disconnected from 'pages/Disconnected';
import Onboarding from 'pages/Onboarding';
import { defaultRoute } from 'routes';
import { paths } from 'routes/utils';
import { globalIsAlive } from 'services/api';
import Message, { MessageType } from 'shared/components/Message';
import Spinner from 'shared/components/Spinner';
import { ApiState, RouteConfig } from 'shared/types';
import rootLogger from 'shared/utils/Logger';
import { filterOutLoginLocation } from 'shared/utils/routes';
import { isAborted } from 'shared/utils/service';

interface Props {
  routes: RouteConfig[];
}

const logger = rootLogger.extend('components', 'Router');

const Router: React.FC<Props> = (props: Props) => {
  const {
    auth,
    orgState: { orgs, hasBeenInitialized: orgChecked },
  } = useStore();
  const [canceler] = useState(new AbortController());
  const checkAuth = useAuthCheck(canceler);
  const location = useLocation();
  const [isServerReachable, setIsServerReachable] = useState<ApiState<boolean>>({
    hasBeenInitialized: false,
  });
  const [errorMsg, setErrorMsg] = useState<string>('');

  useEffect(() => {
    checkAuth().catch((e) => {
      if (!isAborted(e)) setErrorMsg('Failed to check authentication status.');
    });
  }, [checkAuth]);

  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  useEffect(() => {
    globalIsAlive(5000)
      .then(() =>
        setIsServerReachable((cur) => ({
          ...cur,
          data: true,
          hasBeenInitialized: true,
        })),
      )
      .catch(() =>
        setIsServerReachable((cur) => ({
          ...cur,
          data: false,
          hasBeenInitialized: true,
        })),
      );
  }, []);

  // on first load of the app check for server reachability.
  if (!isServerReachable.hasBeenInitialized) {
    return <Spinner center tip="Checking server connectivity..." />;
  } else {
    if (!isServerReachable.data === true) {
      logger.warn('Server is not reachable');
      return <Disconnected />;
    }
  }

  if (errorMsg) {
    return <Message title={errorMsg} type={MessageType.Warning} />;
  }

  // Do not mount login page until auth is checked.
  if (!auth.checked) {
    return <Spinner center tip="Checking authentication status..." />;
  }

  return (
    <Routes>
      {props.routes.map((config) => {
        const { element, ...route } = config;

        if (route.redirect) {
          return (
            <Route element={<Navigate to={route.redirect} />} key={route.id} path={route.path} />
          );
        }

        if (route.id !== 'signOut' && auth.isAuthenticated && orgChecked && orgs.length === 0) {
          return <Route {...route} element={<Onboarding />} key={route.id} />;
        }

        if (!route.needAuth || auth.isAuthenticated) {
          return <Route {...route} element={element} key={route.id} />;
        }

        // The user needs to be authenticated, but we don't know if they are.

        return (
          <Route
            {...route}
            element={
              <Navigate
                state={{ loginRedirect: filterOutLoginLocation(location) }}
                to={{
                  hash: location.hash,
                  pathname: paths.login(),
                  search: location.search,
                }}
              />
            }
            key={route.id}
          />
        );
      })}
      <Route
        element={
          <Navigate
            to={{ hash: location.hash, pathname: defaultRoute.path, search: location.search }}
          />
        }
        path="*"
      />
    </Routes>
  );
};

export default Router;
