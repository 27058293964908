import { notifStorage, SubscriptionType } from 'experimental/notifications/storage';
import { globalServerAddress } from 'routes/utils';
import { AccessLevel, ClusterDetails, ClusterID } from 'saasTypes';
import { detApi } from 'services/apiConfig';
import * as GlobalApi from 'services/global-bindings';
import * as RegionalApi from 'services/regional-bindings';
import { generateDetApi } from 'shared/utils/service';
import { identity } from 'shared/utils/service';
import { DetError } from 'utils/error';
import { decodePoolConfig } from 'utils/saas';

import { ClusterScope } from './types';

export { isAuthFailure } from 'shared/utils/service';

/*
supported operations on global management plane:
  refreshJWT(

  listOrgs(
  createOrg(
  getOrgUsers(
  updateOrgUser(
  deleteOrgUser(

  listClusters(
  getClusterUsers(
  getClusterOverrides(
  updateClusterUser(
  deleteClusterOverride(

supported operations on regional management plane:
  listClusters(
  createCluster(
  getCluster(
  deleteCluster(
  pauseCluster(
  resumeCluster(
  updateCluster(
*/

/* Auth Apis */
export const refreshToken = generateDetApi<
  void,
  GlobalApi.ModelRefreshJWTResponse,
  { token: string }
>({
  name: 'refreshToken',
  postProcess: (response) => {
    return { token: response.jwt };
  },
  request: (_, options) => detApi.Global.Auth.refreshJWT(options),
});

/* Orgs */
export const fetchOrgs = generateDetApi<
  void,
  GlobalApi.ModelListOrgsResponse,
  GlobalApi.ModelListOrgsEntry[]
>({
  name: 'fetchOrgs',
  postProcess: (response) => response.orgs,
  request: (_, options) => detApi.Global.Orgs.listOrgs(options),
});

export const createOrg = generateDetApi<
  { name: string },
  GlobalApi.ModelCreateOrgResponse,
  GlobalApi.ModelCreateOrgResponse
>({
  name: 'createOrg',
  postProcess: (response) => response,
  request: ({ name }, options) => detApi.Global.Orgs.createOrg({ name }, options),
});

export const getOrgMembers = generateDetApi<
  { orgId: string },
  GlobalApi.ModelGetOrgUsersResponse,
  GlobalApi.ModelOrgUser[]
>({
  name: 'getOrgMembers',
  postProcess: (response) => response.users,
  request: ({ orgId }, options) => detApi.Global.Auth.getOrgUsers(orgId, options),
});

/**
 * Upsert an org member.
 * @param userRoles: the full set of user roles to upsert
 */
export const upsertOrgUser = generateDetApi<
  { orgId: string; userId: string; userRoles: GlobalApi.ModelUpdateOrgUserRequest },
  void,
  void
>({
  name: 'updateOrgUser',
  postProcess: identity,
  request: async ({ orgId, userId, userRoles }, options) => {
    await detApi.Global.Auth.updateOrgUser(orgId, userId, userRoles, options);
  },
});

export const deleteOrgUser = generateDetApi<{ orgId: string; userId: string }, void, void>({
  name: 'deleteOrgUser',
  postProcess: identity,
  request: async ({ orgId, userId }, options) => {
    await detApi.Global.Auth.deleteOrgUser(orgId, userId, options);
  },
});

/**
 * Invite a user to an org
 * @param userRoles: the full set of user roles to upsert
 */
export const inviteOrgUser = generateDetApi<
  { orgId: string; userInfo: GlobalApi.ModelInviteOrgUserRequest },
  void,
  void
>({
  name: 'inviteOrgUser',
  postProcess: identity,
  request: async ({ orgId, userInfo }, options) => {
    await detApi.Global.Auth.inviteOrgUser(orgId, userInfo, options);
  },
});

/* Clusters */
export const fetchClusters = generateDetApi<
  { orgId: string },
  GlobalApi.ModelListClustersResponse,
  GlobalApi.ModelClusterInfo[]
>({
  name: 'fetchClusters',
  postProcess: (response) => response.clusters,
  request: ({ orgId }, options) => detApi.Global.Cluster.listClusters(orgId, options),
});

export const fetchRegionalClusters = generateDetApi<
  ClusterScope,
  GlobalApi.ModelListClustersResponse,
  GlobalApi.ModelClusterInfo[]
>({
  name: 'fetchRegionClusters',
  postProcess: (response) => response.clusters,
  request: (
    { orgId, regionId },
    options, // TODO: this is gonna be a repeated pattern.
  ) => detApi.Regional[regionId].Cluster.listClusters(orgId, options),
});

export const updateClusterUser = generateDetApi<
  {
    clusterId: string;
    orgId: string;
    update: GlobalApi.ModelUpdateClusterUserRequest;
    userId: string;
  },
  void,
  void
>({
  name: 'updateClusterUser',
  postProcess: identity,
  request: async ({ clusterId, orgId, userId, update }, options) => {
    await detApi.Global.Auth.updateClusterUser(orgId, clusterId, userId, update, options);
  },
});

/** upgrade cluster version */
export const upgradeCluster = generateDetApi<
  ClusterScope & {
    clusterId: string;
    version: string;
  },
  void,
  void
>({
  name: 'upgradeCluster',
  postProcess: identity,
  request: async ({ clusterId, orgId, version, regionId }, options) => {
    await detApi.Regional[regionId].Cluster.upgradeCluster(
      orgId,
      clusterId,
      { detVersion: version },
      options,
    );
    notifStorage.addClusterSub(
      { id: clusterId, location: regionId },
      SubscriptionType.ClusterUpgraded,
    );
  },
});

export const deleteCluster = generateDetApi<
  ClusterScope & {
    clusterId: string;
  },
  void,
  void
>({
  name: 'deleteCluster',
  postProcess: (response) => response,
  request: async ({ clusterId, orgId, regionId }, options) => {
    await detApi.Regional[regionId].Cluster.deleteCluster(orgId, clusterId, options);
    notifStorage.addClusterSub(
      { id: clusterId, location: regionId },
      SubscriptionType.ClusterDeleted,
    );
  },
});

export const pauseCluster = generateDetApi<
  ClusterScope & {
    clusterId: string;
  },
  void,
  void
>({
  name: 'pauseCluster',
  postProcess: (response) => response,
  request: async ({ clusterId, orgId, regionId }, options) => {
    await detApi.Regional[regionId].Cluster.pauseCluster(orgId, clusterId, options);
    notifStorage.addClusterSub(
      { id: clusterId, location: regionId },
      SubscriptionType.ClusterPaused,
    );
  },
});

export const resumeCluster = generateDetApi<
  ClusterScope & {
    clusterId: string;
  },
  void,
  void
>({
  name: 'resumeCluster',
  postProcess: (response) => response,
  request: async ({ clusterId, orgId, regionId }, options) => {
    await detApi.Regional[regionId].Cluster.resumeCluster(orgId, clusterId, options);
    notifStorage.addClusterSub(
      { id: clusterId, location: regionId },
      SubscriptionType.ClusterResumed,
    );
  },
});

/**
 * Get the effective list of a cluster's users.
 */
export const getClusterUsers = generateDetApi<
  {
    clusterId: string;
    orgId: string;
  },
  GlobalApi.ModelGetClusterUsersResponse,
  GlobalApi.ModelClusterUser[]
>({
  name: 'getClusterUsers',
  postProcess: (response) => response.users,
  request: ({ clusterId, orgId }, options) => {
    return detApi.Global.Auth.getClusterUsers(orgId, clusterId, options);
  },
});

/**
 * Get the effective list of a user's clusters.
 */
export const getUserClusters = generateDetApi<
  {
    orgId: string;
    userId: string;
  },
  GlobalApi.ModelGetUserClustersResponse,
  GlobalApi.ModelClusterAccessInfo[]
>({
  name: 'getUserClusters',
  postProcess: (response) => response.clusters,
  request: ({ userId, orgId }, options) => {
    return detApi.Global.Auth.getUserClusters(orgId, userId, options);
  },
});

/**
 * Get cluster access overrides for an organization.
 */
export const getClusterOverrides = generateDetApi<
  {
    orgId: string;
  },
  GlobalApi.ModelGetClusterOverridesResponse,
  Record<ClusterID, GlobalApi.ModelClusterUser[]>
>({
  name: 'getClusterOverrides',
  postProcess: (response) => response.clusters,
  request: ({ orgId }, options) => {
    return detApi.Global.Auth.getClusterOverrides(orgId, options);
  },
});

/**
 * add an override access override for user to a cluster.
 */
export const updateClusterLevelRole = generateDetApi<
  {
    clusterId: string;
    orgId: string;
    role: AccessLevel;
    userId: string;
  },
  void,
  void
>({
  name: 'addClusterLevelRole',
  postProcess: identity,
  request: async ({ orgId, clusterId, userId, role }, options) => {
    await detApi.Global.Auth.updateClusterUser(orgId, clusterId, userId, { role }, options);
  },
});

export const createCluster = generateDetApi<
  ClusterScope & {
    cluster: RegionalApi.ModelCreateClusterRequest;
  },
  RegionalApi.ModelCreateClusterResponse,
  string
>({
  name: 'createCluster',
  postProcess: (response) => response.id,
  request: async ({ cluster, orgId, regionId }, options) => {
    const c = await detApi.Regional[regionId].Cluster.createCluster(orgId, cluster, options);
    notifStorage.addClusterSub(c, SubscriptionType.ClusterCreated);
    return c;
  },
});

export const getSupportMatrix = generateDetApi<
  void,
  GlobalApi.ModelGetSupportMatrixResponse,
  GlobalApi.ModelGetSupportMatrixResponse
>({
  name: 'getSupportMatrix',
  postProcess: (response) => response,
  request: (options) => detApi.Global.SupportMatrix.getSupportMatrix(options),
});

export const getCluster = generateDetApi<
  ClusterScope & {
    clusterId: string;
  },
  RegionalApi.ModelGetClusterResponse,
  ClusterDetails
>({
  name: 'getCluster',
  postProcess: (response) => {
    const poolConfigs = response.cluster.masterConfig.resource_pools.map((pool) =>
      decodePoolConfig(pool),
    );
    return {
      ...response.cluster,
      masterConfig: {
        ...response.cluster.masterConfig,
        resource_pools: poolConfigs,
      },
    };
  },
  request: ({ clusterId, orgId, regionId }, options) =>
    detApi.Regional[regionId].Cluster.getCluster(orgId, clusterId, options),
});

/**
 * terminate and reprovision the Determined master instance with modified parameters
 */
export const reprovisionCluster = generateDetApi<
  ClusterScope & {
    clusterId: string;
    instanceType: RegionalApi.ModelReprovisionClusterRequest;
  },
  void,
  void
>({
  name: 'reprovisionCluster',
  postProcess: (response) => response,
  request: async ({ clusterId, instanceType, orgId, regionId }, options) => {
    await detApi.Regional[regionId].Cluster.reprovisionCluster(
      orgId,
      clusterId,
      instanceType,
      options,
    );
    notifStorage.addClusterSub(
      { id: clusterId, location: regionId },
      SubscriptionType.ClusterReprovisioned,
    );
  },
});

/**
 * restart the Determined master process with a new configuration
 */
export const reconfigureCluster = generateDetApi<
  ClusterScope & {
    clusterId: string;
    config: RegionalApi.ModelMasterConfig;
  },
  void,
  void
>({
  name: 'reconfigureCluster',
  postProcess: (response) => response,
  request: async ({ clusterId, config, orgId, regionId }, options) => {
    await detApi.Regional[regionId].Cluster.reconfigureCluster(orgId, clusterId, config, options);
    notifStorage.addClusterSub(
      { id: clusterId, location: regionId },
      SubscriptionType.ClusterReconfigured,
    );
  },
});

export const editCluster = generateDetApi<
  ClusterScope & {
    cluster: RegionalApi.ModelEditClusterRequest;
    clusterId: string;
  },
  void,
  void
>({
  name: 'editCluster',
  postProcess: (response) => response,
  request: async ({ clusterId, cluster, orgId, regionId }, options) => {
    await detApi.Regional[regionId].Cluster.editCluster(orgId, clusterId, cluster, options);
  },
});

/**
 * Check if the global management plane is reachable
 * @param timeout timeout in seconds
 * This api handler is NOT generated like the other api handlers.
 */
export const globalIsAlive = async (timeout: number): Promise<void> => {
  const timeoutCanceler = new AbortController();
  setTimeout(() => timeoutCanceler.abort(), timeout);
  try {
    await fetch(globalServerAddress('/health-check'), {
      method: 'GET',
      signal: timeoutCanceler.signal,
    });
  } catch (e: unknown) {
    throw new DetError(e, { publicMessage: 'Global server is not reachable', silent: true });
  }
};
