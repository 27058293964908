export * from 'shared/types';
export type PropsWithStoragePath<T> = T & { storagePath?: string };

export interface User {
  email?: string;
  name?: string;
  userID: string;
}

export enum BrandingType {
  Determined = 'determined',
  HPE = 'hpe',
}

export enum RunState {
  Active = 'ACTIVE',
  Paused = 'PAUSED',
  StoppingCanceled = 'STOPPING_CANCELED',
  Canceled = 'CANCELED',
  StoppingCompleted = 'STOPPING_COMPLETED',
  Completed = 'COMPLETED',
  StoppingError = 'STOPPING_ERROR',
  Errored = 'ERROR',
  Deleted = 'DELETED',
  Deleting = 'DELETING',
  DeleteFailed = 'DELETE_FAILED',
  Unspecified = 'UNSPECIFIED',
}

export interface DetailedUser extends User {
  email?: string;
  isActive: boolean;
  isAdmin: boolean;
  name?: string;
  userID: string;
}

export interface Auth {
  isAuthenticated: boolean;
  token?: string;
  user?: User;
}

export interface SsoProvider {
  name: string;
  ssoUrl: string;
}

export interface DeterminedInfo {
  branding: BrandingType;
  checked: boolean;
  clusterId: string;
  clusterName: string;
  externalLoginUri: string;
  externalLogoutUri: string;
  isTelemetryEnabled: boolean;
  masterId: string;
  ssoProviders?: SsoProvider[];
  version: string;
}

export interface Telemetry {
  enabled: boolean;
  segmentKey?: string;
}

export enum ResourceState { // This is almost CommandState
  Unspecified = 'UNSPECIFIED',
  Assigned = 'ASSIGNED',
  Pulling = 'PULLING',
  Starting = 'STARTING',
  Running = 'RUNNING',
  Terminated = 'TERMINATED',
  Warm = 'WARM',
}

// High level Slot state
export enum SlotState {
  Running = 'RUNNING',
  Free = 'FREE',
  Pending = 'PENDING',
  Potential = 'POTENTIAL',
}

export interface EndTimes {
  endTime?: string;
}

export interface StartEndTimes extends EndTimes {
  startTime: string;
}

export interface Pagination {
  limit: number;
  offset: number;
}

export const ALL_VALUE = 'all';

export enum LogLevel {
  Critical = 'critical',
  Debug = 'debug',
  Error = 'error',
  Info = 'info',
  None = 'none',
  Trace = 'trace',
  Warning = 'warning',
}

export enum LogLevelFromApi {
  Unspecified = 'LOG_LEVEL_UNSPECIFIED',
  Trace = 'LOG_LEVEL_TRACE',
  Debug = 'LOG_LEVEL_DEBUG',
  Info = 'LOG_LEVEL_INFO',
  Warning = 'LOG_LEVEL_WARNING',
  Error = 'LOG_LEVEL_ERROR',
  Critical = 'LOG_LEVEL_CRITICAL',
}

export interface Log {
  id: number | string;
  level?: LogLevel;
  message: string;
  meta?: string;
  time: string;
}
