import { Button } from 'antd';
import React, { useEffect } from 'react';

import PageMessage from 'components/PageMessage';
import { paths } from 'routes/utils';
import useUI from 'shared/contexts/stores/UI';
import { routeToReactUrl } from 'shared/utils/routes';

export const UnverifiedEmail: React.FC = () => {
  const {
    actions: { showChrome, hideChrome },
  } = useUI();

  useEffect(() => {
    hideChrome();
    return showChrome;
  }, [hideChrome, showChrome]);

  return (
    <PageMessage title="Unverfied Email">
      <p>
        Your identity provider indicates that your email address is not verified. Please verify your
        email and try again.
      </p>
      <Button onClick={() => routeToReactUrl(paths.logout())}>Sign out</Button>
    </PageMessage>
  );
};
