import { Typography } from 'antd';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import { ClusterDetails } from 'saasTypes';
import { getCluster } from 'services/api';
import CopyButton from 'shared/components/CopyButton';
import Message from 'shared/components/Message';
import useModal, { ModalHooks, ModalOpen } from 'shared/hooks/useModal/useModal';
import { isEqual } from 'shared/utils/data';
import { copyToClipboard } from 'shared/utils/dom';
const { Paragraph } = Typography;

import css from './useAgentRole.module.scss';

interface Props {
  onClose?: () => void;
}

interface OnOpenProps {
  clusterId: string;
  orgId: string;
  regionId: string;
}

interface ContentProps extends OnOpenProps {
  clusterDetails?: ClusterDetails;
  setClusterDetails: Dispatch<SetStateAction<ClusterDetails | undefined>>;
}

const ModalContent: React.FC<ContentProps> = ({
  orgId,
  clusterId,
  regionId,
  clusterDetails,
  setClusterDetails,
}) => {
  const fetchClusterDetails = useCallback(
    async (clusterId, orgId) => {
      if (!clusterId) return;
      const clusterDetailsRes = await getCluster({
        clusterId,
        orgId,
        regionId,
      });
      setClusterDetails(clusterDetailsRes);
    },
    [setClusterDetails, regionId],
  );

  useEffect(() => {
    fetchClusterDetails(clusterId, orgId);
  }, [clusterId, orgId, fetchClusterDetails]);

  if (!orgId) return <Message title="No organization selected" />;

  if (!clusterDetails?.agentRole) return <Message title="Loading..." />;

  const example = {
    Statement: [
      {
        Action: ['s3:GetObject', 's3:ListBucket'],
        Effect: 'Allow',
        Principal: { AWS: [clusterDetails.agentRole] },
        Resource: ['arn:aws:s3:::<BUCKET_NAME>/*', 'arn:aws:s3:::<BUCKET_NAME>'],
        Sid: 'AllowAccess',
      },
    ],
    Version: '2012-10-17',
  };

  return (
    <>
      <Paragraph>
        <span className={css.copyContainer}>
          Please grant this AWS IAM role access to your training data:
          <CopyButton onCopy={async () => await copyToClipboard(clusterDetails.agentRole)} />
        </span>
        <pre className={css.code}>{clusterDetails.agentRole}</pre>
        <span className={css.copyContainer}>
          {`If you use AWS S3 to store your training data, 
        you can use the example bucket policy below to grant access. 
        Just replace <BUCKET_NAME> with your bucket’s name. 
        If you have multiple buckets, you need to paste this example 
        and replace <BUCKET_NAME> for each bucket:`}
          <CopyButton
            onCopy={async () => await copyToClipboard(JSON.stringify(example, null, 2))}
          />
        </span>
        <pre className={css.code}>{JSON.stringify(example, null, 2)}</pre>
      </Paragraph>
    </>
  );
};

const useAgentRole = ({ onClose }: Props): ModalHooks<OnOpenProps> => {
  const { modalOpen: openOrUpdate, ...modalHooks } = useModal({
    onClose: () => {
      if (onClose) onClose();
    },
  });
  const [openProps, setOpenProps] = useState<OnOpenProps | undefined>();
  const [clusterDetails, setClusterDetails] = useState<ClusterDetails>();

  const modalOpen: ModalOpen<OnOpenProps> = useCallback(
    (newModalProps) => {
      const newOpenProps = newModalProps?.context;
      // remember the passed in open props so we can update the content if needed.
      if (!!newOpenProps && !isEqual(openProps, newOpenProps)) setOpenProps(newOpenProps);

      const curOpenProps = newOpenProps || openProps;
      if (!curOpenProps) return; // cannot open modal without open props

      openOrUpdate({
        className: css.noFooter,
        closable: true,
        content: (
          <ModalContent
            clusterDetails={clusterDetails}
            clusterId={curOpenProps.clusterId}
            orgId={curOpenProps.orgId}
            regionId={curOpenProps.regionId}
            setClusterDetails={setClusterDetails}
          />
        ),
        icon: null,
        title: 'Training Data Access',
        width: 600,
      });
    },
    [clusterDetails, openProps, openOrUpdate],
  );

  useEffect(() => {
    if (!openProps) return;
    modalOpen({ context: openProps });
  }, [modalOpen, openProps]);

  return { modalOpen, ...modalHooks };
};

export default useAgentRole;
