import { Select } from 'antd';
import React, { useEffect, useMemo, useState } from 'react';

import { SemanticVersion } from 'shared/types';
import { isEqual } from 'shared/utils/data';
import { sortVersions } from 'shared/utils/sort';
import { stringToVersion, versionToString } from 'shared/utils/string';
const { Option } = Select;

/** render a list of versions for the user to select */
const VersionPicker: React.FC<{
  curVersion?: SemanticVersion;
  onChange: (version: SemanticVersion) => void;
  versions: SemanticVersion[];
}> = ({ onChange, versions, curVersion }) => {
  const [curVal, setCurVal] = useState(
    versions.length ? versionToString(sortVersions(versions)[0]) : undefined,
  );

  useEffect(() => {
    if (curVal) onChange(stringToVersion(curVal));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [curVal]);

  const options = useMemo(() => {
    const opts = [...versions];
    if (curVersion && !versions.find((v) => isEqual(v, curVersion))) {
      opts.push(curVersion);
    }
    return sortVersions(opts).map((version, idx) => {
      const ver = versionToString(version);
      let labelSuffix = version === curVersion ? ' (current)' : '';
      labelSuffix += idx === 0 ? ' (latest)' : '';
      return (
        <Option key={ver} value={ver}>
          {ver}
          {labelSuffix}
        </Option>
      );
    });
  }, [versions, curVersion]);
  return (
    <Select value={curVal} onChange={setCurVal}>
      {options}
    </Select>
  );
};

export default VersionPicker;
