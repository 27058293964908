import { Alert, Form, FormInstance, Input } from 'antd';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';

import InstanceTypeDropdown, { InstanceUse } from 'components/InstanceTypeDropdown';
import Link from 'components/Link';
import ResourcePools, { getEmptyPoolConfig } from 'components/ResourcePools';
import { ClusterDetails, DefaultResourcePool, PoolConfig } from 'saasTypes';
import { editCluster, getCluster, reconfigureCluster, reprovisionCluster } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import { ModelAgentResourceManagerConfig } from 'services/regional-bindings';
import Message from 'shared/components/Message';
import Spinner from 'shared/components/Spinner';
import useModal, { ModalHooks, ModalOpen } from 'shared/hooks/useModal/useModal';
import { isEqual } from 'shared/utils/data';
import { ErrorLevel, ErrorType } from 'shared/utils/error';
import { generateUUID } from 'shared/utils/string';
import handleError from 'utils/error';
import { generateMasterConfig } from 'utils/saas';

import css from './useUpdateCluster.module.scss';

interface Props {
  onClose?: () => void;
}

interface OnOpenProps {
  cluster: GlobalApi.ModelClusterInfo;
  master: boolean;
  orgId: string;
}

interface ContentProps extends OnOpenProps {
  clusterName?: string;
  hideDropdown: boolean;
  masterInstanceType?: string;
  poolConfigs?: PoolConfig[];
  resourceManager: ModelAgentResourceManagerConfig;
  setClusterDetails: Dispatch<SetStateAction<ClusterDetails | undefined>>;
  setClusterName: Dispatch<SetStateAction<string>>;
  setHideDropdown: Dispatch<SetStateAction<boolean>>;
  setMasterInstanceType: Dispatch<SetStateAction<string | undefined>>;
  setPoolConfigs: Dispatch<SetStateAction<PoolConfig[] | undefined>>;
  setResourceManager: Dispatch<SetStateAction<ModelAgentResourceManagerConfig>>;
}

const ModalContent: React.FC<ContentProps> = ({
  master,
  orgId,
  cluster,
  clusterName,
  hideDropdown,
  masterInstanceType,
  poolConfigs,
  resourceManager,
  setClusterDetails,
  setClusterName,
  setPoolConfigs,
  setHideDropdown,
  setMasterInstanceType,
  setResourceManager,
}) => {
  const fetchClusterDetails = useCallback(
    async (clusterId, orgId, master) => {
      const clusterDetailsRes = await getCluster({
        clusterId,
        orgId,
        regionId: cluster.location,
      });
      if (master) {
        setMasterInstanceType(clusterDetailsRes?.masterInstanceType);
        setClusterName(clusterDetailsRes?.name);
      } else {
        const mappedPools = clusterDetailsRes.masterConfig.resource_pools
          // add a unique id for each pool so we/react can track changes.
          .map((p) => ({ ...p, key: generateUUID() }));
        setPoolConfigs(mappedPools);
        setResourceManager(clusterDetailsRes.masterConfig.resource_manager);
      }
      setClusterDetails(clusterDetailsRes);
    },
    [
      cluster.location,
      setPoolConfigs,
      setMasterInstanceType,
      setClusterName,
      setResourceManager,
      setClusterDetails,
    ],
  );

  const onShowDropdown = useCallback(() => {
    setHideDropdown(false);
  }, [setHideDropdown]);

  const onUpdateMaster = useCallback(
    (instanceType: string) => {
      setMasterInstanceType(instanceType);
      setHideDropdown(true);
    },
    [setMasterInstanceType, setHideDropdown],
  );

  const onRemovePoolConfig = useCallback(
    (key: string) => {
      if (!poolConfigs) return;
      const poolConfigsCopy = [...poolConfigs];
      const idx = poolConfigsCopy.findIndex((a) => a.key === key);
      poolConfigsCopy.splice(idx, 1);
      setPoolConfigs(poolConfigsCopy);
    },
    [poolConfigs, setPoolConfigs],
  );

  const onUpdatePoolConfig = useCallback(
    (key: string, form: FormInstance) => {
      if (!poolConfigs) return;
      const poolConfigsCopy = [...poolConfigs];
      const idx = poolConfigsCopy.findIndex((a) => a.key === key);
      poolConfigsCopy[idx] = { ...poolConfigsCopy[idx], ...form.getFieldsValue() };
      setPoolConfigs(poolConfigsCopy);
    },
    [poolConfigs, setPoolConfigs],
  );

  const onAddPoolConfig = useCallback(() => {
    setPoolConfigs((poolConfigs) => {
      if (!poolConfigs) return poolConfigs;
      return [getEmptyPoolConfig(), ...poolConfigs];
    });
  }, [setPoolConfigs]);

  const onUpdateResourceManager = useCallback(
    (keys, value) => {
      const rm = { ...resourceManager };
      keys.forEach((key: DefaultResourcePool) => {
        if (key === DefaultResourcePool.aux) {
          rm.default_aux_resource_pool = value;
        }
        if (key === DefaultResourcePool.compute) {
          rm.default_compute_resource_pool = value;
        }
      });
      setResourceManager(rm as ModelAgentResourceManagerConfig);
    },
    [setResourceManager, resourceManager],
  );

  useEffect(() => {
    fetchClusterDetails(cluster?.id, orgId, master);
  }, [cluster, orgId, master, fetchClusterDetails]);

  if (!orgId) return <Message title="No organization selected" />;
  if (master) {
    if (!masterInstanceType) return <Spinner tip="Loading cluster details" />;
    return (
      <div className={css.formWrapper}>
        <Form className={css.form}>
          <Form.Item
            label="Cluster Name"
            rules={[{ message: 'Cluster name required', required: true }]}>
            <Input
              value={clusterName}
              onChange={(e) => {
                setClusterName(e.target.value);
              }}
            />
          </Form.Item>
          <div>
            {/* wrap Alert with div to add margin */}
            <Alert
              message="Changing the master instance type may cause interruptions in running workloads. Consider scheduling this activity at a time when it is least disruptive."
              type="warning"
            />
          </div>
          <Form.Item
            className={css.formItemWithLink}
            label={
              <>
                <span>Master Instance Type</span>
                <Link
                  path="https://aws.amazon.com/ec2/instance-types/#General_Purpose"
                  popout={true}>
                  List of Instance Types
                </Link>
              </>
            }
            labelCol={{ span: 24 }}
            name="masterInstanceType">
            <InstanceTypeDropdown
              buttonLabel={masterInstanceType}
              clusterRegion={cluster.location}
              disableAutoDismiss={true}
              hide={hideDropdown}
              showArrow={false}
              use={InstanceUse.Master}
              onSelect={onUpdateMaster}
              onVisibleChange={onShowDropdown}
            />
          </Form.Item>
        </Form>
      </div>
    );
  } else {
    if (!poolConfigs) return <Spinner tip="Loading cluster details" />;
    return (
      <div className={css.modalContent}>
        <ResourcePools
          clusterRegion={cluster.location}
          pools={poolConfigs}
          resourceManager={resourceManager}
          onAddPoolConfig={onAddPoolConfig}
          onRemovePoolConfig={onRemovePoolConfig}
          onUpdatePoolConfig={onUpdatePoolConfig}
          onUpdateResourceManager={onUpdateResourceManager}
        />
      </div>
    );
  }
};

const useUpdateCluster = ({ onClose }: Props): ModalHooks<OnOpenProps> => {
  const { modalOpen: openOrUpdate, ...modalHooks } = useModal({
    onClose: () => {
      // canceler.abort();
      if (onClose) onClose();
    },
  });
  const [canceler] = useState(new AbortController());
  const [openProps, setOpenProps] = useState<OnOpenProps | undefined>();
  const [hideDropdown, setHideDropdown] = useState(true);
  const [poolConfigs, setPoolConfigs] = useState<PoolConfig[]>();
  const [masterInstanceType, setMasterInstanceType] = useState<string>();
  const [clusterDetails, setClusterDetails] = useState<ClusterDetails>();
  const [resourceManager, setResourceManager] = useState<ModelAgentResourceManagerConfig>({
    default_aux_resource_pool: '',
    default_compute_resource_pool: '',
  });
  const [clusterName, setClusterName] = useState<string>('');

  const hasMasterInstanceTypeUpdate = (
    masterInstanceType?: string,
    clusterDetails?: ClusterDetails,
  ) => {
    return masterInstanceType !== clusterDetails?.masterInstanceType;
  };

  const hasNameUpdate = (clusterName?: string, clusterDetails?: ClusterDetails) => {
    return clusterName?.length && clusterName !== clusterDetails?.name;
  };

  const reprovisionClusterRequest = useCallback(
    async (orgId, cluster) => {
      if (!orgId || !cluster) return;
      const promises = [];
      if (masterInstanceType && hasMasterInstanceTypeUpdate(masterInstanceType, clusterDetails)) {
        promises.push(
          reprovisionCluster(
            {
              clusterId: cluster.id,
              instanceType: { masterInstanceType: masterInstanceType },
              orgId,
              regionId: cluster.location,
            },
            { signal: canceler.signal },
          ).catch((error) => {
            handleError(error, {
              level: ErrorLevel.Error,
              publicSubject: 'Failed to update Master Instance Type',
              silent: false,
              type: ErrorType.Server,
            });
          }),
        );
      }
      if (hasNameUpdate(clusterName, clusterDetails)) {
        promises.push(
          editCluster(
            {
              cluster: { name: clusterName },
              clusterId: cluster.id,
              orgId,
              regionId: cluster.location,
            },
            { signal: canceler.signal },
          ).catch((error) => {
            handleError(error, {
              level: ErrorLevel.Error,
              publicSubject: 'Failed to update Cluster Name',
              silent: false,
              type: ErrorType.Server,
            });
          }),
        );
      }
      try {
        await Promise.allSettled(promises);
      } catch (error) {
        throw handleError(error, {
          level: ErrorLevel.Error,
          publicSubject: 'Failed to update',
          silent: false,
          type: ErrorType.Server,
        });
      }
    },
    [canceler.signal, clusterName, clusterDetails, masterInstanceType],
  );

  const reconfigureClusterRequest = useCallback(
    async (orgId, cluster) => {
      if (!orgId || !cluster || !poolConfigs) return;
      // call `generateMasterConfig` outside of try/catch block
      // to avoid calling `handleError` if `generateMasterConfig` throws a data validation error:
      const masterConfig = generateMasterConfig(poolConfigs, resourceManager, true);
      try {
        await reconfigureCluster(
          {
            clusterId: cluster.id,
            config: masterConfig,
            orgId,
            regionId: cluster.location,
          },
          { signal: canceler.signal },
        );
      } catch (error) {
        throw handleError(error, {
          level: ErrorLevel.Error,
          publicSubject: 'Failed to update',
          silent: false,
        });
      }
    },
    [canceler.signal, poolConfigs, resourceManager],
  );

  const onOk = useCallback(
    async ({ master, orgId, cluster }: OnOpenProps) => {
      // onOk uses async/await so that if `generateMasterConfig` throws a data validation error,
      // it can return a rejected promise, which prevents modal from closing
      if (master) {
        await reprovisionClusterRequest(orgId, cluster);
      } else {
        await reconfigureClusterRequest(orgId, cluster);
      }
    },
    [reconfigureClusterRequest, reprovisionClusterRequest],
  );

  const clusterHasUpdates = useCallback(
    (master: boolean) => {
      if (master) {
        return (
          hasMasterInstanceTypeUpdate(masterInstanceType, clusterDetails) ||
          hasNameUpdate(clusterName, clusterDetails)
        );
      }
      if (!poolConfigs || poolConfigs.length === 0) return false;
      return (
        !isEqual(
          poolConfigs.map((p) => ({ ...p, key: '' })),
          clusterDetails?.masterConfig.resource_pools,
        ) || !isEqual(resourceManager, clusterDetails?.masterConfig.resource_manager)
      );
    },
    [clusterDetails, masterInstanceType, clusterName, poolConfigs, resourceManager],
  );

  const modalOpen: ModalOpen<OnOpenProps> = useCallback(
    (newModalProps) => {
      const newOpenProps = newModalProps?.context;
      // remember the passed in open props so we can update the content if needed.
      if (!!newOpenProps && !isEqual(openProps, newOpenProps)) setOpenProps(newOpenProps);

      const curOpenProps = newOpenProps || openProps;
      if (!curOpenProps) return; // cannot open modal without open props

      openOrUpdate({
        content: (
          <ModalContent
            cluster={curOpenProps.cluster}
            clusterName={clusterName}
            hideDropdown={hideDropdown}
            master={curOpenProps.master}
            masterInstanceType={masterInstanceType}
            orgId={curOpenProps.orgId}
            poolConfigs={poolConfigs}
            resourceManager={resourceManager}
            setClusterDetails={setClusterDetails}
            setClusterName={setClusterName}
            setHideDropdown={setHideDropdown}
            setMasterInstanceType={setMasterInstanceType}
            setPoolConfigs={setPoolConfigs}
            setResourceManager={setResourceManager}
          />
        ),
        icon: null,
        okButtonProps: { disabled: !clusterHasUpdates(curOpenProps.master) },
        okText: `Update ${curOpenProps.master ? 'Master' : 'Resource Pools'}`,
        onOk: () => onOk(curOpenProps),
        title: `Update ${curOpenProps.cluster?.name}
      ${curOpenProps.master ? 'Master' : 'Resource Pools'}`,
        width: 600,
      });
    },
    [
      clusterName,
      hideDropdown,
      masterInstanceType,
      clusterHasUpdates,
      poolConfigs,
      onOk,
      openProps,
      openOrUpdate,
      resourceManager,
    ],
  );

  useEffect(() => {
    if (!openProps) return;
    modalOpen({ context: openProps });
  }, [modalOpen, openProps]);

  return { modalOpen, ...modalHooks };
};

export default useUpdateCluster;
