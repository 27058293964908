import { ColumnType } from 'antd/es/table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AccessLevelAdd from 'components/AccessLevel/AccessLevelAdd';
import AccessLevelDelete from 'components/AccessLevel/AccessLevelDelete';
import AccessLevelSelect from 'components/AccessLevel/AccessLevelSelect';
import ResponsiveTable from 'components/ResponsiveTable';
import { useStore } from 'contexts/Store';
import { useFetchClusters } from 'experimental/notifications/hooks';
import { useFetchSupportMatrix } from 'hooks/useFetch';
import { AccessLevel } from 'saasTypes';
import { getUserClusters, updateClusterLevelRole } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import useModal, { ModalHooks, ModalOpen } from 'shared/hooks/useModal/useModal';
import { isEqual } from 'shared/utils/data';
import { alphaNumericSorter, semVerIsOlder } from 'shared/utils/sort';
import { stringToVersion } from 'shared/utils/string';
interface OnOpenProps {
  user: GlobalApi.ModelOrgUser;
}

const ModalContent: React.FC<OnOpenProps> = ({ user }) => {
  const [orgClusters, setOrgClusters] = useState<GlobalApi.ModelClusterInfo[]>([]);
  const [userClusters, setUserClusters] = useState<GlobalApi.ModelClusterAccessInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [canceler] = useState(new AbortController());
  const {
    orgState: { selectedOrg },
    supportMatrix,
  } = useStore();
  const fetchSupportMatrix = useFetchSupportMatrix(canceler);
  const fetchClusters = useFetchClusters(canceler);

  const loadOrgClusters = useCallback(async () => {
    const clusters = await fetchClusters();
    if (!clusters) return;
    setOrgClusters(clusters);
  }, [fetchClusters]);

  const loadUserClusters = useCallback(async () => {
    if (!selectedOrg) return;
    const clusters = await getUserClusters(
      {
        orgId: selectedOrg.id,
        userId: user.id,
      },
      { signal: canceler.signal },
    );
    if (!clusters) return;
    setUserClusters(clusters);
  }, [selectedOrg, user, canceler]);

  const fetchData = useCallback(async () => {
    if (!selectedOrg || !supportMatrix) return;
    await loadOrgClusters();
    await loadUserClusters();
    setLoading(false);
  }, [loadOrgClusters, loadUserClusters, selectedOrg, supportMatrix]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    if (!supportMatrix) fetchSupportMatrix();
  }, [supportMatrix, fetchSupportMatrix]);

  /**
   * Update the user's access level to a cluster
   */
  const updateUserClusterAcl = useCallback(
    async (clusterId: string, newAccess: AccessLevel) => {
      if (!selectedOrg) return;
      await updateClusterLevelRole(
        {
          clusterId,
          orgId: selectedOrg.id,
          role: newAccess,
          userId: user.id,
        },
        { signal: canceler.signal },
      );
      fetchData();
    },
    [selectedOrg, user, canceler, fetchData],
  );

  const columns = useMemo(() => {
    const cols: ColumnType<GlobalApi.ModelClusterAccessInfo>[] = [
      {
        dataIndex: 'id',
        defaultSortOrder: 'ascend',
        key: 'id',
        sorter: (a, b) => alphaNumericSorter(a.id, b.id),
        title: 'ID',
        width: 300,
      },
      {
        dataIndex: 'name',
        key: 'name',
        sorter: (a, b) => alphaNumericSorter(a.name, b.name),
        title: 'Name',
      },
      {
        dataIndex: 'location',
        key: 'location',
        sorter: (a, b) => alphaNumericSorter(a.location, b.location),
        title: 'Location',
      },
      {
        dataIndex: 'detVersion',
        key: 'detVersion',
        sorter: (a, b) =>
          semVerIsOlder(stringToVersion(a.detVersion), stringToVersion(b.detVersion)) ? -1 : 1,
        title: 'Version',
      },
      {
        dataIndex: 'role',
        key: 'role',
        render: (_, record) => {
          return (
            <AccessLevelSelect
              availableLevels={[AccessLevel.Admin, AccessLevel.User]}
              defaultValue={record.role as AccessLevel}
              onChange={(level: AccessLevel) => updateUserClusterAcl(record.id, level)}
            />
          );
        },
        sorter: (a, b) => alphaNumericSorter(a.role, b.role),
        title: 'Cluster Role',
      },
      {
        dataIndex: 'delete',
        key: 'delete',
        render: (_, record) => {
          return <AccessLevelDelete id={record.id} onChange={updateUserClusterAcl} />;
        },
        title: '',
      },
    ];
    return cols;
  }, [updateUserClusterAcl]);

  const excludedClusters = useMemo(() => {
    return orgClusters.filter((oc) => !userClusters.some((uc) => uc.id === oc.id));
  }, [orgClusters, userClusters]);

  return (
    <div>
      <AccessLevelAdd
        optionIdField="id"
        optionLabelField="name"
        options={excludedClusters}
        optionType="cluster"
        onUpdate={updateUserClusterAcl}
      />
      <ResponsiveTable<GlobalApi.ModelClusterAccessInfo>
        columns={columns}
        dataSource={userClusters}
        loading={loading}
        pagination={{ hideOnSinglePage: true }}
        rowKey="cluster"
        showSorterTooltip={false}
      />
    </div>
  );
};

interface Props {
  onClose?: () => void;
}

/**
 * modal for setting cluster access levels for a user
 */
const useUserClusters = ({ onClose }: Props): ModalHooks<OnOpenProps> => {
  const { modalOpen: openOrUpdate, ...modalHooks } = useModal<OnOpenProps>({ onClose });
  const [openProps, setOpenProps] = useState<OnOpenProps | undefined>();

  const modalOpen: ModalOpen<OnOpenProps> = useCallback(
    (initialModalProps = {}) => {
      const newOpenProps = initialModalProps.context;
      if (!!newOpenProps && !isEqual(openProps, newOpenProps)) setOpenProps(newOpenProps);

      const curOpenProps = newOpenProps || openProps;
      if (!curOpenProps) return; // cannot open modal without open props

      const modalProps = {
        cancelButtonProps: { hidden: true },
        content: <ModalContent user={curOpenProps.user} />,
        icon: null,
        okText: 'Ok',
        title: `Manage Cluster Access for ${curOpenProps.user.name}`,
        width: '50vw',
      };

      const combinedProps = { ...modalProps, ...initialModalProps };
      openOrUpdate(combinedProps);
    },
    [openOrUpdate, openProps],
  );

  useEffect(() => {
    if (!openProps) return;
    modalOpen({ context: openProps });
  }, [modalOpen, openProps]);

  return { modalOpen, ...modalHooks };
};

export default useUserClusters;
