import { useCallback } from 'react';

import { StoreAction, useStore, useStoreDispatch } from 'contexts/Store';
import { fetchOrgs, getSupportMatrix } from 'services/api';
import { ModelGetSupportMatrixResponse, ModelListOrgsEntry } from 'services/global-bindings';
import { isEqual } from 'shared/utils/data';
import handleError from 'utils/error';
import { decodeSaasJwt } from 'utils/saas';

import useAuthCheck from './useAuthCheck';

export const useFetchOrgs = (
  canceler: AbortController,
): (() => Promise<ModelListOrgsEntry[] | void>) => {
  const storeDispatch = useStoreDispatch();
  const {
    auth: { token, isAuthenticated }, // TODO(WEB-595): handleError to be aware of public routes.
  } = useStore();
  const checkAuth = useAuthCheck(canceler);

  return useCallback(async () => {
    if (!isAuthenticated) return;
    try {
      const orgs = await fetchOrgs(undefined, { signal: canceler.signal });
      if (token) {
        const { roles } = decodeSaasJwt(token);
        const orgRolesFromJwt = new Set(Object.keys(roles));
        const orgsFromApi = new Set(orgs.map((o) => o.id));
        if (!isEqual(orgRolesFromJwt, orgsFromApi)) {
          await checkAuth();
        }
      }
      storeDispatch({ type: StoreAction.SetOrgs, value: orgs });
      return orgs;
    } catch (e) {
      handleError(e);
    }
  }, [canceler, storeDispatch, token, checkAuth, isAuthenticated]);
};

export const useFetchSupportMatrix = (
  canceler: AbortController,
): (() => Promise<ModelGetSupportMatrixResponse | void>) => {
  const storeDispatch = useStoreDispatch();

  return useCallback(async () => {
    try {
      const supportMatrix = await getSupportMatrix(undefined, { signal: canceler.signal });
      storeDispatch({ type: StoreAction.SetSupportMatrix, value: supportMatrix });
      return supportMatrix;
    } catch (e) {
      handleError(e);
    }
  }, [canceler, storeDispatch]);
};
