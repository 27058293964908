import { Menu, Tooltip } from 'antd';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { useStore } from 'contexts/Store';
import useSettings, { BaseType, SettingsConfig } from 'hooks/useSettings';
import { paths } from 'routes/utils';
import AvatarCard from 'shared/components/AvatarCard';
import Icon from 'shared/components/Icon';
import useUI from 'shared/contexts/stores/UI';
import { getDisplayName } from 'utils/user';

import Dropdown, { Placement } from './Dropdown';
import Link, { Props as LinkProps } from './Link';
import css from './NavigationSideBar.module.scss';
import ThemeToggle from './ThemeToggle';

interface ItemProps extends LinkProps {
  badge?: number;
  icon: string;
  label: string;
  status?: string;
  tooltip?: boolean;
}

interface Settings {
  navbarCollapsed: boolean;
}

const settingsConfig: SettingsConfig = {
  settings: [
    {
      defaultValue: false,
      key: 'navbarCollapsed',
      skipUrlEncoding: true,
      storageKey: 'navbarCollapsed',
      type: { baseType: BaseType.Boolean },
    },
  ],
  storagePath: 'navigation',
};

const menuConfig = {
  bottom: [
    // docs are only available on production builds and are prepared outside of React.
    { external: true, icon: 'docs', label: 'Docs', path: paths.docs(), popout: true },
  ],
  top: [
    { icon: 'cluster', label: 'Clusters', path: paths.clusters() },
    { icon: 'user', label: 'Members', path: paths.members() },
  ],
};
if (process.env.IS_DEV) {
  menuConfig.top.splice(1, 0, { icon: 'cluster', label: 'Cluster Cards (beta)', path: '/cards' });
}

const NavigationItem: React.FC<ItemProps> = ({ path, status, ...props }: ItemProps) => {
  const location = useLocation();
  const [isActive, setIsActive] = useState(false);
  const classes = [css.navItem];

  if (isActive) classes.push(css.active);
  if (status) classes.push(css.hasStatus);

  useEffect(() => setIsActive(location.pathname === path), [location.pathname, path]);

  const link = (
    <Link className={classes.join(' ')} disabled={isActive} path={path} {...props}>
      <Icon name={props.icon} size="large" />
      <div className={css.label}>{props.label}</div>
      {status && <div className={css.status}>{status}</div>}
    </Link>
  );

  return props.tooltip ? (
    <Tooltip placement="right" title={props.label}>
      <div>{link}</div>
    </Tooltip>
  ) : (
    link
  );
};

const NavigationSideBar: React.FC = () => {
  // `nodeRef` padding is required for CSSTransition to work with React.StrictMode.
  const nodeRef = useRef(null);
  const { auth } = useStore();
  const { ui } = useUI();
  const { settings, updateSettings } = useSettings<Settings>(settingsConfig);

  const showNavigation = auth.isAuthenticated && ui.showChrome;
  const version = process.env.BUILD_HASH || '';
  const shortVersion = version.replace(/-.*$/i, ''); // remove the -dirty suffix
  const isVersionLong = version !== shortVersion;

  const handleCollapse = useCallback(() => {
    updateSettings({ navbarCollapsed: !settings.navbarCollapsed });
  }, [settings.navbarCollapsed, updateSettings]);

  if (!showNavigation) return null;

  return (
    <CSSTransition
      appear={true}
      classNames={{
        appear: css.collapsedAppear,
        appearActive: settings.navbarCollapsed ? css.collapsedEnterActive : css.collapsedExitActive,
        appearDone: settings.navbarCollapsed ? css.collapsedEnterDone : css.collapsedExitDone,
        enter: css.collapsedEnter,
        enterActive: css.collapsedEnterActive,
        enterDone: css.collapsedEnterDone,
        exit: css.collapsedExit,
        exitActive: css.collapsedExitActive,
        exitDone: css.collapsedExitDone,
      }}
      in={settings.navbarCollapsed}
      nodeRef={nodeRef}
      timeout={200}>
      <nav className={css.base} ref={nodeRef}>
        <header>
          <Dropdown
            content={
              <Menu
                items={[
                  { key: 'theme-toggle', label: <ThemeToggle /> },
                  {
                    key: 'sign-out',
                    label: <Link path={paths.logout()}>Sign Out</Link>,
                  },
                ]}
              />
            }
            offset={settings.navbarCollapsed ? { x: -8, y: 16 } : { x: 16, y: -8 }}
            placement={settings.navbarCollapsed ? Placement.RightTop : Placement.BottomLeft}>
            <AvatarCard
              className={css.user}
              darkLight={ui.darkLight}
              displayName={getDisplayName(auth.user)}
              noColor
            />
          </Dropdown>
        </header>
        <main>
          <section className={css.top}>
            {menuConfig.top.map((config) => (
              <NavigationItem
                key={config.label + config.path}
                tooltip={settings.navbarCollapsed}
                {...config}
              />
            ))}
          </section>
          <section className={css.bottom}>
            {menuConfig.bottom.map((config) => (
              <NavigationItem
                key={config.label + config.path}
                tooltip={settings.navbarCollapsed}
                {...config}
              />
            ))}
            <NavigationItem
              icon={settings.navbarCollapsed ? 'expand' : 'collapse'}
              label={settings.navbarCollapsed ? 'Expand' : 'Collapse'}
              tooltip={settings.navbarCollapsed}
              onClick={handleCollapse}
            />
          </section>
        </main>
        <footer>
          <div className={css.version}>
            {isVersionLong && settings.navbarCollapsed ? (
              <Tooltip placement="right" title={`Version ${version}`}>
                <span className={css.versionLabel}>{shortVersion}</span>
              </Tooltip>
            ) : (
              <span className={css.versionLabel}>{version}</span>
            )}
          </div>
        </footer>
      </nav>
    </CSSTransition>
  );
};

export default NavigationSideBar;
