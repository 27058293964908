import React, { ReactElement } from 'react';

import Link from 'components/Link';
import { AccessLevel } from 'saasTypes';

import css from './AccessLevelDelete.module.scss';

interface Props {
  id: string;
  onChange: (id: string, level: AccessLevel) => void;
}

const AccessLevelDelete = ({ id, onChange }: Props): ReactElement => {
  return (
    <span className={css.action}>
      <Link onClick={() => onChange(id, AccessLevel.None)}>X</Link>
    </span>
  );
};

export default AccessLevelDelete;
