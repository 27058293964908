import { ColumnType } from 'antd/es/table';

import * as GlobalApi from 'services/global-bindings';
import { alphaNumericSorter } from 'shared/utils/sort';

export const columns: ColumnType<GlobalApi.ModelOrgUser>[] = [
  {
    dataIndex: 'name',
    defaultSortOrder: 'ascend',
    key: 'name',
    sorter: (a, b) => alphaNumericSorter(a.name, b.name),
    title: 'Name',
  },
  {
    dataIndex: 'email',
    defaultSortOrder: 'ascend',
    key: 'email',
    sorter: (a, b) => alphaNumericSorter(a.email, b.email),
    title: 'Email',
  },
  {
    dataIndex: 'role',
    key: 'role',
    sorter: (a, b) => alphaNumericSorter(a.role, b.role),
    title: 'Organization Role',
  },
  { dataIndex: 'defaultClusterRole', key: 'defaultClusterRole', title: 'Default Cluster Role' },
  // { key: 'permissions', title: 'Permissions' },
  // {
  //   key: 'lastActive',
  //   render: relativeTimeRenderer,
  //   title: 'Last Active',
  // },
  {
    align: 'right',
    className: 'fullCell',
    fixed: 'right',
    key: 'actions',
    title: '',
    width: 40,
  },
];
