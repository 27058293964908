import { globalStorage } from 'globalStorage';
import { globalServerAddress, regionalServerAddress } from 'routes/utils';
import * as GlobalApi from 'services/global-bindings';
import * as RegionalApi from 'services/regional-bindings';

import { RegionID } from './types';

/**
 * available regional apis.
 */
const regions: RegionID[] = ['aws-us-west-2'];

interface DetRegionApi {
  Cluster: RegionalApi.ClustersApi;
}

const AUTH_COOKIE_KEY = 'auth';

const clearAuthCookie = (key = AUTH_COOKIE_KEY): void => {
  document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

const genApiKey = (token: string | undefined): string | undefined => {
  if (!token) return undefined;
  return `Bearer ${token}`;
};

export const updateBearerToken = (token: string): void => {
  if (token === '') throw new Error('bad token');
  globalStorage.authToken = token;
  updateDetApi({ apiKey: genApiKey(token) });
};

export const removeBearerToken = (): void => {
  clearAuthCookie('auth-session');
  globalStorage.removeAuthToken();
  updateDetApi({ apiKey: undefined });
};

/**
 * @param regions list of regions to set up api configs for.
 * @returns api configs for given regions.
 */
const regionalApis = (regions: Set<RegionID> | RegionID[]) => {
  const regionApi = (regionId: RegionID): DetRegionApi => {
    return {
      Cluster: new RegionalApi.ClustersApi(
        new RegionalApi.Configuration({
          apiKey: genApiKey(globalStorage.authToken),
          basePath: regionalServerAddress(regionId),
        }),
      ),
    };
  };
  return Array.from(regions).reduce((acc, cur) => {
    acc[cur] = regionApi(cur);
    return acc;
  }, {} as Record<RegionID, DetRegionApi>);
};

const globalApi = (apiConfig?: GlobalApi.ConfigurationParameters) => {
  const globalApiConfig = new GlobalApi.Configuration({
    apiKey: genApiKey(globalStorage.authToken),
    basePath: globalServerAddress(),
    ...apiConfig,
  });
  return {
    Auth: new GlobalApi.AuthzApi(globalApiConfig),
    Cluster: new GlobalApi.ClustersApi(globalApiConfig),
    Orgs: new GlobalApi.OrgsApi(globalApiConfig),
    SupportMatrix: new GlobalApi.SupportMatrixApi(globalApiConfig),
  };
};

export const detApi = {
  Global: globalApi(),
  Regional: regionalApis(regions),
};

// Update references to generated API code with new configuration.
export const updateDetApi = (apiConfig?: GlobalApi.ConfigurationParameters): void => {
  detApi.Global = globalApi(apiConfig);
  detApi.Regional = regionalApis(regions);
};
