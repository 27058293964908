import React from 'react';

import Spinner from 'shared/components/Spinner/Spinner';
import useUI from 'shared/contexts/stores/UI';

import css from './Navigation.module.scss';
import NavigationHeader from './NavigationHeader';
import NavigationSideBar from './NavigationSideBar';
import NavigationTabbar from './NavigationTabbar';

interface Props {
  children?: React.ReactNode;
}

const Navigation: React.FC<Props> = ({ children }) => {
  const { ui } = useUI();

  return (
    <Spinner spinning={ui.showSpinner}>
      <NavigationHeader />
      <div className={css.base}>
        <NavigationSideBar />
        {children}
        <NavigationTabbar />
      </div>
    </Spinner>
  );
};

export default Navigation;
