import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Form } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import VersionPicker from 'components/VersionPicker';
import { upgradeCluster } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import Spinner from 'shared/components/Spinner';
import useModal, { ModalHooks, ModalOpen } from 'shared/hooks/useModal/useModal';
import { SemanticVersion } from 'shared/types';
import { isEqual } from 'shared/utils/data';
import { ErrorLevel } from 'shared/utils/error';
import { stringToVersion, versionToString } from 'shared/utils/string';
import handleError from 'utils/error';
import { suitableVersions } from 'utils/saas';

interface Props {
  onClose?: () => void;
}

interface OnOpenProps {
  cluster: GlobalApi.ModelClusterInfo;
  orgId: string;
  supportedDetVersions: string[];
}

interface FormValues {
  desiredVersion?: SemanticVersion;
}

interface ContentProps extends OnOpenProps {
  setInputs: (value: React.SetStateAction<FormValues>) => void;
}

const ModalContent: React.FC<ContentProps> = ({ setInputs, cluster, supportedDetVersions }) => {
  const validUpgrades = useMemo(() => {
    return suitableVersions(supportedDetVersions, cluster.detVersion);
  }, [cluster.detVersion, supportedDetVersions]);

  if (validUpgrades.length === 0) return <Spinner tip="No upgrades are available." />;

  return (
    <div>
      <Form<FormValues>>
        <Form.Item label="Desired version" name="desiredVersion">
          <VersionPicker
            curVersion={stringToVersion(cluster.detVersion)}
            versions={validUpgrades}
            onChange={(ver) => setInputs((cur) => ({ ...cur, desiredVersion: ver }))}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

const useModalUpgradeCluster = ({ onClose }: Props): ModalHooks<OnOpenProps> => {
  const { modalOpen: openOrUpdate, ...modalHooks } = useModal<OnOpenProps>({ onClose });
  const [canceler] = useState(new AbortController());
  const [inputs, setInputs] = useState<FormValues>({ desiredVersion: undefined });
  const [openProps, setOpenProps] = useState<OnOpenProps | undefined>();

  const onOk = useCallback(
    async ({ orgId, cluster }: OnOpenProps) => {
      if (!inputs || !inputs.desiredVersion) return;
      try {
        await upgradeCluster(
          {
            clusterId: cluster.id,
            orgId,
            regionId: cluster.location,
            version: versionToString(inputs.desiredVersion),
          },
          { signal: canceler.signal },
        );
      } catch (error) {
        throw handleError(error, {
          level: ErrorLevel.Error,
          publicSubject: 'Failed to request upgrade',
          silent: false,
        });
      }
    },
    [canceler.signal, inputs],
  );

  const modalOpen: ModalOpen<OnOpenProps> = useCallback(
    (newModalProps) => {
      const newOpenProps = newModalProps?.context;
      // remember the passed in open props so we can update the content if needed.
      if (!!newOpenProps && !isEqual(openProps, newOpenProps)) setOpenProps(newOpenProps);

      const curOpenProps = newOpenProps || openProps;
      if (!curOpenProps) return; // cannot open modal without open props

      const btnDisabled =
        inputs.desiredVersion === undefined ||
        versionToString(inputs.desiredVersion) === curOpenProps.cluster.detVersion;
      openOrUpdate({
        content: (
          <ModalContent
            cluster={curOpenProps.cluster}
            orgId={curOpenProps.orgId}
            setInputs={setInputs}
            supportedDetVersions={curOpenProps.supportedDetVersions}
          />
        ),
        icon: <ExclamationCircleOutlined />,
        okButtonProps: { disabled: btnDisabled },
        onOk: () => onOk(curOpenProps),
        title: `Upgrade version for ${curOpenProps.cluster.name}`,
        ...newModalProps,
      });
    },
    [openOrUpdate, inputs, onOk, openProps, setOpenProps],
  );

  useEffect(() => {
    if (!openProps) return;
    modalOpen({ context: openProps });
  }, [modalOpen, openProps]);

  return { modalOpen, ...modalHooks };
};

export default useModalUpgradeCluster;
