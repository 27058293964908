import React, { useCallback, useEffect, useState } from 'react';

import { StoreAction, useStore, useStoreDispatch } from 'contexts/Store';
import { paths } from 'routes/utils';
import { isAuthFailure, refreshToken } from 'services/api';
import { removeBearerToken } from 'services/apiConfig';
import Spinner from 'shared/components/Spinner';
import rootLogger from 'shared/utils/Logger';
import { routeToExternalUrl, routeToReactUrl } from 'shared/utils/routes';
import handleError, { ErrorLevel, ErrorType } from 'utils/error';

const logger = rootLogger.extend('pages', 'SignOut');

const SignOut: React.FC = () => {
  const { info, auth } = useStore();
  const storeDispatch = useStoreDispatch();
  const [isSigningOut, setIsSigningOut] = useState(false);
  const [canceler] = useState(new AbortController());

  const signOut = useCallback(async () => {
    setIsSigningOut(true);
    if (auth.checked && !auth.isAuthenticated) {
      logger.trace('auth checked and user is not authenticated.');
      removeBearerToken();
      routeToReactUrl(paths.login());
      return;
    }
    try {
      await refreshToken(undefined, { signal: canceler.signal });
      logger.trace('user is authenticated.');
      storeDispatch({ type: StoreAction.SetUnauthenticated });
      routeToExternalUrl(info.externalLogoutUri);
    } catch (e) {
      if (isAuthFailure(e)) {
        storeDispatch({ type: StoreAction.SetUnauthenticated });
        logger.trace('auth checked and user is not authenticated.');
        return;
      }
      handleError(e, {
        level: ErrorLevel.Warn,
        silent: false,
        type: ErrorType.Server,
      });
    }
  }, [info.externalLogoutUri, auth.checked, storeDispatch, canceler.signal, auth.isAuthenticated]);

  useEffect(() => {
    if (!isSigningOut) signOut();
  }, [signOut, isSigningOut]);

  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  return <Spinner center />;
};

export default SignOut;
