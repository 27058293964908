import { useCallback } from 'react';

import { StoreAction, useStore, useStoreDispatch } from 'contexts/Store';
import { globalStorage } from 'globalStorage';
import { paths } from 'routes/utils';
import { isAuthFailure, refreshToken } from 'services/api';
import { updateBearerToken } from 'services/apiConfig';
import rootLogger from 'shared/utils/Logger';
import { routeToReactUrl } from 'shared/utils/routes';
import { isAborted } from 'shared/utils/service';
import handleError, { ErrorType } from 'utils/error';

const logger = rootLogger.extend('hooks/useAuthCheck');

const useAuthCheck = (canceler: AbortController): (() => Promise<void>) => {
  const { info } = useStore();
  const storeDispatch = useStoreDispatch();

  const checkAuth = useCallback(async (): Promise<void> => {
    /*
     * Check for the auth token from the following sources:
     *   1 - jwt token from external authentication.
     * We assume any hash on the page to be the token.
     *   2 - server cookie
     *   3 - local storage
     */
    let jwtToken: null | string = null;
    if (window.location.hash.length) {
      jwtToken = window.location.hash.substring(1);
      logger.debug('Received jwt token in page hash');
      window.location.hash = '';
    }
    const authToken = jwtToken ?? globalStorage.authToken;
    if (!authToken) {
      storeDispatch({ type: StoreAction.SetAuthCheck });
      return;
    } else {
      logger.trace('found a cached auth token');
    }
    /*
     * If auth token found, update the API bearer token and validate it with the current user API.
     * If an external login URL is provided, redirect there.
     * Otherwise mark that we checked the auth and skip auth token validation.
     */
    updateBearerToken(authToken);

    try {
      const { token: newToken } = await refreshToken(undefined, { signal: canceler.signal });
      storeDispatch({
        type: StoreAction.SetAuth,
        value: { isAuthenticated: true, token: newToken },
      });
      logger.trace('verified auth token validity');
    } catch (e) {
      logger.trace('refreshToken call failed');
      if (isAborted(e)) return;
      logger.trace('failed to refresh auth token');

      if (isAuthFailure(e, !!info.externalLoginUri)) {
        storeDispatch({ type: StoreAction.SetUnauthenticated });
        routeToReactUrl(paths.login());
      } else {
        throw handleError(e, {
          isUserTriggered: false,
          publicMessage: 'Unable to verify current user.',
          publicSubject: 'GET user failed',
          silent: true,
          type: ErrorType.Server,
        });
      }
    }
  }, [canceler, info.externalLoginUri, storeDispatch]);

  return checkAuth;
};

export default useAuthCheck;
