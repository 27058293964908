import { LoadingOutlined } from '@ant-design/icons';
import { Card, ConfigProvider, List, Spin } from 'antd';
import React, { useCallback } from 'react';

import Link from 'components/Link';
import { useStore } from 'contexts/Store';
import { handlePath } from 'routes/utils';
import { ClusterState, transitionalClusterState } from 'saasTypes';
import * as GlobalApi from 'services/global-bindings';
import Icon from 'shared/components/Icon';
import { AnyMouseEvent } from 'shared/utils/routes';
import { capitalizeWord } from 'shared/utils/string';
import { disableClusterLinkByState } from 'utils/saas';

import css from './ClusterCard.module.scss';

const { Meta } = Card;

const getAvatarByState = (state: string) => {
  if (transitionalClusterState.has(state as ClusterState)) {
    return <Spin indicator={<LoadingOutlined className={css.spinner} spin />} />;
  } else if (state === ClusterState.Running) {
    return <Icon name="cluster" size="huge" />;
  } else if (state === ClusterState.Paused) {
    return <Icon name="pause" size="huge" />;
  } else if (state === ClusterState.Deleted) {
    return <Icon name="close" size="huge" />;
  } else if (state === ClusterState.ActionFailed) {
    return <i className={`icon-warning ${css.stateFailed} ${css.huge}`} />;
  } else {
    return <i className={`icon-warning ${css.stateFailed} ${css.huge}`} />;
  }
};

const getDescription = (state: string, detVersion: string) => {
  let descriptionState = <span>{capitalizeWord(state)}</span>;
  const descriptionVersion = <span>{detVersion}</span>;
  if (transitionalClusterState.has(state as ClusterState)) {
    descriptionState = <span>Updating...</span>;
  } else if (state === ClusterState.Running) {
    descriptionState = <span className={css.stateRunning}>Running</span>;
  } else if (state === ClusterState.ActionFailed) {
    descriptionState = <span className={css.stateFailed}>Action Failed</span>;
  }
  return (
    <>
      {descriptionState} {descriptionVersion}
    </>
  );
};

interface ClusterCardProps {
  cluster: GlobalApi.ModelClusterInfo;
}
const ClusterCard: React.FC<ClusterCardProps> = ({ cluster }: ClusterCardProps) => {
  const { auth } = useStore();

  const filterPools = useCallback(() => {
    if (cluster.resourcePools.length > 5) {
      return [
        ...cluster.resourcePools.slice(0, 4),
        {
          pool_name: `${cluster.resourcePools.length - 4} more...`,
          provider: { instance_type: '' },
        },
      ];
    } else if (cluster.resourcePools) {
      return cluster.resourcePools;
    }
  }, [cluster]);

  return (
    <Card className={css.card}>
      <div className={css.cardContent}>
        <div>
          <Meta
            avatar={getAvatarByState(cluster.state)}
            className={css.cardMeta}
            description={getDescription(cluster.state, cluster.detVersion)}
            title={cluster.name}
          />
          <ConfigProvider renderEmpty={() => null}>
            <List
              className={css.list}
              dataSource={filterPools()}
              header="Resource Pools"
              renderItem={(item) => (
                <List.Item>
                  <List.Item.Meta title={item.pool_name} />
                  <div>{item.provider.instance_type}</div>
                </List.Item>
              )}
            />
          </ConfigProvider>
        </div>
        <div className={css.buttonsWrapper}>
          <Link disabled isButton={true} key="details">
            View details
          </Link>
          <Link
            className={'ant-btn-primary'}
            disabled={disableClusterLinkByState(cluster.state as ClusterState)}
            isButton={true}
            key="open"
            onClick={(event: AnyMouseEvent) => {
              handlePath(event, {
                external: true,
                onClick: undefined,
                path: `https://${cluster.endpoint}/det/login?jwt=${auth.token}`,
                popout: true,
              });
            }}>
            Open
          </Link>
        </div>
      </div>
    </Card>
  );
};

export default ClusterCard;
