import { Button, ConfigProvider, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import React, { useCallback, useEffect, useState } from 'react';

import Link from 'components/Link';
import Page from 'components/Page';
import ResponsiveTable from 'components/ResponsiveTable';
import { useStore } from 'contexts/Store';
import { useFetchClusters } from 'experimental/notifications/hooks';
import { useFetchSupportMatrix } from 'hooks/useFetch';
import useModalAgentRole from 'hooks/useModal/useAgentRole';
import useModalClusterUsers from 'hooks/useModal/useClusterUsers';
import useModalNewCluster from 'hooks/useModal/useNewCluster/useNewCluster';
import useModalUpdateCluster from 'hooks/useModal/useUpdateCluster';
import useModalUpgradeCluster from 'hooks/useModal/useUpgradeVersion';
import { handlePath } from 'routes/utils';
import {
  ClusterState,
  stableClusterState,
  transitionalClusterState,
  upgradeableStates,
} from 'saasTypes';
import { deleteCluster, pauseCluster, resumeCluster } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import ActionDropdown from 'shared/components/ActionDropdown';
import Icon from 'shared/components/Icon';
import Message, { MessageType } from 'shared/components/Message';
import Spinner from 'shared/components/Spinner';
import usePolling from 'shared/hooks/usePolling';
import { copyToClipboard } from 'shared/utils/dom';
import { AnyMouseEvent } from 'shared/utils/routes';
import { alphaNumericSorter, semVerIsOlder } from 'shared/utils/sort';
import { capitalize, stringToVersion } from 'shared/utils/string';
import handleError, { DetError } from 'utils/error';
import { disableClusterLinkByState, isAdmin, suitableVersions } from 'utils/saas';

import css from './Clusters.module.scss';

export enum ClusterAction {
  Delete = 'Delete',
  ViewAccess = 'View Access',
  ManageAccess = 'Manage Access',
  Pause = 'Pause',
  Upgrade = 'Upgrade',
  Resume = 'Resume',
  TrainingDataDetails = 'Training Data Access',
  UpdateMaster = 'Reconfigure Master',
  UpdatePools = 'Reconfigure Resource Pools',
}

export const confirmations = {
  [ClusterAction.Pause]: {},
  [ClusterAction.Delete]: {},
  [ClusterAction.Resume]: {},
};

const Clusters: React.FC = () => {
  const [clusters, setClusters] = useState<GlobalApi.ModelClusterInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [supportedDetVersions, setSupportedDetVersions] = useState<string[]>([]);
  const [canceler] = useState(new AbortController());
  const {
    orgState: { selectedOrg },
    auth,
  } = useStore();
  const fetchSupportMatrix = useFetchSupportMatrix(canceler);
  const fetchClusters = useFetchClusters(canceler);

  const loadClusters = useCallback(async () => {
    if (!selectedOrg) return;
    const newClusters = await fetchClusters();
    setLoading(false);
    if (!newClusters) return;
    setClusters(newClusters);
  }, [selectedOrg, fetchClusters]);

  const loadVersions = useCallback(async () => {
    if (!selectedOrg) return;
    try {
      const supportMatrix = await fetchSupportMatrix();
      if (supportMatrix) setSupportedDetVersions(supportMatrix.supportedDetVersions);
    } catch (err) {
      handleError(err);
    }
  }, [selectedOrg, fetchSupportMatrix]);

  usePolling(loadClusters, { runImmediately: false });

  useEffect(() => {
    loadClusters();
  }, [loadClusters]);

  useEffect(() => {
    loadVersions();
  }, [loadVersions]);

  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  const formatState = (state: string) => {
    const formattedState = capitalize(state);
    if (transitionalClusterState.has(state as ClusterState)) {
      return formattedState + '...';
    }
    return formattedState;
  };

  const {
    modalOpen: openModalClusterUpdateMaster,
    contextHolder: contextHolderClusterUpdateMaster,
  } = useModalUpdateCluster({ onClose: loadClusters });

  const { modalOpen: openModalClusterUpdatePools, contextHolder: contextHolderClusterUpdatePools } =
    useModalUpdateCluster({ onClose: loadClusters });

  const { modalOpen: openModalClusterUpgrade, contextHolder: contextHolderClusterUpgrade } =
    useModalUpgradeCluster({ onClose: loadClusters });

  const { modalOpen: openModalClusterDetails, contextHolder: contextHolderClusterDetails } =
    useModalAgentRole({ onClose: loadClusters });

  const { modalOpen: openModalClusterUsers, contextHolder: contextHolderClusterUsers } =
    useModalClusterUsers({ onClose: loadClusters });

  const getTriggers = useCallback(
    (cluster) => {
      if (!selectedOrg) return {};
      return {
        [ClusterAction.Delete]: async () => {
          await deleteCluster({
            clusterId: cluster.id,
            orgId: selectedOrg.id,
            regionId: cluster.location,
          });
        },
        [ClusterAction.Pause]: async () => {
          await pauseCluster({
            clusterId: cluster.id,
            orgId: selectedOrg.id,
            regionId: cluster.location,
          });
        },
        [ClusterAction.Resume]: async () => {
          await resumeCluster({
            clusterId: cluster.id,
            orgId: selectedOrg.id,
            regionId: cluster.location,
          });
        },
        [ClusterAction.Upgrade]: () => {
          openModalClusterUpgrade({
            context: {
              cluster,
              orgId: selectedOrg.id,
              supportedDetVersions: supportedDetVersions,
            },
          });
        },
        [ClusterAction.UpdateMaster]: () => {
          openModalClusterUpdateMaster({
            context: {
              cluster,
              master: true,
              orgId: selectedOrg?.id,
            },
          });
        },
        [ClusterAction.UpdatePools]: () => {
          openModalClusterUpdatePools({
            context: {
              cluster,
              master: false,
              orgId: selectedOrg?.id,
            },
          });
        },
        [ClusterAction.ViewAccess]: () => {
          openModalClusterUsers({ context: { clusterId: cluster.id, orgId: selectedOrg.id } });
        },
        [ClusterAction.ManageAccess]: () => {
          openModalClusterUsers({ context: { clusterId: cluster.id, orgId: selectedOrg.id } });
        },
        [ClusterAction.TrainingDataDetails]: () => {
          openModalClusterDetails({
            context: {
              clusterId: cluster.id,
              orgId: selectedOrg.id,
              regionId: cluster.location,
            },
          });
        },
      };
    },
    [
      openModalClusterDetails,
      openModalClusterUpgrade,
      openModalClusterUsers,
      openModalClusterUpdateMaster,
      openModalClusterUpdatePools,
      supportedDetVersions,
      selectedOrg,
    ],
  );

  const getActionsForCluster = useCallback(
    (cluster: GlobalApi.ModelClusterInfo): ClusterAction[] => {
      if (!selectedOrg || !auth.roles) return [];

      const actions: ClusterAction[] = [ClusterAction.TrainingDataDetails];

      if (isAdmin(auth.roles, selectedOrg.id, cluster.id)) {
        actions.push(ClusterAction.ManageAccess);
      } else {
        actions.push(ClusterAction.ViewAccess);
      }

      // check for valid cluster actions.
      if (transitionalClusterState.has(cluster.state as ClusterState)) return actions;
      if (!stableClusterState.has(cluster.state as ClusterState)) {
        // sanity check. all states should be either in stable or transitional.
        throw new DetError(undefined, {
          publicMessage: 'Cluster is in an invalid state',
          silent: true,
        });
      }
      if (cluster.state === ClusterState.Deleted) return actions;

      actions.push(
        ClusterAction.Upgrade,
        ClusterAction.UpdateMaster,
        ClusterAction.UpdatePools,
        ClusterAction.Delete,
      );

      if (cluster.state === ClusterState.Running) {
        actions.push(ClusterAction.Pause);
      } else if (cluster.state === ClusterState.Paused) {
        actions.push(ClusterAction.Resume);
      }

      return actions;
    },
    [selectedOrg, auth.roles],
  );

  const getDisabledActionsForCluster = useCallback(
    (cluster: GlobalApi.ModelClusterInfo) => {
      const disabledActions = { [ClusterAction.Upgrade]: false };

      if (
        !upgradeableStates.has(cluster.state as ClusterState) ||
        suitableVersions(supportedDetVersions, cluster.detVersion).length < 1
      ) {
        disabledActions[ClusterAction.Upgrade] = true;
      }

      return disabledActions;
    },
    [supportedDetVersions],
  );

  const columns: ColumnsType<GlobalApi.ModelClusterInfo> = [
    {
      dataIndex: 'id',
      defaultSortOrder: 'ascend',
      key: 'id',
      sorter: (a, b) => alphaNumericSorter(a.id, b.id),
      title: 'ID',
      width: 300,
    },
    {
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => alphaNumericSorter(a.name, b.name),
      title: 'Name',
    },
    {
      dataIndex: 'location',
      key: 'location',
      sorter: (a, b) => alphaNumericSorter(a.location, b.location),
      title: 'Location',
    },
    {
      dataIndex: 'state',
      key: 'state',
      render: (state: ClusterState) => {
        return <span className={css[`${state}-state`]}>{formatState(state)}</span>;
      },
      sorter: (a, b) => alphaNumericSorter(a.state, b.state),
      title: 'Status',
    },
    {
      dataIndex: 'detVersion',
      key: 'detVersion',
      sorter: (a, b) =>
        semVerIsOlder(stringToVersion(a.detVersion), stringToVersion(b.detVersion)) ? -1 : 1,
      title: 'Version',
    },
    {
      key: 'view',
      render: (_, record) => {
        return (
          <div className={css.buttonsWrapper}>
            <Link
              disabled={disableClusterLinkByState(record.state as ClusterState)}
              isButton={true}
              onClick={(event: AnyMouseEvent) => {
                handlePath(event, {
                  external: true,
                  onClick: undefined,
                  path: `https://${record.endpoint}/det/login?jwt=${auth.token}`,
                  popout: true,
                });
              }}>
              View Cluster
            </Link>
            <Tooltip title="Copy Cluster Link">
              <Button onClick={() => copyToClipboard(`https://${record.endpoint}/`)}>
                <Icon name="clipboard" />
              </Button>
            </Tooltip>
          </div>
        );
      },
      width: 152,
    },
    {
      align: 'right',
      fixed: 'right',
      key: 'action',
      render: (_, record) => {
        return (
          <ActionDropdown<ClusterAction>
            actionOrder={getActionsForCluster(record)}
            confirmations={confirmations}
            disabled={getDisabledActionsForCluster(record)}
            id={record.id}
            kind="cluster"
            onComplete={() => loadClusters()}
            onError={handleError}
            onTrigger={getTriggers(record)}
          />
        );
      },
      width: 20,
    },
  ];

  const emptyMessage = useCallback(() => {
    if (loading) return <Spinner tip="Fetching list of clusters" />;
    return (
      <Message
        message="Create a cluster to get started"
        title="No clusters"
        type={MessageType.Empty}
      />
    );
  }, [loading]);

  const { modalOpen, contextHolder: contextHolderNewCluster } = useModalNewCluster();

  return (
    <Page options={<Button onClick={() => modalOpen()}>New Cluster</Button>} title="Clusters">
      <ConfigProvider renderEmpty={emptyMessage}>
        <ResponsiveTable
          columns={columns}
          dataSource={clusters}
          pagination={{ hideOnSinglePage: true }}
          rowKey="id"
          showSorterTooltip={true}
        />
      </ConfigProvider>
      {contextHolderClusterUpgrade}
      {contextHolderClusterUsers}
      {contextHolderNewCluster}
      {contextHolderClusterUpdateMaster}
      {contextHolderClusterUpdatePools}
      {contextHolderClusterDetails}
    </Page>
  );
};

export default Clusters;
