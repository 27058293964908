import React from 'react';

import Grid from 'components/Grid';
import Page from 'components/Page';
import Message from 'shared/components/Message';

const comps: React.ReactNode[] = [<Message key="shared-message" title="Nothing to see here." />];

const WIP: React.FC = () => {
  return (
    <Page title="Dev - Work In Progress">
      <Grid>{comps}</Grid>
    </Page>
  );
};

export default WIP;
