import { Divider, Form, FormInstance, Input } from 'antd';
import React, { useCallback, useState } from 'react';

import InstanceTypeDropdown, { InstanceUse } from 'components/InstanceTypeDropdown';
import Link from 'components/Link';
import RegionPicker from 'components/RegionPicker';
import ResourcePools from 'components/ResourcePools';
import VersionPicker from 'components/VersionPicker';
import { BasicConfigOption, DefaultResourcePool, OverallConfig, PoolConfig } from 'saasTypes';
import { ModelAgentResourceManagerConfig } from 'services/regional-bindings';
import Spinner from 'shared/components/Spinner';
import { SemanticVersion } from 'shared/types';
import { stringToVersion, versionToString } from 'shared/utils/string';

import css from './useNewCluster.module.scss';

interface BasicConfigSelectProps {
  onSelect: (option: BasicConfigOption) => void;
  selected: BasicConfigOption;
}

const BasicConfigSelect: React.FC<BasicConfigSelectProps> = ({
  selected,
  onSelect,
}: BasicConfigSelectProps) => {
  const standardClasses = [css.card];
  const proClasses = [css.card];
  if (selected === BasicConfigOption.Standard) {
    standardClasses.push(css.selected);
  } else if (selected === BasicConfigOption.Pro) {
    proClasses.push(css.selected);
  }

  return (
    <div className={css.cards}>
      <div
        className={standardClasses.join(' ')}
        onClick={() => {
          onSelect(BasicConfigOption.Standard);
        }}>
        <h5>Standard</h5>
        <p>Accelerator(s) per Instance</p>
        <p className={css.value}>1 x NVIDIA T4</p>
        <p>Max Instances</p>
        <p className={css.value}>2</p>
        <p>Estimated Usage Price</p>
        <p className={css.value}>~$0.53 / instance-hour</p>
      </div>
      <div
        className={proClasses.join(' ')}
        onClick={() => {
          onSelect(BasicConfigOption.Pro);
        }}>
        <h5>Pro</h5>
        <p>Accelerator(s) per Instance</p>
        <p className={css.value}>8 x NVIDIA Tesla® V100</p>
        <p>Max Instances</p>
        <p className={css.value}>2</p>
        <p>Estimated Usage Price</p>
        <p className={css.value}>$12.24 / instance-hour</p>
      </div>
    </div>
  );
};

interface AdvancedConfigFormProps {
  clusterRegion: string;
  onAddPoolConfig: () => void;
  onRemovePoolConfig: (key: string) => void;
  onUpdateOverallConfig: React.Dispatch<React.SetStateAction<OverallConfig>>;
  onUpdatePoolConfig: (key: string, form: FormInstance) => void;
  onUpdateResourceManager: (keys: DefaultResourcePool[], value?: string) => void;
  overallConfig: OverallConfig;
  poolConfigs: PoolConfig[];
  resourceManager: ModelAgentResourceManagerConfig;
  supportedDetVersions: string[];
}

const AdvancedConfigForm: React.FC<AdvancedConfigFormProps> = ({
  poolConfigs,
  overallConfig,
  onAddPoolConfig,
  onRemovePoolConfig,
  onUpdatePoolConfig,
  onUpdateResourceManager,
  onUpdateOverallConfig,
  supportedDetVersions,
  clusterRegion,
  resourceManager,
}) => {
  const [form] = Form.useForm();
  const [hideDropdown, setHideDropdown] = useState(true);

  const onShowDropdown = useCallback(() => {
    setHideDropdown(false);
  }, [setHideDropdown]);

  const updateOverallConfig = useCallback(
    (updated) => {
      onUpdateOverallConfig({ ...overallConfig, ...updated });
    },
    [onUpdateOverallConfig, overallConfig],
  );

  const updateVersion = useCallback(
    (ver: SemanticVersion) => {
      onUpdateOverallConfig({ ...overallConfig, detVersion: versionToString(ver) });
    },
    [onUpdateOverallConfig, overallConfig],
  );

  const selectInstanceType = useCallback(
    (instanceType: string) => {
      onUpdateOverallConfig({
        ...overallConfig,
        ...{ masterInstanceType: instanceType },
      });
      setHideDropdown(true);
    },
    [setHideDropdown, onUpdateOverallConfig, overallConfig],
  );

  return (
    <>
      <Form<OverallConfig>
        form={form}
        initialValues={{
          detVersion: overallConfig.detVersion,
          masterInstanceType: overallConfig.masterInstanceType,
        }}
        labelCol={{ span: 24 }}
        onValuesChange={updateOverallConfig}>
        <Form.Item label="Determined Version" name="detVersion">
          <VersionPicker
            curVersion={stringToVersion(overallConfig.detVersion)}
            versions={supportedDetVersions.map((v) => stringToVersion(v))}
            onChange={updateVersion}
          />
        </Form.Item>
        <Form.Item
          className={css.formItemWithLink}
          label={
            <>
              <span>Master Instance Type</span>
              <Link path="https://aws.amazon.com/ec2/instance-types/#General_Purpose" popout={true}>
                List of Instance Types
              </Link>
            </>
          }
          labelCol={{ span: 24 }}
          name="masterInstanceType">
          <InstanceTypeDropdown
            buttonLabel={overallConfig.masterInstanceType}
            clusterRegion={clusterRegion}
            disableAutoDismiss={true}
            hide={hideDropdown}
            showArrow={false}
            use={InstanceUse.Master}
            onSelect={selectInstanceType}
            onVisibleChange={onShowDropdown}
          />
        </Form.Item>
      </Form>
      <ResourcePools
        clusterRegion={clusterRegion}
        pools={poolConfigs}
        resourceManager={resourceManager}
        onAddPoolConfig={onAddPoolConfig}
        onRemovePoolConfig={onRemovePoolConfig}
        onUpdatePoolConfig={onUpdatePoolConfig}
        onUpdateResourceManager={onUpdateResourceManager}
      />
    </>
  );
};

interface NewClusterModalContentProps extends AdvancedConfigFormProps {
  basicConfigSelection: BasicConfigOption;
  clusterName: string;
  isAdvanced: boolean;
  onSelectBasicOption: (selection: BasicConfigOption) => void;
  onToggleConfigType: () => void;
  onUpdateClusterName: React.Dispatch<React.SetStateAction<string>>;
  onUpdateClusterRegion: React.Dispatch<React.SetStateAction<string>>;
}
export const NewClusterModalContent: React.FC<NewClusterModalContentProps> = ({
  poolConfigs,
  basicConfigSelection,
  clusterName,
  clusterRegion,
  isAdvanced,
  overallConfig,
  resourceManager,
  onAddPoolConfig,
  onSelectBasicOption,
  onRemovePoolConfig,
  onToggleConfigType,
  onUpdatePoolConfig,
  onUpdateOverallConfig,
  supportedDetVersions,
  onUpdateClusterName,
  onUpdateClusterRegion,
  onUpdateResourceManager,
}) => {
  if (!supportedDetVersions.length) return <Spinner tip="Loading..." />;
  return (
    <div className={css.modalContent}>
      <Form labelCol={{ span: 24 }}>
        <Form.Item label="Region">
          <RegionPicker curRegion={clusterRegion} onChange={onUpdateClusterRegion} />
        </Form.Item>
        <Form.Item
          label="Cluster Name"
          name="clusterName"
          rules={[{ message: 'Cluster name required', required: true }]}>
          <Input
            value={clusterName}
            onChange={(e) => {
              onUpdateClusterName(e.target.value);
            }}
          />
        </Form.Item>
        <p className={css.sectionHeader}>
          Configuration
          <Link onClick={onToggleConfigType}>{isAdvanced ? 'Basic' : 'Advanced'}</Link>
        </p>
      </Form>
      {isAdvanced ? (
        <AdvancedConfigForm
          clusterRegion={clusterRegion}
          overallConfig={overallConfig}
          poolConfigs={poolConfigs}
          resourceManager={resourceManager}
          supportedDetVersions={supportedDetVersions}
          onAddPoolConfig={onAddPoolConfig}
          onRemovePoolConfig={onRemovePoolConfig}
          onUpdateOverallConfig={onUpdateOverallConfig}
          onUpdatePoolConfig={onUpdatePoolConfig}
          onUpdateResourceManager={onUpdateResourceManager}
        />
      ) : (
        <BasicConfigSelect selected={basicConfigSelection} onSelect={onSelectBasicOption} />
      )}
      <Divider />
      <div>
        You will only be charged these prices while accelerators are allocated for your workloads.
        Charges for data storage and the Determined master instance are separate. Clusters can take
        5-10 minutes to spin up.
      </div>
    </div>
  );
};
