import { RouteConfig } from 'shared/types';

const routes: RouteConfig[] = [
  {
    id: 'signIn',
    needAuth: false,
    path: '/login',
    title: 'Login',
  },
  {
    id: 'signOut',
    needAuth: false,
    path: '/logout',
    title: 'Logout',
  },
  {
    id: 'reload',
    needAuth: false,
    path: '/reload',
    title: 'Reload',
  },
  {
    id: 'members',
    needAuth: true,
    path: '/members',
    title: 'Members',
  },
  {
    id: 'clusters',
    needAuth: true,
    path: '/clusters',
    title: 'Clusters',
  },
  {
    id: 'cards', // temporary route
    needAuth: true,
    path: '/cards',
    title: 'Cluster Cards (beta)',
  },
  {
    id: 'wip',
    needAuth: true,
    path: '/wip',
    title: 'Open Work in Progress',
  },
  {
    id: 'onboarding',
    needAuth: true,
    path: '/getting-started',
    title: 'Getting Started',
  },
  {
    id: 'unverifiedEmail',
    needAuth: false,
    path: 'messages/unverified-email',
  },
];

export default routes;
