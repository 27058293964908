import { Button, Form, Select } from 'antd';
import React, { ReactElement, useState } from 'react';

import { AccessLevel } from 'saasTypes';
import { capitalizeWord } from 'shared/utils/string';

import css from './AccessLevelAdd.module.scss';

const { Option } = Select;

interface Props<T> {
  onUpdate: (optionToAdd: string, AccessLevel: AccessLevel) => void;
  optionIdField: 'id' | 'user';
  optionLabelField: 'name' | 'user';
  optionType: 'member' | 'cluster';
  options: T[];
}

interface SelectOption {
  // relevant fields from ModelClusterInfo and ModelClusterUser:
  id?: string;
  name?: string;
  user?: string;
}

const AccessLevelAdd = <T extends SelectOption>({
  onUpdate,
  options,
  optionType,
  optionIdField,
  optionLabelField,
}: Props<T>): ReactElement => {
  const [isAdding, setIsAdding] = useState<boolean>();
  const [optionToAdd, setOptionToAdd] = useState<string>();
  const [form] = Form.useForm();

  let placeholder = `Search ${optionType}s`;
  if (options.length === 0) placeholder = `No ${optionType}s available`;

  return (
    <div className={css.addOption}>
      <Form form={form} layout="inline">
        <Form.Item name="selection">
          <Select
            allowClear={false}
            disabled={isAdding || options.length === 0}
            optionFilterProp="children"
            placeholder={placeholder}
            showSearch
            onChange={(optionId) => {
              if (!optionId) return;
              setOptionToAdd(optionId.toString());
            }}>
            {options.map((option: SelectOption) => {
              return (
                <Option key={option[optionIdField]} value={option[optionIdField]}>
                  {option[optionLabelField]}
                </Option>
              );
            })}
          </Select>
        </Form.Item>
        <Form.Item>
          <Button
            disabled={!optionToAdd}
            loading={isAdding}
            onClick={async () => {
              if (!optionToAdd) return;
              setIsAdding(true);
              await onUpdate(optionToAdd, AccessLevel.User);
              setIsAdding(false);
              setOptionToAdd(undefined);
              form.resetFields(['selection']);
            }}>
            Add {capitalizeWord(optionType)}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AccessLevelAdd;
