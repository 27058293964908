/// <reference path="./custom.d.ts" />
// tslint:disable
/**
 * Determined AI Cloud - Regional Management API
 * This service is for managing user accounts, organizations, and access control
 *
 * OpenAPI spec version: 0.1
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */


import * as url from "url";
import * as portableFetch from "portable-fetch";
import { Configuration } from "./configuration";

const BASE_PATH = "https://[location].dev.det-cloud.net:4931".replace(/\/+$/, "");

/**
 *
 * @export
 */
export const COLLECTION_FORMATS = {
    csv: ",",
    ssv: " ",
    tsv: "\t",
    pipes: "|",
};

/**
 *
 * @export
 * @interface FetchAPI
 */
export interface FetchAPI {
    (url: string, init?: any): Promise<Response>;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
    url: string;
    options: any;
}

/**
 *
 * @export
 * @class BaseAPI
 */
export class BaseAPI {
    protected configuration: Configuration;

    constructor(configuration?: Configuration, protected basePath: string = BASE_PATH, protected fetch: FetchAPI = portableFetch) {
        if (configuration) {
            this.configuration = configuration;
            this.basePath = configuration.basePath || this.basePath;
        }
    }
};

/**
 *
 * @export
 * @class RequiredError
 * @extends {Error}
 */
export class RequiredError extends Error {
    name: "RequiredError"
    constructor(public field: string, msg?: string) {
        super(msg);
    }
}

/**
 * 
 * @export
 * @interface ModelActionInfo
 */
export interface ModelActionInfo {
    /**
     * 
     * @type {string}
     * @memberof ModelActionInfo
     */
    owner: string;
    /**
     * 
     * @type {Array<ModelProgressElement>}
     * @memberof ModelActionInfo
     */
    progress: Array<ModelProgressElement>;
    /**
     * 
     * @type {string}
     * @memberof ModelActionInfo
     */
    startTime: string;
    /**
     * 
     * @type {string}
     * @memberof ModelActionInfo
     */
    state: string;
    /**
     * 
     * @type {string}
     * @memberof ModelActionInfo
     */
    type: string;
    /**
     * 
     * @type {any}
     * @memberof ModelActionInfo
     */
    value: any;
}

/**
 * 
 * @export
 * @interface ModelAgentResourceManagerConfig
 */
export interface ModelAgentResourceManagerConfig {
    /**
     * 
     * @type {string}
     * @memberof ModelAgentResourceManagerConfig
     */
    default_aux_resource_pool: string;
    /**
     * 
     * @type {string}
     * @memberof ModelAgentResourceManagerConfig
     */
    default_compute_resource_pool: string;
}

/**
 * 
 * @export
 * @interface ModelClusterDetails
 */
export interface ModelClusterDetails {
    /**
     * 
     * @type {string}
     * @memberof ModelClusterDetails
     */
    agentRole: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterDetails
     */
    detVersion: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterDetails
     */
    endpoint: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterDetails
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterDetails
     */
    location: string;
    /**
     * 
     * @type {ModelMasterConfig}
     * @memberof ModelClusterDetails
     */
    masterConfig: ModelMasterConfig;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterDetails
     */
    masterInstanceType: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterDetails
     */
    name: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterDetails
     */
    state: string;
}

/**
 * 
 * @export
 * @interface ModelClusterInfo
 */
export interface ModelClusterInfo {
    /**
     * 
     * @type {string}
     * @memberof ModelClusterInfo
     */
    detVersion: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterInfo
     */
    endpoint: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterInfo
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterInfo
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterInfo
     */
    name: string;
    /**
     * 
     * @type {Array<ModelResourcePoolConfig>}
     * @memberof ModelClusterInfo
     */
    resourcePools: Array<ModelResourcePoolConfig>;
    /**
     * 
     * @type {string}
     * @memberof ModelClusterInfo
     */
    state: string;
}

/**
 * 
 * @export
 * @interface ModelCreateClusterRequest
 */
export interface ModelCreateClusterRequest {
    /**
     * 
     * @type {string}
     * @memberof ModelCreateClusterRequest
     */
    detVersion: string;
    /**
     * 
     * @type {ModelMasterConfig}
     * @memberof ModelCreateClusterRequest
     */
    masterConfig: ModelMasterConfig;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateClusterRequest
     */
    masterInstanceType: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateClusterRequest
     */
    name: string;
}

/**
 * 
 * @export
 * @interface ModelCreateClusterResponse
 */
export interface ModelCreateClusterResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelCreateClusterResponse
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateClusterResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelCreateClusterResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelEditClusterRequest
 */
export interface ModelEditClusterRequest {
    /**
     * 
     * @type {string}
     * @memberof ModelEditClusterRequest
     */
    name: string;
}

/**
 * 
 * @export
 * @interface ModelErrorRESTResponse
 */
export interface ModelErrorRESTResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelErrorRESTResponse
     */
    error: string;
    /**
     * 
     * @type {string}
     * @memberof ModelErrorRESTResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelErrorRESTResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelGetClusterActionsResponse
 */
export interface ModelGetClusterActionsResponse {
    /**
     * 
     * @type {Array<ModelActionInfo>}
     * @memberof ModelGetClusterActionsResponse
     */
    actions: Array<ModelActionInfo>;
    /**
     * 
     * @type {string}
     * @memberof ModelGetClusterActionsResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGetClusterActionsResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelGetClusterResponse
 */
export interface ModelGetClusterResponse {
    /**
     * 
     * @type {ModelClusterDetails}
     * @memberof ModelGetClusterResponse
     */
    cluster: ModelClusterDetails;
    /**
     * 
     * @type {string}
     * @memberof ModelGetClusterResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelGetClusterResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelListClustersResponse
 */
export interface ModelListClustersResponse {
    /**
     * 
     * @type {Array<ModelClusterInfo>}
     * @memberof ModelListClustersResponse
     */
    clusters: Array<ModelClusterInfo>;
    /**
     * 
     * @type {string}
     * @memberof ModelListClustersResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelListClustersResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelMasterConfig
 */
export interface ModelMasterConfig {
    /**
     * 
     * @type {ModelAgentResourceManagerConfig}
     * @memberof ModelMasterConfig
     */
    resource_manager: ModelAgentResourceManagerConfig;
    /**
     * 
     * @type {Array<ModelResourcePoolConfig>}
     * @memberof ModelMasterConfig
     */
    resource_pools: Array<ModelResourcePoolConfig>;
}

/**
 * 
 * @export
 * @interface ModelProgressElement
 */
export interface ModelProgressElement {
    /**
     * 
     * @type {string}
     * @memberof ModelProgressElement
     */
    status: string;
    /**
     * 
     * @type {string}
     * @memberof ModelProgressElement
     */
    step: string;
}

/**
 * 
 * @export
 * @interface ModelProviderConfig
 */
export interface ModelProviderConfig {
    /**
     * 
     * @type {boolean}
     * @memberof ModelProviderConfig
     */
    cpu_slots_allowed: boolean;
    /**
     * These are from AWSClusterConfig upstream, which is also included via github.com/determined-ai/determined/master/pkg/union
     * @type {string}
     * @memberof ModelProviderConfig
     */
    instance_type: string;
    /**
     * 
     * @type {number}
     * @memberof ModelProviderConfig
     */
    max_instances: number;
    /**
     * When we support multiple clouds, this field should be handled by something like github.com/determined-ai/determined/master/pkg/union This should always be 'aws' right now, and is here to ensure we don't break compatibility when we add multi-cloud support
     * @type {string}
     * @memberof ModelProviderConfig
     */
    type: string;
}

/**
 * 
 * @export
 * @interface ModelRESTResponse
 */
export interface ModelRESTResponse {
    /**
     * 
     * @type {string}
     * @memberof ModelRESTResponse
     */
    location: string;
    /**
     * 
     * @type {string}
     * @memberof ModelRESTResponse
     */
    requestId: string;
}

/**
 * 
 * @export
 * @interface ModelReprovisionClusterRequest
 */
export interface ModelReprovisionClusterRequest {
    /**
     * 
     * @type {string}
     * @memberof ModelReprovisionClusterRequest
     */
    masterInstanceType: string;
}

/**
 * 
 * @export
 * @interface ModelResourcePoolConfig
 */
export interface ModelResourcePoolConfig {
    /**
     * 
     * @type {number}
     * @memberof ModelResourcePoolConfig
     */
    max_aux_containers_per_agent: number;
    /**
     * 
     * @type {string}
     * @memberof ModelResourcePoolConfig
     */
    pool_name: string;
    /**
     * 
     * @type {ModelProviderConfig}
     * @memberof ModelResourcePoolConfig
     */
    provider: ModelProviderConfig;
}

/**
 * 
 * @export
 * @interface ModelUpgradeClusterRequest
 */
export interface ModelUpgradeClusterRequest {
    /**
     * 
     * @type {string}
     * @memberof ModelUpgradeClusterRequest
     */
    detVersion: string;
}


/**
 * ClustersApi - fetch parameter creator
 * @export
 */
export const ClustersApiFetchParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * create a cluster
         * @summary Create a cluster
         * @param {string} orgId organization ID
         * @param {ModelCreateClusterRequest} cluster initial cluster options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCluster(orgId: string, cluster: ModelCreateClusterRequest, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling createCluster.');
            }
            // verify required parameter 'cluster' is not null or undefined
            if (cluster === null || cluster === undefined) {
                throw new RequiredError('cluster','Required parameter cluster was null or undefined when calling createCluster.');
            }
            const localVarPath = `/orgs/{orgId}/clusters`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelCreateClusterRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(cluster || {}) : (cluster || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * permanently delete the compute and storage resources of a cluster
         * @summary Delete a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCluster(orgId: string, clusterId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling deleteCluster.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling deleteCluster.');
            }
            const localVarPath = `/orgs/{orgId}/clusters/{clusterId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'DELETE' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * write cluster metadata fields (empty fields are treated as unchanged)
         * @summary Edit cluster metadata
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {ModelEditClusterRequest} cluster initial cluster options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCluster(orgId: string, clusterId: string, cluster: ModelEditClusterRequest, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling editCluster.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling editCluster.');
            }
            // verify required parameter 'cluster' is not null or undefined
            if (cluster === null || cluster === undefined) {
                throw new RequiredError('cluster','Required parameter cluster was null or undefined when calling editCluster.');
            }
            const localVarPath = `/orgs/{orgId}/clusters/{clusterId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelEditClusterRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(cluster || {}) : (cluster || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * get cluster
         * @summary Get cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCluster(orgId: string, clusterId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getCluster.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling getCluster.');
            }
            const localVarPath = `/orgs/{orgId}/clusters/{clusterId}`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list the lifecycle actions and configuration operations that have been applied
         * @summary List the actions taken on the cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterActions(orgId: string, clusterId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling getClusterActions.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling getClusterActions.');
            }
            const localVarPath = `/orgs/{orgId}/clusters/{clusterId}/actions`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * list clusters
         * @summary List clusters
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusters(orgId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling listClusters.');
            }
            const localVarPath = `/orgs/{orgId}/clusters`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'GET' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * shut down all compute resources of a running cluster
         * @summary Pause a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseCluster(orgId: string, clusterId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling pauseCluster.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling pauseCluster.');
            }
            const localVarPath = `/orgs/{orgId}/clusters/{clusterId}/pause`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * restart the Determined master process with a new configuration
         * @summary Reconfigure a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {ModelMasterConfig} config cluster master configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reconfigureCluster(orgId: string, clusterId: string, config: ModelMasterConfig, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling reconfigureCluster.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling reconfigureCluster.');
            }
            // verify required parameter 'config' is not null or undefined
            if (config === null || config === undefined) {
                throw new RequiredError('config','Required parameter config was null or undefined when calling reconfigureCluster.');
            }
            const localVarPath = `/orgs/{orgId}/clusters/{clusterId}/reconfigure`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelMasterConfig" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(config || {}) : (config || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * terminate and reprovision the Determined master instance with modified parameters
         * @summary Reprovision a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {ModelReprovisionClusterRequest} instanceType Master instance type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reprovisionCluster(orgId: string, clusterId: string, instanceType: ModelReprovisionClusterRequest, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling reprovisionCluster.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling reprovisionCluster.');
            }
            // verify required parameter 'instanceType' is not null or undefined
            if (instanceType === null || instanceType === undefined) {
                throw new RequiredError('instanceType','Required parameter instanceType was null or undefined when calling reprovisionCluster.');
            }
            const localVarPath = `/orgs/{orgId}/clusters/{clusterId}/reprovision`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelReprovisionClusterRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(instanceType || {}) : (instanceType || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Replace an unrecoverable cluster DB with a new DB from backup
         * @summary Restore the cluster DB from backup
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {string} restoreToTime time to restore to in ISO format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreClusterDb(orgId: string, clusterId: string, restoreToTime: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling restoreClusterDb.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling restoreClusterDb.');
            }
            // verify required parameter 'restoreToTime' is not null or undefined
            if (restoreToTime === null || restoreToTime === undefined) {
                throw new RequiredError('restoreToTime','Required parameter restoreToTime was null or undefined when calling restoreClusterDb.');
            }
            const localVarPath = `/orgs/{orgId}/clusters/{clusterId}/restoreDb`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"string" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(restoreToTime || {}) : (restoreToTime || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * restart minimal compute resources of a paused cluster
         * @summary Resume a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeCluster(orgId: string, clusterId: string, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling resumeCluster.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling resumeCluster.');
            }
            const localVarPath = `/orgs/{orgId}/clusters/{clusterId}/resume`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * restart the Determined master process with a new Determined version
         * @summary Upgrade a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {ModelUpgradeClusterRequest} version Determined version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upgradeCluster(orgId: string, clusterId: string, version: ModelUpgradeClusterRequest, options: any = {}): FetchArgs {
            // verify required parameter 'orgId' is not null or undefined
            if (orgId === null || orgId === undefined) {
                throw new RequiredError('orgId','Required parameter orgId was null or undefined when calling upgradeCluster.');
            }
            // verify required parameter 'clusterId' is not null or undefined
            if (clusterId === null || clusterId === undefined) {
                throw new RequiredError('clusterId','Required parameter clusterId was null or undefined when calling upgradeCluster.');
            }
            // verify required parameter 'version' is not null or undefined
            if (version === null || version === undefined) {
                throw new RequiredError('version','Required parameter version was null or undefined when calling upgradeCluster.');
            }
            const localVarPath = `/orgs/{orgId}/clusters/{clusterId}/upgrade`
                .replace(`{${"orgId"}}`, encodeURIComponent(String(orgId)))
                .replace(`{${"clusterId"}}`, encodeURIComponent(String(clusterId)));
            const localVarUrlObj = url.parse(localVarPath, true);
            const localVarRequestOptions = Object.assign({ method: 'POST' }, options);
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication BearerToken required
            if (configuration && configuration.apiKey) {
                const localVarApiKeyValue = typeof configuration.apiKey === 'function'
					? configuration.apiKey("Authorization")
					: configuration.apiKey;
                localVarHeaderParameter["Authorization"] = localVarApiKeyValue;
            }

            localVarHeaderParameter['Content-Type'] = 'application/json';

            localVarUrlObj.query = Object.assign({}, localVarUrlObj.query, localVarQueryParameter, options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign({}, localVarHeaderParameter, options.headers);
            const needsSerialization = (<any>"ModelUpgradeClusterRequest" !== "string") || localVarRequestOptions.headers['Content-Type'] === 'application/json';
            localVarRequestOptions.body =  needsSerialization ? JSON.stringify(version || {}) : (version || "");

            return {
                url: url.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ClustersApi - functional programming interface
 * @export
 */
export const ClustersApiFp = function(configuration?: Configuration) {
    return {
        /**
         * create a cluster
         * @summary Create a cluster
         * @param {string} orgId organization ID
         * @param {ModelCreateClusterRequest} cluster initial cluster options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCluster(orgId: string, cluster: ModelCreateClusterRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelCreateClusterResponse> {
            const localVarFetchArgs = ClustersApiFetchParamCreator(configuration).createCluster(orgId, cluster, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * permanently delete the compute and storage resources of a cluster
         * @summary Delete a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCluster(orgId: string, clusterId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = ClustersApiFetchParamCreator(configuration).deleteCluster(orgId, clusterId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * write cluster metadata fields (empty fields are treated as unchanged)
         * @summary Edit cluster metadata
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {ModelEditClusterRequest} cluster initial cluster options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCluster(orgId: string, clusterId: string, cluster: ModelEditClusterRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = ClustersApiFetchParamCreator(configuration).editCluster(orgId, clusterId, cluster, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * get cluster
         * @summary Get cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCluster(orgId: string, clusterId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGetClusterResponse> {
            const localVarFetchArgs = ClustersApiFetchParamCreator(configuration).getCluster(orgId, clusterId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * list the lifecycle actions and configuration operations that have been applied
         * @summary List the actions taken on the cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterActions(orgId: string, clusterId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelGetClusterActionsResponse> {
            const localVarFetchArgs = ClustersApiFetchParamCreator(configuration).getClusterActions(orgId, clusterId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * list clusters
         * @summary List clusters
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusters(orgId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelListClustersResponse> {
            const localVarFetchArgs = ClustersApiFetchParamCreator(configuration).listClusters(orgId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * shut down all compute resources of a running cluster
         * @summary Pause a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseCluster(orgId: string, clusterId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = ClustersApiFetchParamCreator(configuration).pauseCluster(orgId, clusterId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * restart the Determined master process with a new configuration
         * @summary Reconfigure a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {ModelMasterConfig} config cluster master configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reconfigureCluster(orgId: string, clusterId: string, config: ModelMasterConfig, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = ClustersApiFetchParamCreator(configuration).reconfigureCluster(orgId, clusterId, config, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * terminate and reprovision the Determined master instance with modified parameters
         * @summary Reprovision a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {ModelReprovisionClusterRequest} instanceType Master instance type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reprovisionCluster(orgId: string, clusterId: string, instanceType: ModelReprovisionClusterRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = ClustersApiFetchParamCreator(configuration).reprovisionCluster(orgId, clusterId, instanceType, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * Replace an unrecoverable cluster DB with a new DB from backup
         * @summary Restore the cluster DB from backup
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {string} restoreToTime time to restore to in ISO format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreClusterDb(orgId: string, clusterId: string, restoreToTime: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = ClustersApiFetchParamCreator(configuration).restoreClusterDb(orgId, clusterId, restoreToTime, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * restart minimal compute resources of a paused cluster
         * @summary Resume a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeCluster(orgId: string, clusterId: string, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = ClustersApiFetchParamCreator(configuration).resumeCluster(orgId, clusterId, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
        /**
         * restart the Determined master process with a new Determined version
         * @summary Upgrade a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {ModelUpgradeClusterRequest} version Determined version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upgradeCluster(orgId: string, clusterId: string, version: ModelUpgradeClusterRequest, options?: any): (fetch?: FetchAPI, basePath?: string) => Promise<ModelRESTResponse> {
            const localVarFetchArgs = ClustersApiFetchParamCreator(configuration).upgradeCluster(orgId, clusterId, version, options);
            return (fetch: FetchAPI = portableFetch, basePath: string = BASE_PATH) => {
                return fetch(basePath + localVarFetchArgs.url, localVarFetchArgs.options).then((response) => {
                    if (response.status >= 200 && response.status < 300) {
                        return response.json();
                    } else {
                        throw response;
                    }
                });
            };
        },
    }
};

/**
 * ClustersApi - factory interface
 * @export
 */
export const ClustersApiFactory = function (configuration?: Configuration, fetch?: FetchAPI, basePath?: string) {
    return {
        /**
         * create a cluster
         * @summary Create a cluster
         * @param {string} orgId organization ID
         * @param {ModelCreateClusterRequest} cluster initial cluster options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createCluster(orgId: string, cluster: ModelCreateClusterRequest, options?: any) {
            return ClustersApiFp(configuration).createCluster(orgId, cluster, options)(fetch, basePath);
        },
        /**
         * permanently delete the compute and storage resources of a cluster
         * @summary Delete a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteCluster(orgId: string, clusterId: string, options?: any) {
            return ClustersApiFp(configuration).deleteCluster(orgId, clusterId, options)(fetch, basePath);
        },
        /**
         * write cluster metadata fields (empty fields are treated as unchanged)
         * @summary Edit cluster metadata
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {ModelEditClusterRequest} cluster initial cluster options
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        editCluster(orgId: string, clusterId: string, cluster: ModelEditClusterRequest, options?: any) {
            return ClustersApiFp(configuration).editCluster(orgId, clusterId, cluster, options)(fetch, basePath);
        },
        /**
         * get cluster
         * @summary Get cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCluster(orgId: string, clusterId: string, options?: any) {
            return ClustersApiFp(configuration).getCluster(orgId, clusterId, options)(fetch, basePath);
        },
        /**
         * list the lifecycle actions and configuration operations that have been applied
         * @summary List the actions taken on the cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getClusterActions(orgId: string, clusterId: string, options?: any) {
            return ClustersApiFp(configuration).getClusterActions(orgId, clusterId, options)(fetch, basePath);
        },
        /**
         * list clusters
         * @summary List clusters
         * @param {string} orgId organization ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listClusters(orgId: string, options?: any) {
            return ClustersApiFp(configuration).listClusters(orgId, options)(fetch, basePath);
        },
        /**
         * shut down all compute resources of a running cluster
         * @summary Pause a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pauseCluster(orgId: string, clusterId: string, options?: any) {
            return ClustersApiFp(configuration).pauseCluster(orgId, clusterId, options)(fetch, basePath);
        },
        /**
         * restart the Determined master process with a new configuration
         * @summary Reconfigure a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {ModelMasterConfig} config cluster master configuration
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reconfigureCluster(orgId: string, clusterId: string, config: ModelMasterConfig, options?: any) {
            return ClustersApiFp(configuration).reconfigureCluster(orgId, clusterId, config, options)(fetch, basePath);
        },
        /**
         * terminate and reprovision the Determined master instance with modified parameters
         * @summary Reprovision a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {ModelReprovisionClusterRequest} instanceType Master instance type
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        reprovisionCluster(orgId: string, clusterId: string, instanceType: ModelReprovisionClusterRequest, options?: any) {
            return ClustersApiFp(configuration).reprovisionCluster(orgId, clusterId, instanceType, options)(fetch, basePath);
        },
        /**
         * Replace an unrecoverable cluster DB with a new DB from backup
         * @summary Restore the cluster DB from backup
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {string} restoreToTime time to restore to in ISO format
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restoreClusterDb(orgId: string, clusterId: string, restoreToTime: string, options?: any) {
            return ClustersApiFp(configuration).restoreClusterDb(orgId, clusterId, restoreToTime, options)(fetch, basePath);
        },
        /**
         * restart minimal compute resources of a paused cluster
         * @summary Resume a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        resumeCluster(orgId: string, clusterId: string, options?: any) {
            return ClustersApiFp(configuration).resumeCluster(orgId, clusterId, options)(fetch, basePath);
        },
        /**
         * restart the Determined master process with a new Determined version
         * @summary Upgrade a cluster
         * @param {string} orgId organization ID
         * @param {string} clusterId cluster ID
         * @param {ModelUpgradeClusterRequest} version Determined version
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upgradeCluster(orgId: string, clusterId: string, version: ModelUpgradeClusterRequest, options?: any) {
            return ClustersApiFp(configuration).upgradeCluster(orgId, clusterId, version, options)(fetch, basePath);
        },
    };
};

/**
 * ClustersApi - object-oriented interface
 * @export
 * @class ClustersApi
 * @extends {BaseAPI}
 */
export class ClustersApi extends BaseAPI {
    /**
     * create a cluster
     * @summary Create a cluster
     * @param {string} orgId organization ID
     * @param {ModelCreateClusterRequest} cluster initial cluster options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public createCluster(orgId: string, cluster: ModelCreateClusterRequest, options?: any) {
        return ClustersApiFp(this.configuration).createCluster(orgId, cluster, options)(this.fetch, this.basePath);
    }

    /**
     * permanently delete the compute and storage resources of a cluster
     * @summary Delete a cluster
     * @param {string} orgId organization ID
     * @param {string} clusterId cluster ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public deleteCluster(orgId: string, clusterId: string, options?: any) {
        return ClustersApiFp(this.configuration).deleteCluster(orgId, clusterId, options)(this.fetch, this.basePath);
    }

    /**
     * write cluster metadata fields (empty fields are treated as unchanged)
     * @summary Edit cluster metadata
     * @param {string} orgId organization ID
     * @param {string} clusterId cluster ID
     * @param {ModelEditClusterRequest} cluster initial cluster options
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public editCluster(orgId: string, clusterId: string, cluster: ModelEditClusterRequest, options?: any) {
        return ClustersApiFp(this.configuration).editCluster(orgId, clusterId, cluster, options)(this.fetch, this.basePath);
    }

    /**
     * get cluster
     * @summary Get cluster
     * @param {string} orgId organization ID
     * @param {string} clusterId cluster ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getCluster(orgId: string, clusterId: string, options?: any) {
        return ClustersApiFp(this.configuration).getCluster(orgId, clusterId, options)(this.fetch, this.basePath);
    }

    /**
     * list the lifecycle actions and configuration operations that have been applied
     * @summary List the actions taken on the cluster
     * @param {string} orgId organization ID
     * @param {string} clusterId cluster ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public getClusterActions(orgId: string, clusterId: string, options?: any) {
        return ClustersApiFp(this.configuration).getClusterActions(orgId, clusterId, options)(this.fetch, this.basePath);
    }

    /**
     * list clusters
     * @summary List clusters
     * @param {string} orgId organization ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public listClusters(orgId: string, options?: any) {
        return ClustersApiFp(this.configuration).listClusters(orgId, options)(this.fetch, this.basePath);
    }

    /**
     * shut down all compute resources of a running cluster
     * @summary Pause a cluster
     * @param {string} orgId organization ID
     * @param {string} clusterId cluster ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public pauseCluster(orgId: string, clusterId: string, options?: any) {
        return ClustersApiFp(this.configuration).pauseCluster(orgId, clusterId, options)(this.fetch, this.basePath);
    }

    /**
     * restart the Determined master process with a new configuration
     * @summary Reconfigure a cluster
     * @param {string} orgId organization ID
     * @param {string} clusterId cluster ID
     * @param {ModelMasterConfig} config cluster master configuration
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public reconfigureCluster(orgId: string, clusterId: string, config: ModelMasterConfig, options?: any) {
        return ClustersApiFp(this.configuration).reconfigureCluster(orgId, clusterId, config, options)(this.fetch, this.basePath);
    }

    /**
     * terminate and reprovision the Determined master instance with modified parameters
     * @summary Reprovision a cluster
     * @param {string} orgId organization ID
     * @param {string} clusterId cluster ID
     * @param {ModelReprovisionClusterRequest} instanceType Master instance type
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public reprovisionCluster(orgId: string, clusterId: string, instanceType: ModelReprovisionClusterRequest, options?: any) {
        return ClustersApiFp(this.configuration).reprovisionCluster(orgId, clusterId, instanceType, options)(this.fetch, this.basePath);
    }

    /**
     * Replace an unrecoverable cluster DB with a new DB from backup
     * @summary Restore the cluster DB from backup
     * @param {string} orgId organization ID
     * @param {string} clusterId cluster ID
     * @param {string} restoreToTime time to restore to in ISO format
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public restoreClusterDb(orgId: string, clusterId: string, restoreToTime: string, options?: any) {
        return ClustersApiFp(this.configuration).restoreClusterDb(orgId, clusterId, restoreToTime, options)(this.fetch, this.basePath);
    }

    /**
     * restart minimal compute resources of a paused cluster
     * @summary Resume a cluster
     * @param {string} orgId organization ID
     * @param {string} clusterId cluster ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public resumeCluster(orgId: string, clusterId: string, options?: any) {
        return ClustersApiFp(this.configuration).resumeCluster(orgId, clusterId, options)(this.fetch, this.basePath);
    }

    /**
     * restart the Determined master process with a new Determined version
     * @summary Upgrade a cluster
     * @param {string} orgId organization ID
     * @param {string} clusterId cluster ID
     * @param {ModelUpgradeClusterRequest} version Determined version
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ClustersApi
     */
    public upgradeCluster(orgId: string, clusterId: string, version: ModelUpgradeClusterRequest, options?: any) {
        return ClustersApiFp(this.configuration).upgradeCluster(orgId, clusterId, version, options)(this.fetch, this.basePath);
    }

}

