import { Form, FormInstance, Input, ModalFuncProps } from 'antd';
import { Modal } from 'antd';
import React, { useRef } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { useFetchOrgs } from 'hooks/useFetch';
import { createOrg } from 'services/api';
import { Eventually } from 'shared/types';
import handleError, { ErrorLevel, wrapPublicMessage } from 'utils/error';

interface Props {
  close: () => Eventually<void>;
  isVisible: boolean;
}

interface FormValues {
  name: string;
}

/**
 * A modal that gives user option to create a new
 * organization by providing a name through a form
 * and confirming.
 */
const CreateNewOrgModal: React.FC<Props> = ({ isVisible, close }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<unknown | null>(null);
  const [name, setName] = useState('');
  const [canceler] = useState(new AbortController());
  const fetchOrgs = useFetchOrgs(canceler);
  const formRef = useRef<FormInstance<FormValues>>(null);

  const createNewOrg = useCallback(
    async (aName: string) => {
      setIsLoading(true);
      try {
        await createOrg({ name: aName }, { signal: canceler.signal });
        await fetchOrgs();
        setIsLoading(false);
        setError(null);
      } catch (error) {
        setIsLoading(false);
        setError(error);
        handleError(error, {
          level: ErrorLevel.Error,
          publicMessage: wrapPublicMessage(error, 'Failed to create organization'),
        });
      }
    },
    [canceler, fetchOrgs],
  );

  // signal cancellation on unmount
  useEffect(() => {
    return () => {
      canceler.abort();
    };
  }, [canceler]);

  const readFormInput = useCallback(() => {
    if (!formRef.current) {
      handleError(undefined, {
        isUserTriggered: false,
        level: ErrorLevel.Error,
        publicMessage: 'Form is not initialized',
      });
      return;
    }
    const inputs = formRef.current.getFieldsValue();
    setName(inputs.name);
    return inputs;
  }, []);

  const onOk = useCallback(async () => {
    const inputs = readFormInput();
    if (!inputs) return;
    await createNewOrg(inputs.name);
    await close();
  }, [close, createNewOrg, readFormInput]);

  useEffect(() => {
    if (error) {
      setIsLoading(false);
    }
  }, [error]);

  const modalProps = useMemo<ModalFuncProps>(
    () => ({
      okButtonProps: {
        disabled: isLoading || !name,
        loading: isLoading,
      },
      okText: 'Create',
      onCancel: async () => {
        setError(null);
        await close();
      },
      onOk,
      title: 'Create New Organization',
      visible: isVisible,
    }),
    [isLoading, name, close, onOk, isVisible],
  );

  return (
    <Modal {...modalProps}>
      <div>
        <Form<FormValues> labelCol={{ span: 6 }} ref={formRef}>
          <Form.Item extra="Human-readable organization name" label="Name" name="name">
            <Input
              autoComplete="off"
              min={1}
              required
              type="text"
              onChange={readFormInput}
              onPressEnter={onOk}
            />
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
};

export default CreateNewOrgModal;
