import * as RegionalApi from 'services/regional-bindings';

/**
 * TODO(DET-7323): stopgap for a backend-defined enum
 * @description valid access levels for an org user.
 */
export enum AccessLevel {
  None = 'none',
  User = 'user',
  Admin = 'admin',
}
/**
 * ordered list of access levels.
 */
export const accessLevels = [AccessLevel.Admin, AccessLevel.User, AccessLevel.None];

export type ClusterID = string;
export type OrgID = string;
/**
 * OrgRoles from decoded JWT. Maps orgId to OrgRole.
 */
export type Roles = Record<OrgID, OrgRole>;
export interface OrgRole {
  /** maps cluster id to access level */
  clusterRoles: Record<ClusterID, AccessLevel>;
  defaultClusterRole: AccessLevel;
  /** saas role */
  role: AccessLevel;
}

/**
 * TODO(DET-7323): stopgap for a backend-defined enum
 * @description cluster states.
 * https://github.com/determined-ai/saas/blob/59b9005f332b2b7e7089fd47bee34cdf77841f75
 * /common/model/model.go#L196-L210
 * 	// Transitional cluster states
 * CREATING       ClusterState = "creating"
 * DELETING       ClusterState = "deleting"
 * PAUSING        ClusterState = "pausing"
 * RESUMING       ClusterState = "resuming"
 * RECONFIGURING  ClusterState = "reconfiguring"
 * UPGRADING      ClusterState = "upgrading"
 * REPROVISIONING ClusterState = "reprovisioning"
 * RESTORE_DB     ClusterState = "restoring database"
 * RENEWING_TLS   ClusterState = "renewing TLS certificate"
 *
 *	 // Failed cluster states
 * CREATE_FAILED      ClusterState = "creating failed"
 * DELETE_FAILED      ClusterState = "deleting failed"
 * PAUSE_FAILED       ClusterState = "pausing failed"
 * RESUME_FAILED      ClusterState = "resuming failed"
 * RECONFIGURE_FAILED ClusterState = "reconfiguring failed"
 * UPGRADE_FAILED     ClusterState = "upgrading failed"
 * REPROVISION_FAILED ClusterState = "reprovisioning failed"
 * RESTORE_DB_FAILED  ClusterState = "restoring database failed"
 * RENEW_TLS_FAILED   ClusterState = "renewing tls failed"

 *  // Legacy state
 * ACTION_FAILED ClusterState = "action failed"
 */
export enum ClusterState {
  // Transitional cluster states
  Creating = 'creating',
  Deleting = 'deleting',
  Pausing = 'pausing',
  Resuming = 'resuming',
  Reconfiguring = 'reconfiguring',
  Upgrading = 'upgrading',
  Reprovisioning = 'reprovisioning',
  RestoringDb = 'restoring database',
  RenewingTls = 'renewing TLS certificate',
  // Stable cluster states,
  Running = 'running',
  Paused = 'paused',
  Deleted = 'deleted',
  // Failed cluster states,
  CreateFailed = 'creating failed',
  DeleteFailed = 'deleting failed',
  PauseFailed = 'pausing failed',
  ResumeFailed = 'resuming failed',
  ReconfigureFailed = 'reconfiguring failed',
  UpgradeFailed = 'upgrading failed',
  ReprovisionFailed = 'reprovisioning failed',
  RestoreDbFailed = 'restoring database failed',
  RenewTlsFailed = 'renewing tls failed',
  // Legacy cluster state,
  ActionFailed = 'action failed',
}
export const transitionalClusterState = new Set([
  ClusterState.Creating,
  ClusterState.Deleting,
  ClusterState.Pausing,
  ClusterState.Resuming,
  ClusterState.Reconfiguring,
  ClusterState.Upgrading,
  ClusterState.Reprovisioning,
  ClusterState.RestoringDb,
  ClusterState.RenewingTls,
]);

export const stableClusterState = new Set([
  ClusterState.Running,
  ClusterState.Paused,
  ClusterState.Deleted,
  ClusterState.CreateFailed,
  ClusterState.DeleteFailed,
  ClusterState.PauseFailed,
  ClusterState.ResumeFailed,
  ClusterState.ReconfigureFailed,
  ClusterState.UpgradeFailed,
  ClusterState.ReprovisionFailed,
  ClusterState.RestoreDbFailed,
  ClusterState.RenewTlsFailed,
  ClusterState.ActionFailed,
]);

export const upgradeableStates = new Set([ClusterState.Running]);

/**
 * Available options for generating a cluster
 * when using Basic config.
 */
export enum BasicConfigOption {
  Standard = 'standard',
  Pro = 'pro',
}

/**
 * Overall settings (not specific to resource pools)
 * when using Advanced config to create a cluster.
 */
export interface OverallConfig {
  detVersion: string;
  masterInstanceType: string;
}

interface Trackable {
  /** a unique key to track the object by for fast updates and rendering. */
  key: string;
}

/**
 * Settings for a resource pool
 * when using Advanced config to create a cluster.
 * A convenience wrapper around ModelResourcePoolConfig.
 */
export interface PoolConfig extends Trackable {
  cpuSlotsAllowed: boolean;
  instanceType: string;
  maxInstances: number;
  poolName: string;
  primaryUse: ResourcePoolUse;
}

/**
 * Available options for the primary use of a resource pool.
 */
export enum ResourcePoolUse {
  Aux = 'aux',
  Compute = 'compute',
}

export interface ClusterDetails extends Omit<RegionalApi.ModelClusterDetails, 'masterConfig'> {
  masterConfig: Omit<RegionalApi.ModelMasterConfig, 'resource_pools'> & {
    resource_pools: PoolConfig[];
  };
}

export enum DefaultResourcePool {
  aux = 'default_aux_resource_pool',
  compute = 'default_compute_resource_pool',
}
