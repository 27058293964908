import React, { Dispatch, useContext, useReducer } from 'react';

import { globalStorage } from 'globalStorage';
import { globalServerAddress } from 'routes/utils';
import { Roles } from 'saasTypes';
import { removeBearerToken, updateBearerToken } from 'services/apiConfig';
import * as GlobalApi from 'services/global-bindings';
import { StoreProvider as UIStoreProvider } from 'shared/contexts/stores/UI';
import { ApiState } from 'shared/types';
import { clone, isEqual } from 'shared/utils/data';
import { Auth, BrandingType, DeterminedInfo } from 'types';
import { DetError } from 'utils/error';
import { decodeSaasJwt } from 'utils/saas';

interface Props {
  children?: React.ReactNode;
}

export interface State {
  auth: Auth & {
    checked: boolean;
    roles: Roles | undefined;
  };
  info: DeterminedInfo;
  // This probably should be using ApiState, but we're putting that off.
  orgState: {
    orgs: GlobalApi.ModelListOrgsEntry[];
    /** selected org */
    selectedOrg?: GlobalApi.ModelListOrgsEntry;
  } & Pick<ApiState<GlobalApi.ModelListOrgsEntry[]>, 'hasBeenInitialized'>;
  supportMatrix?: GlobalApi.ModelGetSupportMatrixResponse;
}

export enum StoreAction {
  Reset = 'Reset',

  // Auth
  ResetAuth = 'ResetAuth',
  ResetAuthCheck = 'ResetAuthCheck',
  SetAuth = 'SetAuth',
  SetUnauthenticated = 'SetUnauthenticated',
  SetAuthCheck = 'SetAuthCheck',

  // Info
  SetInfo = 'SetInfo',
  SetInfoCheck = 'SetInfoCheck',

  // Org
  SetOrgs = 'SetOrgs',
  /** Set to the selected organization. */
  SetActiveOrg = 'SetActiveOrg',

  /** Set support matrix for active organization */
  SetSupportMatrix = 'SetSupportMatrix',
}

export type Action =
  | { type: StoreAction.Reset }
  | { type: StoreAction.ResetAuth }
  | { type: StoreAction.ResetAuthCheck }
  | { type: StoreAction.SetAuth; value: Omit<Auth, 'user>'> }
  | { type: StoreAction.SetUnauthenticated }
  | { type: StoreAction.SetAuthCheck }
  | { type: StoreAction.SetInfo; value: DeterminedInfo }
  | { type: StoreAction.SetInfoCheck }
  | { type: StoreAction.SetOrgs; value: GlobalApi.ModelListOrgsEntry[] }
  | { type: StoreAction.SetActiveOrg; value: string }
  | { type: StoreAction.SetSupportMatrix; value: GlobalApi.ModelGetSupportMatrixResponse };

const initAuth = {
  checked: false,
  isAuthenticated: false,
  roles: undefined,
};
const initInfo: DeterminedInfo = {
  branding: BrandingType.Determined,
  checked: false,
  clusterId: '',
  clusterName: '',
  externalLoginUri: globalServerAddress('/login'),
  externalLogoutUri: globalServerAddress('/logout'),
  isTelemetryEnabled: false,
  masterId: '',
  version: process.env.VERSION || '',
};

const initState: State = {
  auth: initAuth,
  info: initInfo,
  orgState: {
    hasBeenInitialized: false,
    orgs: [],
    selectedOrg: undefined,
  },
  supportMatrix: undefined,
};

const StateContext = React.createContext<State | undefined>(undefined);
const DispatchContext = React.createContext<Dispatch<Action> | undefined>(undefined);

const setSelectedOrg = (state: State, orgId: string): State => {
  if (orgId === undefined || orgId === '') {
    return { ...state, orgState: { ...state.orgState, selectedOrg: undefined } };
  }
  const selection = state.orgState.orgs.find((org) => org.id === orgId);
  if (!selection) {
    throw new DetError(undefined, { publicMessage: `Org ID ${orgId} not found` });
  }
  globalStorage.selectedOrgId = orgId;
  return { ...state, orgState: { ...state.orgState, selectedOrg: selection } };
};

const reducer = (state: State, action: Action): State => {
  switch (action.type) {
    case StoreAction.Reset:
      return clone(initState) as State;
    case StoreAction.ResetAuth:
      removeBearerToken();
      return { ...state, auth: { ...initAuth } };
    case StoreAction.ResetAuthCheck:
      if (!state.auth.checked) return state;
      return { ...state, auth: { ...state.auth, checked: false } };
    case StoreAction.SetAuth:
      if (action.value.token) {
        updateBearerToken(action.value.token);
        const decodedToken = decodeSaasJwt(action.value.token);
        return {
          ...state,
          auth: {
            ...action.value,
            checked: true,
            roles: decodedToken.roles,
            user: {
              email: decodedToken.email,
              name: decodedToken.name,
              userID: decodedToken.userID,
            },
          },
        };
      }
      return { ...state, auth: { ...action.value, checked: true, roles: undefined } };
    case StoreAction.SetUnauthenticated:
      removeBearerToken();
      return { ...state, auth: { ...initAuth, checked: true } };
    case StoreAction.SetAuthCheck:
      if (state.auth.checked) return state;
      return { ...state, auth: { ...state.auth, checked: true } };
    case StoreAction.SetInfo:
      if (isEqual(state.info, action.value)) return state;
      return { ...state, info: action.value };
    case StoreAction.SetInfoCheck:
      return { ...state, info: { ...state.info, checked: true } };
    case StoreAction.SetOrgs:
      if (isEqual(state.orgState.orgs, action.value))
        return {
          ...state,
          orgState: { ...state.orgState, hasBeenInitialized: true },
        };

      // reset selected org when no orgs are available
      if (action.value.length === 0) {
        return {
          ...state,
          orgState: {
            ...clone(initState.orgState),
            hasBeenInitialized: true,
          },
        };
      }

      // if the selectedOrg doesn't exist in the new list, reset it
      if (!action.value.some((org) => isEqual(org, state.orgState.selectedOrg))) {
        let newSelectedId = globalStorage.selectedOrgId;
        if (!action.value.some((org) => org.id === newSelectedId)) {
          newSelectedId = action.value[0].id;
        }
        return setSelectedOrg(
          {
            ...state,
            orgState: {
              ...state.orgState,
              hasBeenInitialized: true,
              orgs: action.value,
            },
          },
          newSelectedId,
        );
      }

      return {
        ...state,
        orgState: {
          ...state.orgState,
          hasBeenInitialized: true,
          orgs: action.value,
        },
      };
    case StoreAction.SetActiveOrg:
      return setSelectedOrg(state, action.value);
    case StoreAction.SetSupportMatrix:
      return {
        ...state,
        supportMatrix: action.value,
      };
    default:
      return state;
  }
};

export const useStore = (): State => {
  const context = useContext(StateContext);
  if (context === undefined) {
    throw new Error('useStore must be used within a StoreProvider');
  }
  return context;
};

export const useStoreDispatch = (): Dispatch<Action> => {
  const context = useContext(DispatchContext);
  if (context === undefined) {
    throw new Error('useStoreDispatch must be used within a StoreProvider');
  }
  return context;
};

const StoreProvider: React.FC<Props> = ({ children }: Props) => {
  const [state, dispatch] = useReducer(reducer, initState);
  return (
    <StateContext.Provider value={state}>
      <DispatchContext.Provider value={dispatch}>{children}</DispatchContext.Provider>
    </StateContext.Provider>
  );
};

/** a set of app level store providers */
const StackedStoreProvider: React.FC<Props> = ({ children }: Props) => {
  return (
    <StoreProvider>
      <UIStoreProvider>{children}</UIStoreProvider>
    </StoreProvider>
  );
};

export default StackedStoreProvider;
