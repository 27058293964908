import { ResourcePoolUse } from 'saasTypes';

export const clusterDefaults = {
  AUX_INSTANCE_TYPE: 'c5.large',
  // TODO(DET-7323): to be defined as an enum.
  AWS: 'aws',
  COMPUTE_INSTANCE_TYPE: 'g4dn.xlarge',
  COMPUTE_INSTANCE_TYPE_PRO: 'p3.8xlarge',
  CPU_POOL_NAME: 'cpu_pool',
  CPU_SLOTS_ALLOWED: false,
  GPU_POOL_NAME: 'gpu_pool',
  MASTER_INSTANCE_TYPE_PRO: 'm5.large',
  MASTER_INSTANCE_TYPE_STANDARD: 'm5.large',
  MAX_AUX_INSTANCES: 1,
  MAX_COMPUTE_INSTANCES: 2,
  RP_PROVIDER_TYPE: 'aws',
};

export const defaultResourcePoolUse = ResourcePoolUse.Aux;
