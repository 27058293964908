import { Card, Divider, Form, FormInstance, Input, InputNumber, Radio, Select } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import Link from 'components/Link';
import { clusterDefaults, defaultResourcePoolUse } from 'constants/defaults';
import { DefaultResourcePool, PoolConfig, ResourcePoolUse } from 'saasTypes';
import { ModelAgentResourceManagerConfig } from 'services/regional-bindings';
import { generateUUID } from 'shared/utils/string';

import InstanceTypeDropdown, { InstanceUse } from './InstanceTypeDropdown';
import css from './ResourcePools.module.scss';

const { Option } = Select;

export const getEmptyPoolConfig = (): PoolConfig => {
  return {
    cpuSlotsAllowed: clusterDefaults.CPU_SLOTS_ALLOWED,
    instanceType: clusterDefaults.AUX_INSTANCE_TYPE,
    // add a unique id for each pool so we/react can track changes.
    key: generateUUID(),
    maxInstances: clusterDefaults.MAX_AUX_INSTANCES,
    poolName: '',
    primaryUse: defaultResourcePoolUse,
  };
};

interface PoolConfigCardProps {
  clusterRegion: string;
  idx: number;
  onRemove: (key: string) => void;
  onUpdate: (key: string, form: FormInstance) => void;
  onUpdateResourceManager: (keys: DefaultResourcePool[], value?: string) => void;
  poolConfig: PoolConfig;
  resourceManager: ModelAgentResourceManagerConfig;
}
const PoolConfigCard: React.FC<PoolConfigCardProps> = ({
  clusterRegion,
  poolConfig,
  idx,
  onRemove,
  onUpdate,
  onUpdateResourceManager,
  resourceManager,
}: PoolConfigCardProps) => {
  const [form] = Form.useForm();
  const [hideDropdown, setHideDropdown] = useState(true);

  const onShowDropdown = useCallback(() => {
    setHideDropdown(false);
  }, []);

  useEffect(() => {
    // validate to show that pool name is required when empty poolConfig is added:
    form.validateFields();
  }, [poolConfig, form]);

  const onPrimaryUseChange = useCallback(
    (e) => {
      const instanceTypeValue =
        e.target.value === ResourcePoolUse.Compute
          ? clusterDefaults.COMPUTE_INSTANCE_TYPE
          : clusterDefaults.AUX_INSTANCE_TYPE;
      form.setFieldsValue({ instanceType: instanceTypeValue });
      onUpdate(poolConfig.key, form);
    },
    [form, onUpdate, poolConfig],
  );

  return (
    <Card
      className={css.card}
      extra={
        <Link
          onClick={() => {
            if (resourceManager.default_aux_resource_pool === poolConfig.poolName) {
              onUpdateResourceManager([DefaultResourcePool.aux]);
            }
            if (resourceManager.default_compute_resource_pool === poolConfig.poolName) {
              onUpdateResourceManager([DefaultResourcePool.compute]);
            }
            onRemove(poolConfig.key);
          }}>
          Remove
        </Link>
      }
      key={poolConfig.key}
      title={`Resource Pool ${idx + 1}`}>
      <Form<PoolConfig>
        form={form}
        initialValues={poolConfig}
        labelCol={{ span: 24 }}
        onValuesChange={() => onUpdate(poolConfig.key, form)}>
        <Form.Item
          label="Pool Name"
          name="poolName"
          rules={[{ message: 'Pool name required', required: true }]}>
          <Input
            onChange={(e) => {
              const keys = [];
              const value = e.target.value;
              if (resourceManager.default_aux_resource_pool === poolConfig.poolName) {
                keys.push(DefaultResourcePool.aux);
              }
              if (resourceManager.default_compute_resource_pool === poolConfig.poolName) {
                keys.push(DefaultResourcePool.compute);
              }
              if (keys.length) onUpdateResourceManager(keys, value);
            }}
          />
        </Form.Item>
        <Form.Item label="Primary Use" name="primaryUse">
          <Radio.Group
            options={[
              { label: 'Auxiliary Tasks', value: ResourcePoolUse.Aux },
              { label: 'Compute Tasks', value: ResourcePoolUse.Compute },
            ]}
            optionType="button"
            onChange={onPrimaryUseChange}
          />
        </Form.Item>
        <Form.Item
          className={css.formItemWithLink}
          label={
            <>
              <span>Instance Type</span>
              <Link
                path={`https://aws.amazon.com/ec2/instance-types/${
                  poolConfig.primaryUse === ResourcePoolUse.Aux
                    ? '#Compute_Optimized'
                    : '#Accelerated_Computing'
                }`}
                popout={true}>
                List of Instance Types
              </Link>
            </>
          }
          name="instanceType">
          <InstanceTypeDropdown
            buttonLabel={poolConfig.instanceType}
            clusterRegion={clusterRegion}
            disableAutoDismiss={true}
            hide={hideDropdown}
            showArrow={false}
            use={
              poolConfig.primaryUse === ResourcePoolUse.Aux ? InstanceUse.Aux : InstanceUse.Compute
            }
            onSelect={(instanceType: string) => {
              form.setFieldsValue({ instanceType });
              onUpdate(poolConfig.key, form);
              setHideDropdown(true);
            }}
            onVisibleChange={onShowDropdown}
          />
        </Form.Item>
        <Form.Item label="Max Instances" name="maxInstances">
          <InputNumber min="1" />
        </Form.Item>
      </Form>
    </Card>
  );
};

interface Props {
  clusterRegion: string;
  onAddPoolConfig: () => void;
  onRemovePoolConfig: (key: string) => void;
  onUpdatePoolConfig: (key: string, form: FormInstance) => void;
  onUpdateResourceManager: (keys: DefaultResourcePool[], value?: string) => void;
  pools: PoolConfig[] | undefined;
  resourceManager: ModelAgentResourceManagerConfig;
}
const ResourcePoolCards: React.FC<Props> = ({
  clusterRegion,
  pools,
  onAddPoolConfig,
  onRemovePoolConfig,
  onUpdatePoolConfig,
  onUpdateResourceManager,
  resourceManager,
}: Props) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({
      defaultAuxPool: resourceManager.default_aux_resource_pool,
      defaultComputePool: resourceManager.default_compute_resource_pool,
    });
  }, [resourceManager, form]);

  return (
    <>
      <p className={css.sectionHeader}>
        <span>Resource Pools</span>
        <Link onClick={onAddPoolConfig}>Add</Link>
      </p>
      <Divider />
      <Form form={form} labelCol={{ span: 24 }}>
        <Form.Item label="Default Aux Pool" name="defaultAuxPool" required>
          <Select
            onChange={(value) => {
              onUpdateResourceManager([DefaultResourcePool.aux], value);
            }}>
            {pools?.map((p) => (
              <Option key={p.key} value={p.poolName}>
                {p.poolName}
              </Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label="Default Compute Pool" name="defaultComputePool" required>
          <Select
            onChange={(value) => {
              onUpdateResourceManager([DefaultResourcePool.compute], value);
            }}>
            {pools?.map((p) => (
              <Option key={p.key} value={p.poolName}>
                {p.poolName}
              </Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
      <div className={css.pools}>
        {pools?.map((poolConfig: PoolConfig, idx: number) => {
          return (
            <PoolConfigCard
              clusterRegion={clusterRegion}
              idx={idx}
              key={poolConfig.key}
              poolConfig={poolConfig}
              resourceManager={resourceManager}
              onRemove={onRemovePoolConfig}
              onUpdate={onUpdatePoolConfig}
              onUpdateResourceManager={onUpdateResourceManager}
            />
          );
        })}
      </div>
    </>
  );
};

export default ResourcePoolCards;
