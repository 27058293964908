import { Select } from 'antd';
import React, { useCallback } from 'react';

import { AccessLevel, accessLevels } from 'saasTypes';
import { Eventually } from 'shared/types';
import handleError, { wrapPublicMessage } from 'utils/error';

const { Option } = Select;

/** render a list of access levels as a Select */
const AccessLevelSelect: React.FC<{
  availableLevels?: AccessLevel[];
  defaultValue: AccessLevel;
  onChange: (value: AccessLevel) => Eventually<void>;
}> = ({ availableLevels, onChange, ...rest }) => {
  const wrappedOnChange = useCallback(
    async (value: AccessLevel) => {
      try {
        await onChange(value);
      } catch (e) {
        handleError(e, {
          publicMessage: wrapPublicMessage(e, 'Failed to update user access level'),
        });
        // TODO [DET-6907]: we either need to revert the change to Select on failure
        // or put it behind a loading state until we verify the changes took place.
      }
    },
    [onChange],
  );
  return (
    <Select {...rest} onChange={wrappedOnChange}>
      {(availableLevels || accessLevels).map((acl) => (
        <Option key={acl} value={acl}>
          {acl}
        </Option>
      ))}
    </Select>
  );
};

export default AccessLevelSelect;
