import jwt_decode from 'jwt-decode';

import { auxCategories, computeCategories } from 'components/InstanceTypeDropdown';
import { getEmptyPoolConfig } from 'components/ResourcePools';
import { clusterDefaults, defaultResourcePoolUse } from 'constants/defaults';
import { AccessLevel, Roles } from 'saasTypes';
import { ClusterState, PoolConfig, ResourcePoolUse } from 'saasTypes';
import {
  ModelAgentResourceManagerConfig,
  ModelMasterConfig,
  ModelResourcePoolConfig,
} from 'services/regional-bindings';
import { semVerIsOlder, sortVersions } from 'shared/utils/sort';
import { stringToVersion, versionToString } from 'shared/utils/string';
import { Eventually, SemanticVersion } from 'types';
import handleError, { DetError, ErrorLevel } from 'utils/error';

/** Determine if the user can manage a cluster's users */
export const isAdmin = (userRoles: Roles, orgId: string, clusterId: string): boolean => {
  const orgRoles = userRoles[orgId];
  const defaultClusterRole = orgRoles.defaultClusterRole;
  const role = orgRoles.role;
  const clusterRole = orgRoles.clusterRoles[clusterId];
  return (
    defaultClusterRole === AccessLevel.Admin ||
    role === AccessLevel.Admin ||
    clusterRole === AccessLevel.Admin
  );
};

/** generate a list of suitable versions higher than the current version */
export const suitableVersions = (available: string[], curVer: string): SemanticVersion[] => {
  return available
    .map((v) => stringToVersion(v))
    .filter((v) => semVerIsOlder(stringToVersion(curVer), v))
    .sort((a, b) => (semVerIsOlder(a, b) ? 1 : -1));
};

export const getLatestVersion = (versions: string[]): string | undefined => {
  if (versions.length === 0) return undefined;
  const sorted = sortVersions(versions.map((v) => stringToVersion(v)));
  return versionToString(sorted[0]);
};

export const generateMasterConfig = (
  poolConfigs: PoolConfig[],
  resourceManager: ModelAgentResourceManagerConfig,
  validate?: boolean,
): ModelMasterConfig => {
  // CHECK this probably can take in pool names for default aux
  // and compute pools
  const resourcePools = poolConfigs.map((p) => encodePoolConfig(p, validate));

  const error: DetError = new DetError(undefined, { publicSubject: 'Invalid master config' });

  if (poolConfigs.length === 0) {
    error.publicMessage = 'At least one pool required';
    throw handleError(error);
  }

  if (!resourceManager.default_aux_resource_pool) {
    throw handleError(undefined, {
      level: ErrorLevel.Error,
      publicSubject: 'Default aux pool required',
      silent: false,
    });
  }
  if (!resourceManager.default_compute_resource_pool) {
    throw handleError(undefined, {
      level: ErrorLevel.Error,
      publicSubject: 'Default compute pool required',
      silent: false,
    });
  }

  return {
    resource_manager: resourceManager,
    resource_pools: resourcePools,
  };
};

export const decodeSaasJwt = (
  token: string,
): { email: string; name: string; roles: Roles; userID: string } => {
  const jwt = jwt_decode(token) as {
    Email: string;
    Name: string;
    OrgRoles: {
      [orgId: string]: {
        ClusterRoles: Record<string, AccessLevel>;
        DefaultClusterRole: string;
        Role: string;
      };
    };
    UserID: string;
  };
  const roles: Roles = {};
  Object.keys(jwt.OrgRoles).forEach((orgId) => {
    roles[orgId] = {
      clusterRoles: jwt.OrgRoles[orgId].ClusterRoles,
      defaultClusterRole: jwt.OrgRoles[orgId].DefaultClusterRole as AccessLevel,
      role: jwt.OrgRoles[orgId].Role as AccessLevel,
    };
  });
  return {
    email: jwt.Email,
    name: jwt.Name,
    roles,
    userID: jwt.UserID,
  };
};

type ConfigValidationError = {
  key: string;
  reason: string;
};

const validatePoolConfig = (pool: Partial<PoolConfig>): ConfigValidationError[] => {
  const issues: ConfigValidationError[] = [];
  if (!pool.poolName) {
    issues.push({
      key: 'poolName',
      reason: 'Pool name required',
    });
  }
  return issues;
};

/**
 * translate keys for standard ts access.
 * @param config
 */
export const decodePoolConfig = (p: ModelResourcePoolConfig): PoolConfig => {
  const getUseByInstanceType = (instanceType: string): ResourcePoolUse => {
    if (computeCategories.includes(instanceType.split('.')[0].toLowerCase())) {
      return ResourcePoolUse.Compute;
    } else if (auxCategories.includes(instanceType.split('.')[0].toLowerCase())) {
      return ResourcePoolUse.Aux;
    } else {
      return defaultResourcePoolUse;
    }
  };

  return {
    cpuSlotsAllowed: p.provider.cpu_slots_allowed,
    instanceType: p.provider.instance_type,
    key: '',
    maxInstances: p.provider.max_instances,
    poolName: p.pool_name,
    primaryUse: getUseByInstanceType(p.provider.instance_type),
  };
};

/**
 * add in the defaults and translate key names.
 * @param config
 */
const encodePoolConfig = (pool: Partial<PoolConfig>, validate = true): ModelResourcePoolConfig => {
  if (validate && validatePoolConfig(pool).length) {
    throw handleError(undefined, {
      // TODO pass on the issues
      level: ErrorLevel.Error,
      publicSubject: 'Pool name required',
      silent: false,
    });
  }

  const p = {
    ...getEmptyPoolConfig(),
    ...pool,
  };
  return {
    max_aux_containers_per_agent: pool.primaryUse === ResourcePoolUse.Aux ? 100 : 0,
    pool_name: p.poolName,
    provider: {
      cpu_slots_allowed: p.cpuSlotsAllowed,
      instance_type: p.instanceType,
      max_instances: p.maxInstances,
      type: clusterDefaults.RP_PROVIDER_TYPE,
    },
  };
};

/* used temporarily to update our error management */
export const ignoreExceptions = (fn: () => Eventually<void>): (() => Eventually<void>) => {
  return () => {
    try {
      fn();
    } catch (e) {
      handleError(e);
      // ignore
    }
  };
};

export const disableClusterLinkByState = (state: ClusterState): boolean => {
  return state !== ClusterState.Running && state !== ClusterState.ActionFailed;
};
