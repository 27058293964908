import { ColumnType } from 'antd/es/table';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AccessLevelAdd from 'components/AccessLevel/AccessLevelAdd';
import AccessLevelDelete from 'components/AccessLevel/AccessLevelDelete';
import AccessLevelSelect from 'components/AccessLevel/AccessLevelSelect';
import ResponsiveTable from 'components/ResponsiveTable';
import { useStore } from 'contexts/Store';
import { AccessLevel } from 'saasTypes';
import { getClusterOverrides, getClusterUsers, updateClusterLevelRole } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import Spinner from 'shared/components/Spinner';
import useModal, { ModalHooks, ModalOpen } from 'shared/hooks/useModal/useModal';
import { isEqual } from 'shared/utils/data';
import { alphaNumericSorter } from 'shared/utils/sort';
import handleError from 'utils/error';
import { isAdmin } from 'utils/saas';

interface OnOpenProps {
  clusterId: string;
  orgId: string;
}

const ModalContent: React.FC<{ userCanModify?: boolean } & OnOpenProps> = ({
  orgId,
  clusterId,
  userCanModify,
}) => {
  const [apiData, setApiData] = useState<{
    overrides: GlobalApi.ModelClusterUser[] | null;
    users: GlobalApi.ModelClusterUser[] | null;
  }>({ overrides: null, users: null });
  const [canceler] = useState(new AbortController());

  const fetchData = useCallback(async () => {
    const usersPromise = getClusterUsers({ clusterId, orgId }, { signal: canceler.signal })
      .then((users) => {
        setApiData((cur) => ({ ...cur, users }));
      })
      .catch(handleError);
    const overridesPromise = getClusterOverrides({ orgId }, { signal: canceler.signal })
      .then((res) => {
        setApiData((cur) => ({ ...cur, overrides: res[clusterId] || [] }));
      })
      .catch(handleError);
    await Promise.all([usersPromise, overridesPromise]);
  }, [canceler.signal, orgId, clusterId]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  useEffect(() => {
    return () => {
      canceler.abort();
    };
  }, [canceler]);

  /**
   * Update the user's access level to a cluster
   */
  const updateUserClusterAcl = useCallback(
    async (userId: string, newAccess: AccessLevel) => {
      await updateClusterLevelRole({ clusterId, orgId, role: newAccess, userId });
      await fetchData();
    },
    [fetchData, clusterId, orgId],
  );

  const columns = useMemo(() => {
    const cols: ColumnType<GlobalApi.ModelClusterUser>[] = [
      {
        dataIndex: 'name',
        defaultSortOrder: 'ascend',
        key: 'name',
        sorter: (a, b) => alphaNumericSorter(a.name, b.name),
        title: 'Name',
      },
      {
        dataIndex: 'email',
        defaultSortOrder: 'ascend',
        key: 'email',
        sorter: (a, b) => alphaNumericSorter(a.email, b.email),
        title: 'Email',
      },
      {
        dataIndex: 'role',
        key: 'role',
        render: (_, record) => {
          if (!userCanModify) return record.role;
          return (
            <AccessLevelSelect
              availableLevels={[AccessLevel.Admin, AccessLevel.User]}
              defaultValue={record.role as AccessLevel}
              onChange={(level: AccessLevel) => updateUserClusterAcl(record.id, level)}
            />
          );
        },
        sorter: (a, b) => alphaNumericSorter(a.role, b.role),
        title: 'Cluster Role',
      },
      {
        dataIndex: 'delete',
        key: 'delete',
        render: (_, record) => {
          if (!userCanModify) return null;
          return <AccessLevelDelete id={record.id} onChange={updateUserClusterAcl} />;
        },
        title: '',
      },
    ];
    return cols;
  }, [updateUserClusterAcl, userCanModify]);

  const usersWithoutAccess = useMemo(() => {
    if (!apiData.users) return [];
    return apiData.users.filter((user) => user.role === AccessLevel.None);
  }, [apiData.users]);

  const isLoading = apiData.overrides === null || apiData.users === null;
  if (apiData.overrides === null || apiData.users === null)
    return <Spinner tip="Loading inital data..." />;

  return (
    <div>
      {userCanModify && (
        <AccessLevelAdd
          optionIdField="user"
          optionLabelField="user"
          options={usersWithoutAccess}
          optionType="member"
          onUpdate={updateUserClusterAcl}
        />
      )}
      <ResponsiveTable<GlobalApi.ModelClusterUser>
        columns={columns}
        dataSource={apiData.users.filter((u) => u.role !== AccessLevel.None)}
        loading={isLoading}
        pagination={{ hideOnSinglePage: true }}
        rowKey="user"
        showSorterTooltip={false}
      />
    </div>
  );
};

interface Props {
  onClose?: () => void;
}

/**
 * modal for setting user access levels for a cluster
 */
const useClusterUsers = ({ onClose }: Props): ModalHooks<OnOpenProps> => {
  const { modalOpen: openOrUpdate, ...modalHooks } = useModal<OnOpenProps>({ onClose });
  const [openProps, setOpenProps] = useState<OnOpenProps | undefined>();
  const {
    auth: { roles: userRoles },
  } = useStore();

  const modalOpen: ModalOpen<OnOpenProps> = useCallback(
    (initialModalProps = {}) => {
      const newOpenProps = initialModalProps.context;
      if (!!newOpenProps && !isEqual(openProps, newOpenProps)) setOpenProps(newOpenProps);

      const curOpenProps = newOpenProps || openProps;
      if (!curOpenProps) return; // cannot open modal without open props

      const userCanModify = !userRoles
        ? false
        : isAdmin(userRoles, curOpenProps.orgId, curOpenProps.clusterId);

      const modalProps = {
        cancelButtonProps: { hidden: true },
        content: (
          <ModalContent
            clusterId={curOpenProps.clusterId}
            orgId={curOpenProps.orgId}
            userCanModify={userCanModify}
          />
        ),
        icon: null,
        okText: 'Ok',
        title: `${userCanModify ? 'Manage' : 'View'} Cluster Members`,
        width: '50vw',
      };

      const combinedProps = { ...modalProps, ...initialModalProps };
      openOrUpdate(combinedProps);
    },
    [openOrUpdate, openProps, userRoles],
  );

  useEffect(() => {
    if (!openProps) return;
    modalOpen({ context: openProps });
  }, [modalOpen, openProps]);

  return { modalOpen, ...modalHooks };
};

export default useClusterUsers;
