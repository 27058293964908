import { PageHeader } from 'antd';
import React from 'react';

import logoDetCloudDark from 'assets/images/logo-det-cloud-dark.svg';
import logoDetCloudLight from 'assets/images/logo-det-cloud-light.svg';
import preview from 'assets/images/preview.svg';
import { useStore } from 'contexts/Store';
import useUI from 'shared/contexts/stores/UI';
import { DarkLight } from 'shared/themes';

import css from './NavigationHeader.module.scss';
import { OrgPickerStateful } from './OrgPicker';

const NavigationHeader: React.FC = () => {
  const { auth } = useStore();
  const { ui } = useUI();

  const showNavigation = auth.isAuthenticated && ui.showChrome;

  if (!showNavigation) return null;

  return (
    <PageHeader
      className={css.navigationHeader}
      extra={<OrgPickerStateful />}
      title={
        <>
          <img src={ui.darkLight === DarkLight.Dark ? logoDetCloudLight : logoDetCloudDark} />
          <img className={css.preview} src={preview} />
        </>
      }
    />
  );
};

export default NavigationHeader;
