import { ColumnsType } from 'antd/es/table';

export const auxCategories = ['c5'];
export const computeCategories = ['p2', 'p3', 'p4d', 'g4dn'];
export const masterCategories = ['m5'];

export const KEY_COLUMN_NAME = 'instance';

interface InstanceTypeInfoBasic {
  [KEY_COLUMN_NAME]: string;
}
interface InstanceTypeInfoCpu extends InstanceTypeInfoBasic {
  mem: number;
  network: string | number;
  storage: number | string;
  vcpu: number;
}
type InstanceTypeInfoCommon = Omit<InstanceTypeInfoCpu, 'storage'> &
  Partial<Pick<InstanceTypeInfoCpu, 'storage'>>;
interface InstanceTypeInfoGpu extends InstanceTypeInfoCommon {
  gpu: number;
  gpuMem: number;
  gpuP2p?: string;
}
export type InstanceTypeInfo = InstanceTypeInfoBasic | InstanceTypeInfoCpu | InstanceTypeInfoGpu;

interface InstanceTypeValuesByCategory {
  [categoryKey: string]: {
    columns: ColumnsType<InstanceTypeInfo>;
    description: string;
    values: {
      [size: string]: InstanceTypeInfo;
    };
  };
}

export const InstanceTypeValues: InstanceTypeValuesByCategory = {
  c5: {
    columns: [
      {
        dataIndex: KEY_COLUMN_NAME,
        title: KEY_COLUMN_NAME,
      },
      {
        dataIndex: 'vcpu',
        title: 'vCPU',
      },
      {
        dataIndex: 'mem',
        title: 'Mem (GiB)',
      },
      {
        dataIndex: 'storage',
        title: 'Storage (GB)',
      },
      {
        dataIndex: 'network',
        title: 'Network (Gbps)',
      },
    ],
    description: 'Optimized for compute-intensive workloads',
    values: {
      '2xlarge': {
        instance: '2xlarge',
        mem: 16,
        network: 'Up to 10',
        storage: 'EBS-Only',
        vcpu: 8,
      },
      '4xlarge': {
        instance: '4xlarge',
        mem: 32,
        network: 'Up to 10',
        storage: 'EBS-Only',
        vcpu: 16,
      },
      '9xlarge': {
        instance: '9xlarge',
        mem: 72,
        network: '10',
        storage: 'EBS-Only',
        vcpu: 36,
      },
      '12xlarge': {
        instance: '12xlarge',
        mem: 96,
        network: '12',
        storage: 'EBS-Only',
        vcpu: 48,
      },
      '18xlarge': {
        instance: '18xlarge',
        mem: 144,
        network: '25',
        storage: 'EBS-Only',
        vcpu: 72,
      },
      '24xlarge': {
        instance: '24xlarge',
        mem: 192,
        network: '25',
        storage: 'EBS-Only',
        vcpu: 96,
      },
      'large': {
        instance: 'large',
        mem: 4,
        network: 'Up to 10',
        storage: 'EBS-Only',
        vcpu: 2,
      },
      'xlarge': {
        instance: 'xlarge',
        mem: 8,
        network: 'Up to 10',
        storage: 'EBS-Only',
        vcpu: 4,
      },
    },
  },
  g4dn: {
    columns: [
      {
        dataIndex: KEY_COLUMN_NAME,
        title: KEY_COLUMN_NAME,
      },
      {
        dataIndex: 'gpu',
        title: 'GPUs',
      },
      {
        dataIndex: 'vcpu',
        title: 'vCPU',
      },
      {
        dataIndex: 'mem',
        title: 'Memory (GiB)',
      },
      {
        dataIndex: 'gpuMem',
        title: 'GPU Memory (GiB)',
      },
      {
        dataIndex: 'storage',
        title: 'Instance Storage (GB)',
      },
    ],
    description: 'Offering NVIDIA T4s, the most cost-effective cloud GPU option',
    values: {
      '2xlarge': {
        gpu: 1,
        gpuMem: 16,
        instance: '2xlarge',
        mem: 32,
        storage: '1 x 225 NVMe SSD',
        vcpu: 8,
      },
      '4xlarge': {
        gpu: 1,
        gpuMem: 16,
        instance: '4xlarge',
        mem: 64,
        storage: '1 x 225 NVMe SSD',
        vcpu: 16,
      },
      '8xlarge': {
        gpu: 1,
        gpuMem: 16,
        instance: '8xlarge',
        mem: 128,
        storage: '1 x 900 NVMe SSD',
        vcpu: 32,
      },
      '12xlarge': {
        gpu: 4,
        gpuMem: 64,
        instance: '12xlarge',
        mem: 192,
        storage: '1 x 900 NVMe SSD',
        vcpu: 48,
      },
      '16xlarge': {
        gpu: 1,
        gpuMem: 16,
        instance: '16xlarge',
        mem: 256,
        storage: '1 x 900 NVMe SSD',
        vcpu: 64,
      },
      'metal': {
        gpu: 8,
        gpuMem: 128,
        instance: 'metal',
        mem: 384,
        storage: '2 x 900 NVMe SSD',
        vcpu: 96,
      },
      'xlarge': {
        gpu: 1,
        gpuMem: 16,
        instance: 'xlarge',
        mem: 16,
        storage: '1 x 125 NVMe SSD',
        vcpu: 4,
      },
    },
  },
  m5: {
    columns: [
      {
        dataIndex: KEY_COLUMN_NAME,
        title: KEY_COLUMN_NAME,
      },
      {
        dataIndex: 'vcpu',
        title: 'vCPU',
      },
      {
        dataIndex: 'mem',
        title: 'Mem (GiB)',
      },
      {
        dataIndex: 'storage',
        title: 'Storage (GB)',
      },
      {
        dataIndex: 'network',
        title: 'Network (Gbps)',
      },
    ],
    description: 'Provides a balance of compute, memory, and network resources',
    values: {
      '2xlarge': {
        instance: '2xlarge',
        mem: 32,
        network: 'Up to 10',
        storage: 'EBS-Only',
        vcpu: 8,
      },
      '4xlarge': {
        instance: '4xlarge',
        mem: 64,
        network: 'Up to 10',
        storage: 'EBS-Only',
        vcpu: 16,
      },
      '8xlarge': {
        instance: '8xlarge',
        mem: 128,
        network: 10,
        storage: 'EBS-Only',
        vcpu: 32,
      },
      '12xlarge': {
        instance: '12xlarge',
        mem: 192,
        network: 12,
        storage: 'EBS-Only',
        vcpu: 48,
      },
      '16xlarge': {
        instance: '16xlarge',
        mem: 256,
        network: 20,
        storage: 'EBS-Only',
        vcpu: 64,
      },
      '24xlarge': {
        instance: '24xlarge',
        mem: 384,
        network: 25,
        storage: 'EBS-Only',
        vcpu: 96,
      },
      'large': {
        instance: 'large',
        mem: 8,
        network: 'Up to 10',
        storage: 'EBS-Only',
        vcpu: 2,
      },
      'xlarge': {
        instance: 'xlarge',
        mem: 16,
        network: 'Up to 10',
        storage: 'EBS-Only',
        vcpu: 4,
      },
    },
  },
  p2: {
    columns: [
      {
        dataIndex: KEY_COLUMN_NAME,
        title: KEY_COLUMN_NAME,
      },
      {
        dataIndex: 'gpu',
        title: 'GPU',
      },
      {
        dataIndex: 'vcpu',
        title: 'vCPU',
      },
      {
        dataIndex: 'mem',
        title: 'Mem (GiB)',
      },
      {
        dataIndex: 'gpuMem',
        title: 'GPU Memory (GiB)',
      },
      {
        dataIndex: 'network',
        title: 'Network (Gbps)',
      },
    ],
    description: 'Offering NVIDIA K80s, the lowest cost-per-hour GPU option',
    values: {
      '8xlarge': {
        gpu: 8,
        gpuMem: 96,
        instance: '8xlarge',
        mem: 488,
        network: 10,
        vcpu: 32,
      },
      '16xlarge': {
        gpu: 16,
        gpuMem: 192,
        instance: '16xlarge',
        mem: 732,
        network: 25,
        vcpu: 64,
      },
      'xlarge': {
        gpu: 1,
        gpuMem: 12,
        instance: 'xlarge',
        mem: 61,
        network: 'High',
        vcpu: 4,
      },
    },
  },
  p3: {
    columns: [
      {
        dataIndex: KEY_COLUMN_NAME,
        title: KEY_COLUMN_NAME,
      },
      {
        dataIndex: 'gpu',
        title: 'GPU',
      },
      {
        dataIndex: 'vcpu',
        title: 'vCPU',
      },
      {
        dataIndex: 'mem',
        title: 'Mem (GiB)',
      },
      {
        dataIndex: 'gpuMem',
        title: 'GPU Memory (GiB)',
      },
      {
        dataIndex: 'gpuP2p',
        title: 'GPU P2P',
      },
      {
        dataIndex: 'storage',
        title: 'Storage (GB)',
      },
      {
        dataIndex: 'network',
        title: 'Network (Gbps)',
      },
    ],
    description: 'Offering NVIDIA V100s, a higher performance GPU option',
    values: {
      '2xlarge': {
        gpu: 1,
        gpuMem: 16,
        gpuP2p: '-',
        instance: '2xlarge',
        mem: 61,
        network: 'Up to 10',
        storage: 'EBS-Only',
        vcpu: 8,
      },
      '8xlarge': {
        gpu: 4,
        gpuMem: 64,
        gpuP2p: 'NVLink',
        instance: '8xlarge',
        mem: 244,
        network: 10,
        storage: 'EBS-Only',
        vcpu: 32,
      },
      '16xlarge': {
        gpu: 8,
        gpuMem: 128,
        gpuP2p: 'NVLink',
        instance: '16xlarge',
        mem: 488,
        network: 25,
        storage: 'EBS-Only',
        vcpu: 64,
      },
    },
  },
  p4d: {
    columns: [
      {
        dataIndex: KEY_COLUMN_NAME,
        title: KEY_COLUMN_NAME,
      },
      {
        dataIndex: 'gpu',
        title: 'GPUs',
      },
      {
        dataIndex: 'vcpu',
        title: 'vCPUs',
      },
      {
        dataIndex: 'mem',
        title: 'Instance Memory (GiB)',
      },
      {
        dataIndex: 'gpuMem',
        title: 'GPU Memory (GiB)',
      },
      {
        dataIndex: 'gpuP2p',
        title: 'GPU Peer to Peer',
      },
      {
        dataIndex: 'storage',
        title: 'Instance Storage (GB)',
      },
    ],
    description: 'Offering NVIDIA A100s, the highest-performance GPU option',
    values: {
      '24xlarge': {
        gpu: 8,
        gpuMem: 320,
        gpuP2p: '600 GB/s NVSwitch',
        instance: '24xlarge',
        mem: 1152,
        storage: '8 x 1000 NVMe SSD',
        vcpu: 96,
      },
    },
  },
};
