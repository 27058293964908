import { clusterDefaults } from 'constants/defaults';
import { PoolConfig, ResourcePoolUse } from 'saasTypes';
import { ModelCreateClusterRequest } from 'services/regional-bindings';
import { generateUUID } from 'shared/utils/string';

export const basicStandardClusterRequest: Omit<ModelCreateClusterRequest, 'detVersion'> = {
  masterConfig: {
    resource_manager: {
      default_aux_resource_pool: clusterDefaults.CPU_POOL_NAME,
      default_compute_resource_pool: clusterDefaults.GPU_POOL_NAME,
    },
    resource_pools: [
      {
        max_aux_containers_per_agent: 100,
        pool_name: clusterDefaults.CPU_POOL_NAME,
        provider: {
          cpu_slots_allowed: clusterDefaults.CPU_SLOTS_ALLOWED,
          instance_type: clusterDefaults.AUX_INSTANCE_TYPE,
          max_instances: clusterDefaults.MAX_AUX_INSTANCES,
          type: clusterDefaults.AWS,
        },
      },
      {
        max_aux_containers_per_agent: 0,
        pool_name: clusterDefaults.GPU_POOL_NAME,
        provider: {
          cpu_slots_allowed: clusterDefaults.CPU_SLOTS_ALLOWED,
          instance_type: clusterDefaults.COMPUTE_INSTANCE_TYPE,
          max_instances: clusterDefaults.MAX_COMPUTE_INSTANCES,
          type: clusterDefaults.AWS,
        },
      },
    ],
  },
  masterInstanceType: clusterDefaults.MASTER_INSTANCE_TYPE_STANDARD,
  name: '',
};

export const basicProClusterRequest: Omit<ModelCreateClusterRequest, 'detVersion'> = {
  masterConfig: {
    resource_manager: {
      default_aux_resource_pool: clusterDefaults.CPU_POOL_NAME,
      default_compute_resource_pool: clusterDefaults.GPU_POOL_NAME,
    },
    resource_pools: [
      {
        max_aux_containers_per_agent: 100,
        pool_name: clusterDefaults.CPU_POOL_NAME,
        provider: {
          cpu_slots_allowed: clusterDefaults.CPU_SLOTS_ALLOWED,
          instance_type: clusterDefaults.AUX_INSTANCE_TYPE,
          max_instances: clusterDefaults.MAX_AUX_INSTANCES,
          type: clusterDefaults.AWS,
        },
      },
      {
        max_aux_containers_per_agent: 0,
        pool_name: clusterDefaults.GPU_POOL_NAME,
        provider: {
          cpu_slots_allowed: clusterDefaults.CPU_SLOTS_ALLOWED,
          instance_type: clusterDefaults.COMPUTE_INSTANCE_TYPE_PRO,
          max_instances: clusterDefaults.MAX_COMPUTE_INSTANCES,
          type: clusterDefaults.AWS,
        },
      },
    ],
  },
  masterInstanceType: clusterDefaults.MASTER_INSTANCE_TYPE_PRO,
  name: '',
};

export const defaultPoolConfigs: PoolConfig[] = [
  {
    cpuSlotsAllowed: true,
    instanceType: clusterDefaults.AUX_INSTANCE_TYPE,
    key: generateUUID(),
    maxInstances: clusterDefaults.MAX_AUX_INSTANCES,
    poolName: clusterDefaults.CPU_POOL_NAME,
    primaryUse: ResourcePoolUse.Aux,
  },
  {
    cpuSlotsAllowed: false,
    instanceType: clusterDefaults.COMPUTE_INSTANCE_TYPE,
    key: generateUUID(),
    maxInstances: clusterDefaults.MAX_COMPUTE_INSTANCES,
    poolName: clusterDefaults.GPU_POOL_NAME,
    primaryUse: ResourcePoolUse.Compute,
  },
];
