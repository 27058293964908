import { Button } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';

import Page from 'components/Page';
import { useStore } from 'contexts/Store';
import { useFetchClusters } from 'experimental/notifications/hooks';
import { useFetchSupportMatrix } from 'hooks/useFetch';
import useModalNewCluster from 'hooks/useModal/useNewCluster/useNewCluster';
import * as GlobalApi from 'services/global-bindings';
import Message, { MessageType } from 'shared/components/Message';
import Spinner from 'shared/components/Spinner';
import usePolling from 'shared/hooks/usePolling';
import handleError from 'utils/error';

import ClusterCard from './ClusterCard';
import css from './ClusterCards.module.scss';

const Clusters: React.FC = () => {
  const [clusters, setClusters] = useState<GlobalApi.ModelClusterInfo[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [canceler] = useState(new AbortController());
  const {
    orgState: { selectedOrg },
  } = useStore();
  const fetchSupportMatrix = useFetchSupportMatrix(canceler);
  const fetchClusters = useFetchClusters(canceler);

  const loadClusters = useCallback(async () => {
    if (!selectedOrg) return;
    const newClusters = await fetchClusters();
    setLoading(false);
    if (!newClusters) return;
    setClusters(newClusters);
  }, [selectedOrg, fetchClusters]);

  const loadVersions = useCallback(async () => {
    if (!selectedOrg) return;
    try {
      await fetchSupportMatrix();
    } catch (err) {
      handleError(err);
    }
  }, [selectedOrg, fetchSupportMatrix]);

  usePolling(loadClusters, { runImmediately: true });

  useEffect(() => {
    loadClusters();
  }, [loadClusters]);

  useEffect(() => {
    loadVersions();
  }, [loadVersions]);

  useEffect(() => {
    return () => canceler.abort();
  }, [canceler]);

  const emptyMessage = useCallback(() => {
    if (loading) return <Spinner tip="Fetching list of clusters" />;
    return (
      <Message
        message="Create a cluster configured to your resource and training needs"
        title="No clusters"
        type={MessageType.Empty}
      />
    );
  }, [loading]);

  const { modalOpen, contextHolder: contextHolderNewCluster } = useModalNewCluster();

  return (
    <Page
      options={<Button onClick={() => modalOpen()}>New Cluster</Button>}
      title="Cluster Cards (beta)">
      {clusters.length > 0 && (
        <div className={css.cardsWrapper}>
          {clusters.map((cluster) => (
            <ClusterCard cluster={cluster} key={cluster.id} />
          ))}
        </div>
      )}
      {clusters.length === 0 && emptyMessage()}
      {contextHolderNewCluster}
    </Page>
  );
};

export default Clusters;
