import { parseUrl, routeToExternalUrl } from 'shared/utils/routes';
import { BrandingType } from 'types';

/*
 * In mobile view the definition of viewport height varies between
 * whether it should include the chrome's vertical space or not.
 * This function dynamically calculates the available height on the
 * browser view minus the chrome height.
 * https://css-tricks.com/the-trick-to-viewport-units-on-mobile/
 */
export const correctViewportHeight = (): void => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty('--vh', `${vh}px`);
};

const downloadBlob = (filename: string, data: Blob): void => {
  const url = window.URL.createObjectURL(data);
  const element = document.createElement('a');
  element.setAttribute('download', filename);
  element.style.display = 'none';
  element.href = url;
  document.body.appendChild(element);
  element.click();
  window.URL.revokeObjectURL(url);
  document.body.removeChild(element);
};

// Different JS engines impose different maximum string lenghts thus for downloading
// large text we split it into different parts and then stich them together as a Blob
export const downloadText = (filename: string, parts: BlobPart[]): void => {
  const data = new Blob(parts, { type: 'text/plain' });
  downloadBlob(filename, data);
};

export const getCookie = (name: string): string | null => {
  const regex = new RegExp(`(?:(?:^|.*;\\s*)${name}\\s*\\=\\s*([^;]*).*$)|^.*$`);
  const value = document.cookie.replace(regex, '$1');
  return value ? value : null;
};

/*
 * The method of cache busting here is to send a query string as most
 * modern browsers treat different URLs as different files, causing a
 * request of a fresh copy. The previous method of using `location.reload`
 * with a `forceReload` boolean has been deprecated and not reliable.
 */
export const refreshPage = (): void => {
  const now = Date.now();
  const url = parseUrl(window.location.href);
  url.search = url.search ? `${url.search}&ts=${now}` : `ts=${now}`;
  routeToExternalUrl(url.toString());
};

const updateFavicon = (iconPath: string): void => {
  const linkEl: HTMLLinkElement | null = document.querySelector("link[rel*='shortcut icon']");
  if (!linkEl) return;
  linkEl.type = 'image/png';
  linkEl.href = iconPath;
};

export const updateFaviconType = (active: boolean, branding = BrandingType.Determined): void => {
  const suffixDev = process.env.IS_DEV ? '-dev' : '';
  const suffixActive = active ? '-active' : '';
  updateFavicon(`${process.env.PUBLIC_URL}/${branding}/favicon${suffixDev}${suffixActive}.png`);
};
