import { notification } from 'antd';

import { paths } from 'routes/utils';
import { ClusterState } from 'saasTypes';
import { ModelClusterInfo } from 'services/global-bindings';
import { routeToReactUrl } from 'shared/utils/routes';

import { Subscription, SubscriptionType } from './storage';

/** the lazy way to get the action verb from a cluster state.  */
const actionVerb = (subType: SubscriptionType) => {
  return (subType + '').replace('Cluster', '').toLowerCase();
};

const genNotificationText = (
  subType: SubscriptionType,
  c: ModelClusterInfo,
): { description: string; title: string } => {
  if (c.state === ClusterState.ActionFailed) {
    return {
      description: `Cluster ${c.name} ${c.id} was not successfully ${actionVerb(subType)}`,
      title: 'Action failed',
    };
  }

  return {
    description: `Cluster ${c.name} ${c.id} has been ${actionVerb(subType)}.`,
    title: `Cluster ${c.name} ${actionVerb(subType)}.`,
  };
};

const generateNotificationKey = (subscription: Subscription): string => {
  return `${subscription.id}-${subscription.type}`;
};

const browserSupportsNotifications = () => {
  return 'Notification' in window;
};

export const ensureNotificationPermission = async (): Promise<boolean> => {
  return (
    browserSupportsNotifications() && (await window.Notification.requestPermission()) === 'granted'
  );
};

const openNativeNotification = async (cluster: ModelClusterInfo, subscription: Subscription) => {
  if (!(await ensureNotificationPermission())) return;
  const { title, description } = genNotificationText(subscription.type, cluster);
  return new window.Notification(title, { body: description });
};

export const openNotification = async (
  cluster: ModelClusterInfo,
  subscription: Subscription,
): Promise<void> => {
  const { title, description } = genNotificationText(subscription.type, cluster);
  const nativeNotif = await openNativeNotification(cluster, subscription);
  if (nativeNotif) {
    nativeNotif.onclick = () => {
      window.focus();
      routeToReactUrl(paths.clusters());
    };
    return;
  }

  notification.open({
    description,
    duration: 0, // avoid auto close.
    key: generateNotificationKey(subscription),
    message: title,
  });
};

export default {};
