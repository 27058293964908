import { Button } from 'antd';
import React from 'react';

import PageMessage from 'components/PageMessage';
import { globalServerAddress } from 'routes/utils';
import { refreshPage } from 'utils/browser';

const Disconnected: React.FC = () => {
  return (
    <PageMessage title="Server is Unreachable">
      <p>
        Unable to communicate with the global management plane at &quot;{globalServerAddress()}
        &quot;. Please check the firewall and network settings.
      </p>
      <Button onClick={refreshPage}>Try Again</Button>
    </PageMessage>
  );
};

export default Disconnected;
