import { Button } from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import AccessLevelSelect from 'components/AccessLevel/AccessLevelSelect';
import { SingleOrg } from 'components/OrgPicker';
import Page from 'components/Page';
import ResponsiveTable from 'components/ResponsiveTable';
import Section from 'components/Section';
import { useStore } from 'contexts/Store';
import AddMemberModal from 'hooks/useModal/useAddMember';
import useUserClusters from 'hooks/useModal/useUserClusters';
import { AccessLevel } from 'saasTypes';
import { deleteOrgUser, getOrgMembers, upsertOrgUser } from 'services/api';
import * as GlobalApi from 'services/global-bindings';
import ActionDropdown from 'shared/components/ActionDropdown';
import Avatar, { Size } from 'shared/components/Avatar';
import Message from 'shared/components/Message';
import useUI from 'shared/contexts/stores/UI';
import { ModalCloseReason } from 'shared/hooks/useModal/useModal';
import usePolling from 'shared/hooks/usePolling';
import handleError from 'utils/error';

import css from './Members.module.scss';
import { columns } from './Members.table';

enum Action {
  Remove = 'Remove',
  ManageAccess = 'Manage Access',
}

const Users: React.FC = () => {
  const {
    auth: { roles: orgRoles, user },
    orgState: { selectedOrg },
  } = useStore();
  const { ui } = useUI();
  const [pageState, setPageState] = useState({ isAddingMember: false, isLoading: true });
  const [users, setUsers] = useState<GlobalApi.ModelOrgUser[]>([]);
  const [canceler] = useState(new AbortController());

  const fetchUsers = useCallback(async () => {
    if (!selectedOrg) return;
    const members = await getOrgMembers({ orgId: selectedOrg.id }, { signal: canceler.signal });
    setUsers(members);
    setPageState((cur) => ({ ...cur, isLoading: false }));
  }, [selectedOrg, canceler.signal]);

  const { modalOpen: openModalUserClusters, contextHolder: contextHolderUserClusters } =
    useUserClusters({});

  usePolling(fetchUsers);

  // immediately fetch users on each fetchUsers change
  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  const canModifyUser = useCallback(
    (targetUser: GlobalApi.ModelOrgUser) => {
      if (!orgRoles || !user || !selectedOrg) return false;
      const curUserRole = orgRoles[selectedOrg.id];
      if (curUserRole.role !== AccessLevel.Admin) return false;
      if (user.userID === targetUser.id) return false;
      return true;
    },
    [orgRoles, user, selectedOrg],
  );

  /**
   * Update the user's access level and handle any errors.
   */
  const updateUserAcl = useCallback(
    async (newUser: GlobalApi.ModelOrgUser) => {
      if (!selectedOrg) return;
      await upsertOrgUser({
        orgId: selectedOrg.id,
        userId: newUser.id,
        userRoles: { defaultClusterRole: newUser.defaultClusterRole, role: newUser.role },
      });
    },
    [selectedOrg],
  );

  const updatedColumns = useMemo(() => {
    return columns.map((col) => {
      if (!selectedOrg) return col;
      switch (col.key) {
        case 'defaultClusterRole':
          col.render = (text: string, record: GlobalApi.ModelOrgUser) => {
            if (!canModifyUser(record)) return record.defaultClusterRole;
            return (
              <AccessLevelSelect
                defaultValue={record.defaultClusterRole as AccessLevel}
                onChange={(acl) =>
                  updateUserAcl({
                    ...record,
                    defaultClusterRole: acl,
                  })
                }
              />
            );
          };
          break;
        case 'role':
          col.render = (text: string, record: GlobalApi.ModelOrgUser) => {
            if (!canModifyUser(record)) return record.role;
            return (
              <AccessLevelSelect
                defaultValue={record.role as AccessLevel}
                onChange={(acl) =>
                  updateUserAcl({
                    ...record,
                    role: acl,
                  })
                }
              />
            );
          };
          break;
        case 'actions':
          col.render = (text: string, record: GlobalApi.ModelOrgUser) => {
            if (!canModifyUser(record)) return null;
            return (
              <ActionDropdown
                actionOrder={[Action.ManageAccess, Action.Remove]}
                confirmations={{ [Action.Remove]: { okText: 'Remove' } }}
                id={record.name}
                kind="member"
                onError={handleError}
                onTrigger={{
                  [Action.ManageAccess]: () => {
                    openModalUserClusters({ context: { user: record } });
                  },
                  [Action.Remove]: () => {
                    return deleteOrgUser(
                      {
                        orgId: selectedOrg.id,
                        userId: record.id,
                      },
                      { signal: canceler.signal },
                    ).then(fetchUsers);
                  },
                }}
              />
            );
          };
          break;
        case 'name':
          col.render = (_: string, record: GlobalApi.ModelOrgUser): React.ReactNode => {
            return (
              <div className={css.user}>
                <Avatar darkLight={ui.darkLight} displayName={record.name} noColor />
                <span>{record.name}</span>
              </div>
            );
          };
          break;
        case 'email':
          col.render = (_: string, record: GlobalApi.ModelOrgUser): React.ReactNode => {
            return (
              <div className={css.user}>
                <span>{record.email}</span>
              </div>
            );
          };
          break;

        default:
          break;
      }
      return col;
    });
  }, [
    updateUserAcl,
    openModalUserClusters,
    ui.darkLight,
    canModifyUser,
    canceler.signal,
    selectedOrg,
    fetchUsers,
  ]);

  if (!selectedOrg) return <Message title="Waiting for an organization selection.." />;
  if (!orgRoles || !user) return <Message title="Waiting for user information.." />;

  return (
    <Page
      options={
        <Button
          disabled={orgRoles[selectedOrg.id].role !== AccessLevel.Admin}
          onClick={() => setPageState((cur) => ({ ...cur, isAddingMember: !cur.isAddingMember }))}>
          New Member
        </Button>
      }
      title="Settings and Members">
      <Section title="Organization">
        <SingleOrg darkLight={ui.darkLight} displayId org={selectedOrg} size={Size.Large} />
      </Section>
      <Section title="Members">
        <ResponsiveTable<GlobalApi.ModelOrgUser>
          columns={updatedColumns}
          dataSource={users}
          loading={pageState.isLoading}
          pagination={{ hideOnSinglePage: true }}
          rowKey="userID"
          scroll={{ x: 1000 }}
          showSorterTooltip={true}
          size="small"
        />
      </Section>
      <AddMemberModal
        orgId={selectedOrg.id}
        visible={pageState.isAddingMember}
        onClose={(reason) => {
          setPageState((cur) => ({ ...cur, isAddingMember: false }));
          if (reason !== ModalCloseReason.Cancel) fetchUsers();
        }}
      />
      {contextHolderUserClusters}
    </Page>
  );
};

export default Users;
